import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ToolTipComponent = (props) => {
  const { text, data } = props;
  return (
    <OverlayTrigger
      placement="top"
      // trigger="hover"
      overlay={
        <Tooltip id="button-tooltip-2">
          <strong>{text}</strong>
        </Tooltip>
      }
    >
      {data}
    </OverlayTrigger>
  );
};

export default ToolTipComponent;