import { List } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { REACT_APP_FORM_URL } from "../../config/index";
import { jobListing } from "./action";

const Career = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const careerDetails = useSelector((state) => state.career.jobDetailsLising);
  const [urgentJobs, setUrgentJobs] = useState([]);

  useEffect(() => {
    dispatch(jobListing());
  }, []);

  useEffect(() => {
    if (careerDetails) {
      let list = [];
      careerDetails &&
        careerDetails.length > 0 &&
        careerDetails.map((job) => {
          job.isUrgent && list.push(job);
          setUrgentJobs(list);
        });
    }
  }, [careerDetails]);

  return (
    <section className="we-offer-section" id="opening">
      <Container>
        <Row>
          <Col lg={8} md={9} className="mx-auto">
            <div className="we-offer-content text-center">
              <h2>We offer careers, not jobs</h2>
              <p>
                This is exactly where IndiaNIC stands apart from the others. To
                evolve with innovations, we always welcome and recognise
                IndiaNICians who come up with great ideas. Voice of employees
                matters to us. IndiaNIC has the global presence, so grab the
                opportunity to work with top MNCs of the world. Join us and give
                yourself an opportunity to take your career to the newest
                heights.
              </p>
            </div>
            {urgentJobs.length > 0 && (
              <div className="position-listing">
                <div className="listing-block">
                  <h3>
                    Hiring Urgently<span>⚡️</span>
                  </h3>
                  <div className="listing-row">
                    {careerDetails &&
                      careerDetails.length > 0 &&
                      careerDetails.map((job) => {
                        let route =
                          REACT_APP_FORM_URL + `/job-detail/${job?._id}`;
                        return (
                          job.isUrgent && (
                            <div className="full_time listing-box">
                              <a onClick={() => window.open(route)}>
                                <div className="position-name">
                                  <h4>{job && job.title}</h4>
                                </div>
                                <div className="position-number">
                                  <p className="font-weight-medium">
                                    {job && job.noOfPositions} Positions
                                  </p>
                                  <span className="bx bx-right-arrow-alt"></span>
                                </div>
                              </a>
                            </div>
                          )
                        );
                      })}
                    {careerDetails && careerDetails.length === 0 && (
                      <div
                        // style="display:none;text-align:center"
                        className="listing-box no_data_found"
                      >
                        <div className="position-name">
                          <h4>No Data Found</h4>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="listing-row"></div>
                </div>
              </div>
            )}
            <div className="position-listing">
              <div className="listing-block">
                <h3>Current Openings</h3>
                <div className="listing-row">
                  {careerDetails &&
                    careerDetails.length > 0 &&
                    careerDetails.map((job) => {
                      let route =
                        REACT_APP_FORM_URL + `/job-detail/${job?._id}`;
                      return (
                        !job.isUrgent && (
                          <div className="full_time listing-box">
                            <a onClick={() => window.open(route)}>
                              <div className="position-name">
                                <h4>{job && job.title}</h4>
                              </div>
                              <div className="position-number">
                                <p className="font-weight-medium">
                                  {job && job.noOfPositions} Positions
                                </p>
                                <span className="bx bx-right-arrow-alt"></span>
                              </div>
                            </a>
                          </div>
                        )
                      );
                    })}
                  {careerDetails && careerDetails.length === 0 && (
                    <div
                      // style="display:none;text-align:center"
                      className="listing-box no_data_found"
                    >
                      <div className="position-name">
                        <h4>No Data Found</h4>
                      </div>
                    </div>
                  )}
                </div>
                <div className="listing-row"></div>
              </div>
            </div>
            <div className="find-job-section">
              <div className="bg-color-box">
                <Row>
                  <div className="col-12 col-md-6 img-box text-center">
                    <img
                      alt=""
                      srcset="https://dcdh7ea8gkhvt.cloudfront.net/wp-content/uploads/2022/02/career-block-employee.svg"
                      width="410"
                      height="275"
                    />
                  </div>
                  <div className="col-12 col-md-6 content-box">
                    <h2>Couldn’t find the job you are looking for?</h2>
                    <p>
                      <span>Send your resume on </span>{" "}
                      <a
                        href="https://indianic.zohorecruit.com/candidateportal"
                        target="_blank"
                      >
                        Candidate Portal{" "}
                        <span className="bx bx-right-arrow-alt"></span>
                      </a>
                    </p>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Career;
