import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  countryCodeListing,
  getJobDetailById,
  addCandidateData,
  addCvDetails,
  getSkillDetails,
  getQualificationList,
  candidateFileUpload,
  candidateFileDelete,
  getCountryDetails,
  addSkillsMaster,
  addWalkInDriveData,
  technologyListing,
} from "../Career/action";
import InputMask from "react-input-mask";
import inicLogo from "../../assets/images/INIC-logo.png";
import UploadIcon from "../../assets/images/upload-doc.svg";
import UploadImageIcon from "../../assets/images/upload-profile.svg";
import {
  Badge,
  Form,
  Row,
  Col,
  Container,
  Image,
  Button,
  Modal,
  Spinner,
  ModalBody,
} from "react-bootstrap";
import SimpleSelectComponent from "../../components/SimpleSelect/SimpleSelectComponent";
import MultiSelectComponent from "../../components/Multiselect/MultiSelectComponent";
import {
  emailValidator,
  showSuccessToast,
  textValidator,
} from "../../utils/Functions";
import Footer from "../../components/Footer";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import docIcon from "../../assets/images/icons-doc.png";
import PhoneInput from "react-phone-input-2";
import DocViewer, {
  PDFRenderer,
  MSDocRenderer,
  PNGRenderer,
  JPGRenderer,
} from "react-doc-viewer";
import data from "../../WalkInData/data.json";

const WalkInDrive = () => {
  let typingTimer;
  const timeOut = useRef();
  const param = useParams();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.career.getJobData);
  const skillDetail = useSelector((state) => state.career.getSkillList.data);
  const addJob = useSelector((state) => state.career.addWalkInDriveCandidate);
  const code = useSelector((state) => state.career.getCountryCode.data);
  const [isLoading, setIsLoading] = useState(false);
  const qualificationDetail = useSelector(
    (state) => state.career.getQualificationDetails
  );
  const technologyReduxData = useSelector(
    (state) => state.career.technologyDetailedList
  );
  const [technologyOptionsData, setTechnologyOptionsData] = useState([]);
  const [interested, setInterested] = useState(false);
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [jobSuccess, setJobSuccess] = useState(false);
  const [phoneCodeOptionsData, setPhoneCodeOptionsData] = useState([]);
  const [show, setShow] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [fileList, setFileList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [fileType, setFileType] = useState("");
  const [uploadList, setUploadList] = useState([]);
  const [addJobMessage, setAddJobMeassage] = useState("");

  const [jobApplicationData, setJobApplicationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    expectedSalary: "",
    skillSet: [],
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    resume: "",
    photo: "",
    qualificationId: "",
    qualification: "",
    technologyId: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    expectedSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    resume: "",
    qualificationId: "",
    qualification: "",
    technologyId: "",
  });

  const [educationDetails, setEducationDetails] = useState([
    {
      institute: "",
      major: "",
      degree: "",
      from: "",
      to: "",
      pursuing: false,
    },
  ]);

  const [experienceDetails, setExperienceDetails] = useState([
    {
      occupation: "",
      company: "",
      summary: "",
      from: "",
      to: "",
      isWorking: false,
    },
  ]);

  const countryList = useSelector((state) => state.career.getCountryDetails);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    dispatch(getJobDetailById(param.id));
  }, []);

  useEffect(() => {
    dispatch(getSkillDetails());
    dispatch(getCountryDetails());
    dispatch(technologyListing());
  }, []);

  useEffect(() => {
    let countryArray = [];
    countryList.length > 0 &&
      countryList.map((country) =>
        countryArray.push({
          label: country.countryName,
          value: country.countryName,
        })
      );

    setCountryOptions(countryArray);
  }, [countryList]);

  useEffect(() => {
    if (skillDetail && skillDetail.length > 0) {
      const list = [];
      skillDetail?.map((item) => {
        return list.push({
          label: item?.skill,
          value: item?._id,
        });
      });

      if (selectedSkill.length > 0) {
        let selectedSkillList = [];
        selectedSkill.map((data) => {
          selectedSkillList.push(
            list.filter((dat) => dat.label === data.label)[0]
          );
        });

        setSelectedSkill(selectedSkillList);
        setJobApplicationData({
          ...jobApplicationData,
          skillSet: selectedSkillList.map((data) => data.value),
        });
      }
      setSkillSetOption([
        ...list,
        {
          label: "",
          value: "",
        },
      ]);
    } else {
      setSkillSetOption([]);
    }
  }, [skillDetail]);

  useEffect(() => {
    dispatch(countryCodeListing());
    dispatch(getQualificationList());
  }, []);

  useEffect(() => {
    if (addJob && addJob.status === 1) {
      const addFiles = Promise.all(
        uploadList.map((item) => {
          let payload = new FormData();
          payload.append("userId", addJob?.data?._id);
          payload.append("type", item.name);
          payload.append("file", item.item);
          // return dispatch(candidateFileUpload(payload));
        })
      );

      if (uploadList?.length > 0) {
        Promise.all([addFiles]).then((values) => {
          setJobSuccess(true);
          setIsLoading(false);
          // setShow(true);
          showSuccessToast(addJob.message);
        });
      } else {
        setJobSuccess(true);
        // setShow(true);
        showSuccessToast(addJob.message);
      }
    }

    if (addJob && addJob.status === 1) {
      setAddJobMeassage("Job Applied successfully");
      setShow(true);
    } else if (addJob && addJob.status === 2) {
      setAddJobMeassage(
        "You have already applied to this Job, Your Application is in progress"
      );
      setShow(true);
    } else if (addJob && addJob.status === 3) {
      setAddJobMeassage(
        "Your hiring process is in progress, unable to apply for another job / You have already Joined. / You have already Hired."
      );
      setShow(true);
    } else if (addJob && addJob.status === 4) {
      setAddJobMeassage(" You are not eligible to apply for this Role.");
      setShow(true);
    }
  }, [addJob]);

  useEffect(() => {
    if (code && code.length > 0) {
      const list = [];
      code?.map((item) => {
        list.push({
          label: `+${item?.phoneCode}`,
          value: `+${item?.phoneCode}`,
        });
        setPhoneCodeOptionsData(list);
      });
    }
  }, [code]);

  useEffect(() => {
    if (
      technologyReduxData?.status === 1 &&
      technologyReduxData?.data.length > 0
    ) {
      const data = technologyReduxData?.data;
      const newArray = data.map((item) => {
        return {
          label: item.technology,
          value: item._id,
        };
      });
      setTechnologyOptionsData(newArray);
    }
  }, [technologyReduxData]);

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onResumeChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      setErrors({
        ...errors,
        succsessFile: "Resume/CV added succsessfully",
        resume: "",
      });
    }
  };

  const addResume = async (resume) => {
    let type = resume.name.split(".")[1];
    setFileType(type);

    if (type === "pdf" || type === "docx" || type === "gif") {
      const objectUrl = URL.createObjectURL(resume);
      setPreview(objectUrl);
    }
    setFile(resume);
    let obj = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      skillSet: [],
      resume: "",
    };

    let formData = new FormData();
    formData.append("file", resume);
    await dispatch(addCvDetails(formData)).then((res) => {
      if (res) {
        let list = [];
        let ids = [];
        res?.data?.data?.candidateSkillSet.map((item) =>
          list.push({ value: item._id, label: item.skill })
        );

        selectedSkill?.length === 0 && setSelectedSkill(list);
        res?.data?.data?.candidateSkillSet.map((item) => {
          if (item._id !== "") {
            ids.push(item._id);
          }
        });

        obj.firstName = res?.data?.data?.firstName
          ? res?.data?.data?.firstName
          : "";
        obj.lastName = res?.data?.data?.lastName
          ? res?.data?.data?.lastName
          : "";
        obj.email = res?.data?.data?.emailId ? res?.data?.data?.emailId : "";
        obj.mobile = res?.data?.data?.phone ? res?.data?.data?.phone : "";
        obj.skillSet = ids.length > 0 ? ids : [];

        setErrors({
          ...errors,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
      }
    });
    let payload = new FormData();
    payload.append("file", resume);
    payload.append("type", "resume");
    await dispatch(candidateFileUpload(payload)).then((res) => {
      obj.resume = res?.data?.data?.fileUrl + res?.data?.data?.response;
    });

    setJobApplicationData((prevState) => ({
      ...jobApplicationData,
      firstName: prevState.firstName || obj.firstName,
      lastName: prevState.lastName || obj.lastName,
      email: prevState.email || obj.email,
      mobile: prevState.mobile || obj.mobile,
      resume: obj.resume,
      //  skillSet: prevState.skillSet || obj.skillSet,
    }));
  };

  const handleReupload = () => {
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      resume: "",
    });

    setSelectedSkill([]);
    let deletePayload = {
      type: "resume",
      filePath: jobApplicationData.resume,
    };
    dispatch(candidateFileDelete(deletePayload));
    setJobApplicationData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      expectedSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      resume: "",
      qualificationId: "",
      qualification: "",
      technologyId: "",
    });
  };

  const handleImageReupload = () => {
    setImage();
    // setPreview("");
    setProfileList([]);
    // setFileType("");
    setErrors({
      ...errors,
      successImageFile: "",
      resume: "",
    });

    let payload = {
      type: "photo",
      filePath: jobApplicationData.photo,
    };
    dispatch(candidateFileDelete(payload));
    setJobApplicationData({
      ...jobApplicationData,
      photo: "",
    });
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let error = {
      firstName: "",
      // phoneCode: "",
      lastName: "",
      email: "",
      mobile: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      expectedSalary: "",
      city: "",
      state: "",
      qualification: "",
      country: "",
      noticePeriod: "",
      // file: "",
      resume: "",
      skillSet: "",
      technologyId: "",
    };
    let mobileRegex = "^[0-9][0-9]{9,12}$";
    let emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (jobApplicationData.firstName === "") {
      error.firstName = "First Name is required";
    } else error.firstName = "";

    if (jobApplicationData.lastName === "") {
      error.lastName = "Last Name is required";
    } else error.lastName = "";

    if (jobApplicationData.email === "") {
      error.email = "Email is required";
    } else if (!new RegExp(emailRegex).test(jobApplicationData.email)) {
      error.email = "Please provide a valid email";
    } else error.email = "";

    if (jobApplicationData.mobile === "") {
      error.mobile = "Mobile number is required";
    } else if (!new RegExp(mobileRegex).test(jobApplicationData.mobile)) {
      error.mobile = "Mobile number in between 10-13 digits";
    } else error.mobile = "";

    if (jobApplicationData.currentEmployer === "") {
      error.currentEmployer = "This field is required.";
    } else error.currentEmployer = "";

    if (jobApplicationData.totalExperiance === "") {
      error.totalExperiance = "This field is required.";
    } else error.totalExperiance = "";

    if (jobApplicationData.currentSalary === "") {
      error.currentSalary = "This field is required.";
    } else error.currentSalary = "";

    if (jobApplicationData.expectedSalary === "") {
      error.expectedSalary = "This field is required.";
    } else error.expectedSalary = "";

    if (jobApplicationData.noticePeriod === "") {
      error.noticePeriod = "This field is required.";
    } else error.noticePeriod = "";

    if (jobApplicationData.city === "") {
      error.city = "This field is required.";
    } else error.city = "";

    if (jobApplicationData.state === "") {
      error.state = "This field is required.";
    } else error.state = "";

    if (jobApplicationData.qualificationId === "") {
      error.qualification = "This field is required";
    } else error.qualification = "";

    if (jobApplicationData.technologyId === "") {
      error.technologyId = "This field is required";
    } else error.technologyId = "";

    if (jobApplicationData.resume === "") {
      error.resume = " Resume/CV required";
    } else error.resume = "";

    if (jobApplicationData.country === "") {
      error.country = "This field is required";
    } else error.qualification = "";
    if (jobApplicationData.skillSet.length === 0) {
      error.skillSet = "This field is required";
    } else error.skillSet = "";
    if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== "" ||
      // error.phoneCode !== "" ||
      error.mobile !== "" ||
      error.currentEmployer !== "" ||
      error.totalExperiance !== "" ||
      error.currentSalary !== "" ||
      error.expectedSalary !== "" ||
      error.qualification !== "" ||
      error.technologyId !== "" ||
      error.city !== "" ||
      error.state !== "" ||
      error.country !== "" ||
      error.noticePeriod !== "" ||
      error.skillSet !== ""
    ) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }

    setErrors(error);
    return isFormValid;
    //  }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let files = [];
      file && files.push({ name: "resume", item: file });
      image && files.push({ name: "photo", item: image });
      setUploadList(files);

      setIsLoading(true);
      let list = [];
      jobApplicationData.skillSet.map((item) => list.push(item.value));

      let obj = {
        // postingTitle: [jobData._id],
        technologyId: jobApplicationData.technologyId.value,
        firstName: jobApplicationData.firstName,
        lastName: jobApplicationData.lastName,
        emailId: jobApplicationData.email,
        phoneCode: jobApplicationData.phoneCode,
        mobile: jobApplicationData.mobile,
        noticePeriod: jobApplicationData.noticePeriod.value,
        qualification: jobApplicationData.qualificationId.value,
        totalExperiance: jobApplicationData.totalExperiance,
        currentSalary: jobApplicationData.currentSalary,
        expectedSalary: jobApplicationData.expectedSalary,
        skillSet: jobApplicationData.skillSet,
        street: jobApplicationData.street,
        zipCode: jobApplicationData.zipCode,
        city: jobApplicationData.city,
        state: jobApplicationData.state,
        country: jobApplicationData.country,
        resume: jobApplicationData.resume,
        photo: jobApplicationData.photo,
        isCompleted: true,
        // currentEmployer: jobApplicationData.currentEmployer,
        // ...(educationDetails.length > 0 && {
        //   educations: educationDetails,
        // }),
        // ...(experienceDetails.length > 0 && {
        //   experiences: experienceDetails,
        // }),
        //  faceBook: jobApplicationData.faceBook,
        //     linkdin: jobApplicationData.linkdin,

        // recruiterName: jobData.assignedRecruiters,
        // createdForm: "webForm",
      };
      dispatch(addWalkInDriveData(obj));
    }
  };

  const handleImageChange = async ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      setProfileList(newFileList);
      setErrors({
        ...errors,
        successImageFile: "Image added succsessfully",
        file: "",
      });
    }
    setImage(newFileList[0].originFileObj);
  };

  const addProfile = async (profile) => {
    let payload = new FormData();
    payload.append("file", profile);
    payload.append("type", "photo");
    dispatch(candidateFileUpload(payload)).then((res) => {
      setJobApplicationData({
        ...jobApplicationData,
        photo: res?.data?.data?.response,
      });
    });
  };

  const handleDataChange = (e, type) => {
    if (type === "num") {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: e.target.value,
      });
    } else if (type === "email") {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: emailValidator(e.target.value),
      });
    } else {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: textValidator(e.target.value),
      });
    }
  };

  const resetData = () => {
    setIsLoading(false);
    setJobApplicationData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      // phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      expectedSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      qualificationId: "",
      qualification: "",
      technologyId: "",
      resume: "",
    });
    setSelectedSkill([]);
    setJobSuccess(false);
    setInterested(false);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      // phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      expectedSalary: "",
      skillSet: "",
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      qualificationId: "",
      qualification: "",
      technologyId: "",
      resume: "",
    });
    setShow(false);
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
    ]);
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
    ]);
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      successImageFile: "",
      resume: "",
    });
    setImage();
    setProfileList([]);
  };

  const fileTypes = ["PDF", "DOC", "DOCX", "JPG", "JPEG", "PNG"];

  var noticePeriod = [
    "Immediately",
    "15 Days",
    "30 Days",
    "45 Days",
    "60 Days",
    "90 Days",
  ].map(function (notperiod) {
    return { label: notperiod, value: notperiod };
  });

  const handleClose = () => {
    setShow(false);
    resetData();
  };

  const formatPhoneNumber = async (phoneNumber, country) => {
    const reducedPhone = phoneNumber.replace(country.dialCode, "");

    setJobApplicationData({
      ...jobApplicationData,
      mobile: reducedPhone,
      phoneCode: country.dialCode,
    });
  };

  const renderPreview = useMemo(() => {
    if (fileType === "pdf" || fileType === "gif") {
      return <iframe src={preview} />;
    } else if (!showPreview) {
      return (
        <DocViewer
          documents={[
            {
              uri: jobApplicationData?.resume,
              fileType: fileType,
            },
          ]}
          pluginRenderers={[
            PDFRenderer,
            MSDocRenderer,
            PNGRenderer,
            JPGRenderer,
          ]}
          theme={{
            primary: "#5296d8",
            secondary: "#00000099",
            tertiary: "#5296d899",
            text_primary: "#ffffff",
            text_secondary: "#5296d8",
            text_tertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
        />
      );
    }

    // Default case or other conditions if needed
    return null;
  }, [preview, fileType, showPreview, jobApplicationData.resume]);

  return (
    <React.Fragment>
      <Container>
        <header className="consultant-header d-block">
          <Image
            src={inicLogo}
            width="140"
            height="140"
            alt="IndiaNIC Infotech Pvt. Ltd."
            className="inic-logo"
          />
        </header>

        <div className="jd-body-panel job-details-panel">
          <section className="consultant-add-form ">
            <Row>
              <Col lg={12}>
                {/* Form Fields Begins */}
                <div className="consultant-add-form-body ">
                  <Form onSubmit={handleSubmit}>
                    <div className="panel">
                      {/* Start: Basic Info */}
                      <div className="panel-header">
                        <h4 className="title">Select Technology</h4>
                      </div>
                      <div className="panel-content py-0">
                        <Row>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>Select Technology</Form.Label>
                              <span className="text-danger">&nbsp;*</span>
                              <div>
                                <SimpleSelectComponent
                                  name="technology"
                                  // disabled={!file}
                                  value={jobApplicationData.technologyId}
                                  options={technologyOptionsData}
                                  className={
                                    errors.technologyId !== ""
                                      ? "error-red"
                                      : ""
                                  }
                                  onValueChange={(e) => {
                                    e &&
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        technologyId: e,
                                      });
                                    setErrors({
                                      ...errors,
                                      technologyId: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.technologyId}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      {/* Ends: Basic Info */}
                      {/* Start: Basic Info */}
                      <div className="panel-header">
                        <h4 className="title">Basic Info</h4>
                      </div>
                      <div className="panel-content py-0">
                        <Row>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formFirstName">
                              <Form.Label>
                                First Name
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="firstName"
                                  maxLength="20"
                                  className={
                                    errors.firstName !== "" ? "error-red" : ""
                                  }
                                  // disabled={!file}
                                  value={jobApplicationData.firstName}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                    setErrors({
                                      ...errors,
                                      firstName: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.firstName}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formLastName">
                              <Form.Label>
                                Last Name
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="lastName"
                                  maxLength="20"
                                  // disabled={!file}
                                  className={
                                    errors.lastName !== "" ? "error-red" : ""
                                  }
                                  value={jobApplicationData.lastName}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                    setErrors({
                                      ...errors,
                                      lastName: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.lastName}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>
                                Email
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  maxLength="45"
                                  // disabled={!file}
                                  className={
                                    errors.email !== "" ? "error-red" : ""
                                  }
                                  value={jobApplicationData.email}
                                  onChange={(e) => {
                                    handleDataChange(e, "email");
                                    setErrors({
                                      ...errors,
                                      email: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.email}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formMobile">
                              <Form.Label>
                                Mobile
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div className="form-input">
                                {/* <div className="w-25">
                                  <SimpleSelectComponent
                                    name="mobile"
                                    disabled={!file}
                                    className={
                                      errors.phoneCode
                                        ? "phone-code error-red"
                                        : !file
                                        ? "disabled-field phone-code"
                                        : "phone-code"
                                    }
                                    options={phoneCodeOptionsData}
                                    onValueChange={(e) => {
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          phoneCode: e,
                                        });
                                      e &&
                                        setErrors({
                                          ...errors,
                                          phoneCode: "",
                                        });
                                    }}
                                  />
                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.phoneCode}
                                  </span>
                                </div> */}

                                <div className="w-100">
                                  <PhoneInput
                                    country={"in"}
                                    countryCodeEditable={false}
                                    enableSearch
                                    autoFormat={false}
                                    maxLength={13}
                                    disableSearchIcon={true}
                                    className={
                                      errors.mobile !== "" ? "error-red " : ""
                                    }
                                    value={
                                      jobApplicationData.phoneCode +
                                      jobApplicationData.mobile
                                    }
                                    onChange={async (phoneNumber, country) => {
                                      formatPhoneNumber(phoneNumber, country);

                                      setErrors({
                                        ...errors,
                                        mobile: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.mobile}
                                  </span>

                                  {/* <Form.Control
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className={
                                      errors.mobile !== ""
                                        ? "form-control error-red"
                                        : ""
                                    }
                                    value={jobApplicationData.mobile}
                                    onChange={(e) => {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        mobile: e.target.value,
                                      });
                                      setErrors({
                                        ...errors,
                                        mobile: "",
                                      });
                                    }}
                                  /> */}
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>Notice Period</Form.Label>
                              <span className="text-danger">&nbsp;*</span>
                              <div>
                                <SimpleSelectComponent
                                  name="noticePeriod"
                                  // disabled={!file}
                                  value={jobApplicationData.noticePeriod}
                                  options={noticePeriod}
                                  className={
                                    errors.noticePeriod !== ""
                                      ? "error-red"
                                      : ""
                                  }
                                  onValueChange={(e) => {
                                    e &&
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        noticePeriod: e,
                                      });
                                    setErrors({
                                      ...errors,
                                      noticePeriod: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.noticePeriod}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      {/* Ends: Basic Info */}
                      {/* <div className="divider-x"></div> */}
                      {/* Start: Professional Details */}
                      <div className="panel-header pt-0">
                        <h4 className="title">Professional Details</h4>
                      </div>
                      <div className="panel-content py-0">
                        <Row>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formHighQualifHeld">
                              <Form.Label>
                                {" "}
                                Highest Qualification Held
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <SimpleSelectComponent
                                  name="qualification"
                                  // disabled={!file}
                                  value={jobApplicationData.qualification}
                                  className={
                                    errors.qualification
                                      ? "error-red"
                                      : !file
                                      ? "disabled-field"
                                      : "form-control"
                                  }
                                  // options={skillSetOption}
                                  // values={selectedSkill}
                                  onValueChange={(e) => {
                                    e &&
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        qualificationId: e,
                                        qualification: e,
                                      });
                                    setErrors({
                                      ...errors,
                                      qualification: "",
                                    });
                                  }}
                                >
                                  {data.qualification?.map((data) => (
                                    <option value={data.value}>
                                      {data.label}
                                    </option>
                                  ))}
                                </SimpleSelectComponent>

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.qualification}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formCurrentEmployer">
                              <Form.Label>
                                Current Employer
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="currentEmployer"
                                  maxLength="30"
                                  // disabled={!file}
                                  className={
                                    errors.currentEmployer !== ""
                                      ? "error-red"
                                      : ""
                                  }
                                  value={jobApplicationData.currentEmployer}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                    setErrors({
                                      ...errors,
                                      currentEmployer: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.currentEmployer}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formtotalExperiance">
                              <Form.Label>
                                Total Experience(IN YEAR)
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="number"
                                  //    disabled={!file}
                                  className={
                                    errors.totalExperiance !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  value={jobApplicationData.totalExperiance}
                                  onChange={(e) => {
                                    setJobApplicationData({
                                      ...jobApplicationData,
                                      totalExperiance: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      totalExperiance: "",
                                    });
                                  }}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                  }}
                                  maxlength={5}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.totalExperiance}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formCurrentSalary">
                              <Form.Label>
                                Current Salary(IN LPA)
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="number"
                                  // disabled={!file}
                                  className={
                                    errors.currentSalary !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  value={jobApplicationData.currentSalary}
                                  onChange={(e) => {
                                    setJobApplicationData({
                                      ...jobApplicationData,
                                      currentSalary: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      currentSalary: "",
                                    });
                                  }}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                  }}
                                  maxlength={5}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.currentSalary}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formExpectedSalary">
                              <Form.Label>
                                Expected Salary (In LPA)
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="number"
                                  // disabled={!file}
                                  className={
                                    errors.expectedSalary !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  value={jobApplicationData.expectedSalary}
                                  onChange={(e) => {
                                    setJobApplicationData({
                                      ...jobApplicationData,
                                      expectedSalary: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      expectedSalary: "",
                                    });
                                  }}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                  }}
                                  maxlength={5}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.expectedSalary}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>Skill Set</Form.Label>
                              <span className="text-danger">&nbsp;*</span>
                              <div>
                                <MultiSelectComponent
                                  name="skillSet"
                                  options={skillSetOption.filter(
                                    (data) => data.label !== ""
                                  )}
                                  values={selectedSkill}
                                  className={
                                    errors.skillSet !== "" ? "error-red " : ""
                                  }
                                  // disabled={!file}
                                  onValuesChange={(e) => {
                                    let list = [];

                                    e.map((item) => list.push(item.value));
                                    e &&
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        skillSet: list,
                                      });

                                    setSelectedSkill(e);

                                    skillSetOption?.forEach((item) => {
                                      if (
                                        item?.value?.trim() === "" &&
                                        item?.label?.trim() !== ""
                                      ) {
                                        dispatch(
                                          addSkillsMaster({
                                            skill: item.label,
                                          })
                                        );
                                        // setSkillSetOption(e);
                                      }
                                    });
                                    setErrors({
                                      ...errors,
                                      skillSet: "",
                                    });
                                  }}
                                  onSearchChange={(value) => {
                                    if (value && value.trim() !== "") {
                                      const lowercasedValue =
                                        value.toLowerCase();
                                      const isExistingOption =
                                        skillSetOption?.some(
                                          (option) =>
                                            option.label.toLowerCase() ===
                                            lowercasedValue
                                        );
                                      clearTimeout(typingTimer);
                                      if (!isExistingOption) {
                                        typingTimer = setTimeout(() => {
                                          let updatedOptions = skillSetOption;
                                          updatedOptions[
                                            updatedOptions.length - 1
                                          ].label = value;

                                          setSkillSetOption([
                                            ...updatedOptions,
                                          ]);
                                        }, 1000);
                                        return;
                                      }
                                    }
                                  }}
                                  onBlur={() => {
                                    let list = [];
                                    skillDetail?.map((item) => {
                                      return list.push({
                                        label: item?.skill,
                                        value: item?._id,
                                      });
                                    });
                                    setSkillSetOption([
                                      ...list,
                                      {
                                        label: "",
                                        value: "",
                                      },
                                    ]);
                                  }}
                                  onRemoveItem={function (item) {
                                    const skills = selectedSkill?.filter(
                                      function (d) {
                                        return item.value !== d?.value;
                                      }
                                    );
                                    setSelectedSkill(skills);
                                  }}
                                />
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.skillSet}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      {/* Ends: Professional Details */}
                      {/* <div className="divider-x"></div> */}

                      {/* Begin: Address Details */}
                      <div className="panel-header pt-0">
                        <h4 className="title">Address Details</h4>
                      </div>
                      <div className="panel-content py-0">
                        <Row>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formStreet">
                              <Form.Label>Street</Form.Label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="street"
                                  maxLength="45"
                                  // disabled={!file}

                                  className={
                                    errors.skillSet !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  value={jobApplicationData.street}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                  }}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formZip">
                              <Form.Label>Zip / Postal Code</Form.Label>
                              <div>
                                <InputMask
                                  // disabled={!file}
                                  className={"form-control"}
                                  mask={
                                    jobApplicationData.zipCode !== ""
                                      ? "999999"
                                      : ""
                                  }
                                  maskChar=""
                                  value={jobApplicationData.zipCode}
                                  onChange={(e) =>
                                    setJobApplicationData({
                                      ...jobApplicationData,
                                      zipCode: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {/* <Form.Control
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  setJobApplicationData({
                                    ...jobApplicationData,
                                    zipCode: e.target.value,
                                  });
                                }}
                              /> */}
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formCity">
                              <Form.Label>
                                City
                                <span className="text-danger">&nbsp;*</span>
                              </Form.Label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="city"
                                  maxLength="30"
                                  // disabled={!file}
                                  className={
                                    errors.city !== "" ? "error-red" : ""
                                  }
                                  value={jobApplicationData.city}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                    setErrors({
                                      ...errors,
                                      city: "",
                                    });
                                  }}
                                />

                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.city}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formProvince">
                              <Form.Label>State / Province</Form.Label>
                              <span className="text-danger">&nbsp;*</span>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="state"
                                  maxLength="30"
                                  // disabled={!file}
                                  className={
                                    errors.city !== "" ? "error-red" : ""
                                  }
                                  value={jobApplicationData.state}
                                  onChange={(e) => {
                                    handleDataChange(e);
                                    setErrors({
                                      ...errors,
                                      state: "",
                                    });
                                  }}
                                />
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.state}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={"4"} sm={"6"}>
                            <Form.Group controlId="formCountry">
                              <Form.Label>Country</Form.Label>
                              <span className="text-danger">&nbsp;*</span>
                              <div>
                                <SimpleSelectComponent
                                  placeholder="Select country"
                                  className={
                                    errors.country !== "" ? "error-red" : ""
                                  }
                                  options={countryOptions}
                                  defaultValue={{
                                    label: jobApplicationData?.country.label,
                                    value: jobApplicationData?.country.value,
                                  }}
                                  onValueChange={(e) => {
                                    if (e === undefined) {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        country: "",
                                      });
                                    } else {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        country: e.value,
                                      });
                                      setErrors({ ...errors, country: "" });
                                    }
                                  }}
                                />
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.country}
                                </span>
                                {/* <Form.Control
                                    type="text"
                                    name="country"
                                    maxLength="30"
                                    // disabled={!file}
                                    // className={!file ? "disabled-field" : ""}
                                    value={jobApplicationData.country}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                    }}
                                  /> */}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="divider-x"></div>
                      <Row>
                        <Col lg={6} sm={6}>
                          {/* File Uploader */}
                          <div className="document-file-uploader mb-5">
                            <div className="section-title-heading d-block">
                              <h4 className="doc-name mb-3">
                                Upload Resume/CV
                                <span className="text-danger">&nbsp;*</span>
                              </h4>
                              {/* <span className="divider"></span> */}
                            </div>

                            <div className="document-upload">
                              <div className="document-uploader-block">
                                {file && (
                                  <div className="preview-delete-btn">
                                    {(preview ||
                                      jobApplicationData?.resume) && (
                                      <Button
                                        variant="icon"
                                        onClick={() => setShowPreview(true)}
                                        title="Preview"
                                      >
                                        <span className="bx bx-show"></span>
                                      </Button>
                                    )}
                                    <Button
                                      variant="icon"
                                      onClick={handleReupload}
                                      title="Re-Upload"
                                    >
                                      <span className="bx bx-upload"></span>
                                    </Button>
                                  </div>
                                )}
                                {!preview && (
                                  <>
                                    <div className="file-uploader-block">
                                      <div className="upload-icon-title">
                                        {/* <span className="bx bx-cloud-upload"></span> */}
                                        <Image
                                          src={UploadIcon}
                                          className="upload-resume-doc"
                                          alt="Upload-icon"
                                        />
                                        <p className="upload-icon-title-text mt-2 mb-0">
                                          Upload or Drag your Resume here
                                        </p>
                                        <p className="or-text position-relative m-0 ">
                                          OR
                                        </p>
                                        <button
                                          type="button"
                                          class="btn btn-light "
                                        >
                                          Browse
                                        </button>
                                      </div>
                                      {!fileType && (
                                        <div className="upload-file-extensions">
                                          {fileTypes.map((badge) => (
                                            <Badge
                                              key={badge}
                                              variant="dark"
                                              className="badge-custom"
                                            >
                                              {badge}
                                            </Badge>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                    <Upload
                                      listType="picture-card"
                                      fileList={fileList}
                                      onChange={onResumeChange}
                                      customRequest={({
                                        file: resume,
                                        onSuccess,
                                        onError,
                                      }) => {
                                        Promise.resolve().then(() => {
                                          if (
                                            resume.name.split(".")[1] !== "pdf"
                                          ) {
                                            onSuccess();
                                            addResume(resume);
                                          } else {
                                            addResume(resume);
                                          }
                                        });
                                      }}
                                      // onPreview={onPreview}
                                      accept=".pdf,.doc,.docx,.jpg,.jpeg, .gif"
                                      onRemove={() => {
                                        setFile();
                                        setPreview("");
                                        setFileList([]);
                                        setFileType("");
                                        setErrors({
                                          ...errors,
                                          succsessFile: "",
                                          resume: "",
                                        });
                                      }}
                                    >
                                      {fileList.length < 1}
                                    </Upload>{" "}
                                  </>
                                )}
                                {preview && (
                                  <div className="preview-frame">
                                    {renderPreview}
                                  </div>
                                )}
                                {/* {preview && (
                                    <div className="preview-frame">
                                      <iframe src={preview} />{" "}
                                    </div>
                                  )} */}
                              </div>

                              {/* {(fileType === "docx" ||
                                  fileType === "doc") && (
                                  <img
                                    src={docIcon}
                                    alt="Word document icon"
                                    className="preview-doc-icon"
                                  />
                                )} */}
                            </div>
                            <small style={{ color: "red" }}>
                              {errors.resume}
                            </small>
                            <small style={{ color: "green" }}>
                              {errors.succsessFile}
                            </small>
                          </div>

                          {/* File Uploader */}
                        </Col>
                        <Col lg={6} sm={6}>
                          {/* Begin: Attach Image */}
                          <div className="document-file-uploader upload-img">
                            <div className="document-upload">
                              {profileList && (
                                <>
                                  <div className="section-title-heading d-block">
                                    <h4 className="doc-name mb-3">
                                      Upload Profile Picture
                                    </h4>
                                    {/* <span className="divider"></span> */}
                                  </div>
                                  <div className="position-relative profile-img-block">
                                    <div className="document-uploader-block">
                                      <div className="preview-delete-btn">
                                        {image && (
                                          <Button
                                            variant="icon"
                                            onClick={handleImageReupload}
                                            title="Re-Upload"
                                          >
                                            <span className="bx bx-upload"></span>
                                          </Button>
                                        )}
                                      </div>
                                      <div className="upload-icon-title">
                                        {/* <span className="bx bx-cloud-upload"></span> */}
                                        <Image
                                          src={UploadImageIcon}
                                          alt="Upload-image-icon"
                                        />
                                      </div>
                                      {profileList.length === 0 && (
                                        <>
                                          {" "}
                                          <small className="upload-icon-title-text">
                                            Upload or Drag here
                                          </small>
                                        </>
                                      )}
                                      <ImgCrop grid rotate>
                                        <Upload
                                          listType="picture-card"
                                          fileList={profileList}
                                          onChange={(e) => handleImageChange(e)}
                                          customRequest={({
                                            file,
                                            onSuccess,
                                            onError,
                                          }) => {
                                            Promise.resolve().then(() => {
                                              onSuccess();
                                              addProfile(file);
                                            });
                                          }}
                                          onPreview={onPreview}
                                          onRemove={() => {
                                            setProfileList([]);
                                            setImage();
                                            setErrors({
                                              ...errors,
                                              successImageFile: "",
                                            });
                                          }}
                                        >
                                          {profileList.length < 1}
                                        </Upload>
                                      </ImgCrop>
                                    </div>
                                  </div>
                                  {!errors.successImageFile && (
                                    <div className="upload-file-extensions">
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        JPG
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        PNG
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        JPEG
                                      </Badge>
                                    </div>
                                  )}

                                  <small style={{ color: "green" }}>
                                    {errors.successImageFile}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          {/* Ends: Attach Image */}
                        </Col>
                      </Row>
                      {/* Ends: Address Details */}
                    </div>

                    {/* Begin: Submit Button */}
                    <div className="button-group d-flex justify-content-end align-items-center my-4">
                      {!isLoading && (
                        <>
                          <Button
                            variant="secondary"
                            type="button"
                            className="mr-2"
                            onClick={resetData}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            Submit
                          </Button>
                        </>
                      )}
                      {isLoading && (
                        <>
                          <Button variant="primary d-inline-flex align-items-center">
                            Please wait a moment...
                            <Spinner
                              animation="border"
                              variant="light"
                              className="spinner-loader ml-2"
                            />
                          </Button>
                        </>
                      )}
                    </div>
                    {/* Ends: Submit Button */}
                  </Form>
                </div>
                {/* Form Fields Ends */}
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="text-center flex-shrink-0 flex-grow-1">
            <span className="bx bx-check-circle display-4 d-block text-success"></span>
            <h5 className="text-secondary mb-0">Success!</h5>
          </div>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <p>
            Dear{" "}
            {addJob &&
              addJob.data &&
              addJob.data.firstName + " " + addJob.data.lastName}
            ,
          </p> */}
          {/* <p>Thank you for applying and sharing the information.</p> */}
          <p>Thank you for sharing the information.</p>
          <p>{addJobMessage}</p>
          {addJob.status !== 4 && (
            <p>Our team will get in touch with you soon.</p>
          )}
          <p className="mb-2">
            To know more about IndiaNIC - visit{" "}
            <a href="candidate-portal-indianic.com" target="_blank">
              https://www.indianic.com
            </a>
          </p>
          <br />
          <br />
          {/* <p>
            Your further updates will be send to your{" "}
            <a
              href="http://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {addJob && addJob.data && addJob.data.emailId}
            </a>{" "}
          </p> */}
          <h5 className="font-weight-semibold">HR Team</h5>
          <p className="font-weight-medium mb-1">
            Have a Good Day and Best wishes for your further process.
          </p>
          {/* <img src={bgImg} alt="Career Page Header" /> */}
          <p>+91 (79) 6191 6000</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={resetData}>Ok</Button>
          {/* <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showPreview} size="lg" onHide={() => setShowPreview(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {(preview || jobApplicationData?.resume) &&
            (fileType !== "gif" ? (
              <DocViewer
                documents={[
                  {
                    uri: jobApplicationData.resume,
                    fileType: fileType,
                  },
                ]}
                pluginRenderers={[
                  PDFRenderer,
                  MSDocRenderer,
                  PNGRenderer,
                  JPGRenderer,
                ]}
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                }}
                theme={{
                  primary: "#5296d8",
                  secondary: "#00000099",
                  tertiary: "#5296d899",
                  text_primary: "#ffffff",
                  text_secondary: "#5296d8",
                  text_tertiary: "#00000099",
                  disableThemeScrollbar: false,
                }}
              />
            ) : (
              <iframe title="resume" src={preview} />
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            Close
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setShowPreview(false);
              handleReupload();
            }}
          >
            Re-Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </React.Fragment>
  );
};

export default WalkInDrive;
