export default {
  LOGIN: "consultant/login",
  JOB_LISTING: "webform/jobOpenings",
  GET_JOB_BY_ID: "webform/jobOpening",
  GET_CANDIDATE_CV_DETAILS: "candidate/fetchInfo",
  GET_SKILL_DETAILS: "master/skill/details",
  ADD_CANDIDATE_DETAILS: "applyJobForm/candidate", //"webform/candidate",
  GET_COUNTRY_CODE: "master/country/details",
  GET_QUALIFICATION_DETAILS: "master/qualification/details",
  FILEUPLOAD_CANDIDATE: "webform/fileupload",
  CANDIDATE_FILEDELETE: "webform/fileDelete",
  GET_FORM_SETTING: "webform/getSetting",
  GET_WALFINDRIVE_DETAILS: "master/walkInDrive/details",
  GET_COUNTRY_DETAILS: "master/country/details",
  ADD_SKILL_MASTER: "master/addSkill/candidate",
  ADD_WALK_IN_DRIVE: "/walkInDriveForm",
  MASTER_TECHNOLOGY_API_LISTING: "master/technology/listingForDrive",
};
