import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  countryCodeListing,
  getJobDetailById,
  addCandidateData,
  addCvDetails,
  getSkillDetails,
  getQualificationList,
  candidateFileUpload,
  candidateFileDelete,
  getCountryDetails,
  addSkillsMaster,
} from "./action";
import InputMask from "react-input-mask";
// import bgImg from "../../assets/images/main-bg.png";
import inicLogo from "../../assets/images/INIC-logo.png";
import UploadIcon from "../../assets/images/upload-doc.svg";
import UploadImageIcon from "../../assets/images/upload-profile.svg";
import moment from "moment";
import {
  Badge,
  Form,
  Row,
  Col,
  Container,
  Image,
  Button,
  Modal,
  Spinner,
  ModalBody,
} from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import SimpleSelectComponent from "../../components/SimpleSelect/SimpleSelectComponent";
import MultiSelectComponent from "../../components/Multiselect/MultiSelectComponent";
import DatePicker from "react-datepicker";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import {
  emailValidator,
  showSuccessToast,
  textValidator,
} from "../../utils/Functions";
import Footer from "./../../components/Footer";
import swal from "sweetalert";
import ToolTipComponent from "../../components/ToolTip/ToolTipComponent";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import docIcon from "../../assets/images/icons-doc.png";
import PhoneInput from "react-phone-input-2";
import DocViewer, {
  PDFRenderer,
  MSDocRenderer,
  PNGRenderer,
  JPGRenderer,
} from "react-doc-viewer";
const JobDetails = () => {
  let typingTimer;
  const timeOut = useRef();
  const param = useParams();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.career.getJobData);

  const skillDetail = useSelector((state) => state.career.getSkillList.data);

  const addJob = useSelector((state) => state.career.addJobOpening);

  const fileUpload = useSelector((state) => state.career.getCandidateCvDetails);

  const code = useSelector((state) => state.career.getCountryCode.data);
  const [isLoading, setIsLoading] = useState(false);
  const qualificationDetail = useSelector(
    (state) => state.career.getQualificationDetails
  );

  const [interested, setInterested] = useState(false);
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [jobSuccess, setJobSuccess] = useState(false);
  const [startMonth, setStartMonth] = useState("");
  const [experienceStartMonth, setExperienceStartMont] = useState();
  const [phoneCodeOptionsData, setPhoneCodeOptionsData] = useState([]);
  const [show, setShow] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [fileList, setFileList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [fileType, setFileType] = useState("");
  const [uploadList, setUploadList] = useState([]);

  const [jobApplicationData, setJobApplicationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: [],
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    resume: "",
    photo: "",
    qualificationId: "",
    qualification: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",

    qualificationId: "",
    qualification: "",
  });

  const [educationDetails, setEducationDetails] = useState([
    {
      institute: "",
      major: "",
      degree: "",
      from: "",
      to: "",
      pursuing: false,
    },
  ]);

  const [experienceDetails, setExperienceDetails] = useState([
    {
      occupation: "",
      company: "",
      summary: "",
      from: "",
      to: "",
      isWorking: false,
    },
  ]);

  const countryList = useSelector((state) => state.career.getCountryDetails);

  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    dispatch(getJobDetailById(param.id));
  }, []);

  useEffect(() => {
    dispatch(getSkillDetails());
    dispatch(getCountryDetails());
  }, []);

  useEffect(() => {
    let countryArray = [];
    countryList.length > 0 &&
      countryList.map((country) =>
        countryArray.push({
          label: country.countryName,
          value: country.countryName,
        })
      );

    setCountryOptions(countryArray);
  }, [countryList]);

  useEffect(() => {
    if (skillDetail && skillDetail.length > 0) {
      const list = [];
      skillDetail?.map((item) => {
        return list.push({
          label: item?.skill,
          value: item?._id,
        });
      });

      if (selectedSkill.length > 0) {
        let selectedSkillList = [];
        selectedSkill.map((data) => {
          selectedSkillList.push(
            list.filter((dat) => dat.label === data.label)[0]
          );
        });

        setSelectedSkill(selectedSkillList);
        setJobApplicationData({
          ...jobApplicationData,
          skillSet: selectedSkillList.map((data) => data.value),
        });
      }
      setSkillSetOption([
        ...list,
        {
          label: "",
          value: "",
        },
      ]);
    } else {
      setSkillSetOption([]);
    }
  }, [skillDetail]);

  useEffect(() => {
    dispatch(countryCodeListing());

    dispatch(getQualificationList());
  }, []);

  useEffect(() => {
    if (addJob && addJob.status === 1) {
      const addFiles = Promise.all(
        uploadList.map((item) => {
          let payload = new FormData();
          payload.append("userId", addJob?.data?._id);
          payload.append("type", item.name);
          payload.append("file", item.item);
          // return dispatch(candidateFileUpload(payload));
        })
      );

      if (uploadList?.length > 0) {
        Promise.all([addFiles]).then((values) => {
          setJobSuccess(true);
          setIsLoading(false);
          setShow(true);
          showSuccessToast(addJob.message);
        });
      } else {
        setJobSuccess(true);
        setShow(true);
        showSuccessToast(addJob.message);
      }
    }
  }, [addJob]);

  // useEffect(() => {
  //   // if (fileUpload) {
  //   //   let list = [];
  //   //   let ids = [];
  //   //   fileUpload?.data?.candidateSkillSet.map((item) =>
  //   //     list.push({ value: item._id, label: item.skill })
  //   //   );
  //   //   setSelectedSkill(list);
  //   //   fileUpload?.data?.candidateSkillSet.map((item) => {
  //   //     if (item._id !== "") {
  //   //       ids.push(item._id);
  //   //     }
  //   //   });
  //   //   setJobApplicationData({
  //   //     ...jobApplicationData,
  //   //     firstName: fileUpload?.data?.firstName
  //   //       ? fileUpload?.data?.firstName
  //   //       : "",
  //   //     lastName: fileUpload?.data?.lastName ? fileUpload?.data?.lastName : "",
  //   //     email: fileUpload?.data?.emailId ? fileUpload?.data?.emailId : "",
  //   //     mobile: fileUpload?.data?.phone ? fileUpload?.data?.phone : "",
  //   //     skillSet: ids.length > 0 ? ids : [],
  //   //   });
  //   //   setErrors({
  //   //     ...errors,
  //   //     firstName: "",
  //   //     lastName: "",
  //   //     email: "",
  //   //     mobile: "",
  //   //   });
  //   // }
  // }, [fileUpload]);

  useEffect(() => {
    if (code && code.length > 0) {
      const list = [];
      code?.map((item) => {
        list.push({
          label: `+${item?.phoneCode}`,
          value: `+${item?.phoneCode}`,
        });
        setPhoneCodeOptionsData(list);
      });
    }
  }, [code]);

  // useEffect(() => {
  //   if (!selectedFile) {
  //     setPreview(undefined)
  //     return
  //   }
  //   const objectUrl = URL.createObjectURL(selectedFile)
  //   setPreview(objectUrl)
  //   return () => URL.revokeObjectURL(objectUrl)
  // }, [selectedFile])

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onResumeChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      setErrors({
        ...errors,
        succsessFile: "Resume/CV added succsessfully",
        file: "",
      });

      // let type = newFileList[0].originFileObj.name.split(".")[1];
      // setFileType(type);
      // if (type === "pdf") {
      //   const objectUrl = URL.createObjectURL(newFileList[0].originFileObj);
      //   setPreview(objectUrl);
      // }
      //   setFile(newFileList[0].originFileObj);

      //   let formData = new FormData();
      //   formData.append("file", newFileList[0].originFileObj);
      //   await dispatch(addCvDetails(formData));
      //   let payload = new FormData();
      //   payload.append("file", newFileList[0].originFileObj);
      //   payload.append("type", "resume");
      //   // dispatch(candidateFileUpload(payload)).then((res) => {
      //   //   setJobApplicationData({
      //   //     ...jobApplicationData,
      //   //     resume: res?.data?.data?.response,
      //   //   });
      //   // });
    }
  };

  const addResume = async (resume) => {
    let type = resume.name.split(".")[1];
    setFileType(type);

    if (type === "pdf" || type === "docx" || type === "gif") {
      const objectUrl = URL.createObjectURL(resume);
      setPreview(objectUrl);
    }
    setFile(resume);
    let obj = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      skillSet: [],
      resume: "",
    };

    let formData = new FormData();
    formData.append("file", resume);
    await dispatch(addCvDetails(formData)).then((res) => {
      if (res) {
        let list = [];
        let ids = [];
        res?.data?.data?.candidateSkillSet.map((item) =>
          list.push({ value: item._id, label: item.skill })
        );

        // selectedSkill?.length === 0 && setSelectedSkill(list);
        res?.data?.data?.candidateSkillSet.map((item) => {
          if (item._id !== "") {
            ids.push(item._id);
          }
        });

        obj.firstName = res?.data?.data?.firstName
          ? res?.data?.data?.firstName
          : "";
        obj.lastName = res?.data?.data?.lastName
          ? res?.data?.data?.lastName
          : "";
        obj.email = res?.data?.data?.emailId ? res?.data?.data?.emailId : "";
        obj.mobile = res?.data?.data?.phone ? res?.data?.data?.phone : "";
        // obj.skillSet = ids.length > 0 ? ids : [];

        setErrors({
          ...errors,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
      }
    });
    let payload = new FormData();
    payload.append("file", resume);
    payload.append("type", "resume");
    await dispatch(candidateFileUpload(payload)).then((res) => {
      obj.resume = res?.data?.data?.fileUrl + res?.data?.data?.response;
    });

    setJobApplicationData((prevState) => ({
      ...jobApplicationData,
      firstName: prevState.firstName || obj.firstName,
      lastName: prevState.lastName || obj.lastName,
      email: prevState.email || obj.email,
      mobile: prevState.mobile || obj.mobile,
      resume: obj.resume,
      //  skillSet: prevState.skillSet || obj.skillSet,
    }));
  };

  const handleReupload = () => {
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      file: "",
    });

    setSelectedSkill([]);
    let deletePayload = {
      type: "resume",
      filePath: jobApplicationData.resume,
    };
    dispatch(candidateFileDelete(deletePayload));
    setJobApplicationData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      resume: "",
      qualificationId: "",
      qualification: "",
    });
  };

  const handleImageReupload = () => {
    setImage();
    // setPreview("");
    setProfileList([]);
    // setFileType("");
    setErrors({
      ...errors,
      successImageFile: "",
      // file: "",
    });

    let payload = {
      type: "photo",
      filePath: jobApplicationData.photo,
    };
    dispatch(candidateFileDelete(payload));
    setJobApplicationData({
      ...jobApplicationData,
      photo: "",
    });
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleEducationDetails = (e, index, name) => {
    const eduValues = [...educationDetails];
    eduValues[index][name] = e;
    let today = new Date();
    if (name === "pursuing" && e === true) {
      eduValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setEducationDetails(eduValues);
  };

  const handleExpDetail = (e, index, name) => {
    const expValues = [...experienceDetails];
    expValues[index][name] = e;
    let today = new Date();
    if (name === "isWorking" && e === true) {
      expValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setExperienceDetails(expValues);
  };

  /******************* 
   @Purpose : function for + functionality
   @Parameter : {}
   @Author : INIC
  ******************/
  const handleAddEducation = (e) => {
    e.preventDefault();
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
      ...educationDetails,
    ]);
  };

  const handleExeperienceAdd = (e) => {
    e.preventDefault();
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
      ...experienceDetails,
    ]);
  };

  /******************* 
   @Purpose : function for - functionality
   @Parameter : {}
   @Author : INIC
 ******************/
  const handleRemoveEducation = (index) => {
    const educationValues = [...educationDetails];
    let obj = educationDetails[index];
    delete obj["institute"];
    delete obj["degree"];
    delete obj["from"];
    delete obj["to"];
    delete obj["major"];
    delete obj["pursuing"];
    educationValues.splice(index, 1);
    setEducationDetails(educationValues);
  };

  const handleRemoveExperience = (index) => {
    const experienceValues = [...experienceDetails];
    let obj = experienceDetails[index];
    delete obj["company"];
    delete obj["from"];
    delete obj["isWorking"];
    delete obj["occupation"];
    delete obj["summary"];
    delete obj["to"];
    experienceValues.splice(index, 1);
    setExperienceDetails(experienceValues);
  };

  const validateForm = () => {
    let isFormValid = true;
    let error = {
      firstName: "",
      // phoneCode: "",
      lastName: "",
      email: "",
      mobile: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      city: "",
      qualification: "",
      country: "",
      noticePeriod: "",
      // file: "",
      skillSet: "",
    };
    let mobileRegex = "^[0-9][0-9]{9,12}$";
    let emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if (!file) {
    //   error.file = "Please upload resume";
    //   // setErrors(error);
    //   swal({
    //     title: "Please upload Resume/CV first",
    //     icon: "warning",
    //     // buttons: true,
    //     dangerMode: true,
    //   }).then(function (isConfirm) {
    //     if (isConfirm) {
    //     }
    //   });
    //   isFormValid = false;

    //   return isFormValid;
    // } else {
    // error.file = "";

    if (jobApplicationData.firstName === "") {
      error.firstName = "First Name is required";
    } else error.firstName = "";

    if (jobApplicationData.lastName === "") {
      error.lastName = "Last Name is required";
    } else error.lastName = "";

    if (jobApplicationData.email === "") {
      error.email = "Email is required";
    } else if (!new RegExp(emailRegex).test(jobApplicationData.email)) {
      error.email = "Please provide a valid email";
    } else error.email = "";

    // if (jobApplicationData.phoneCode === "") {
    // 	error.phoneCode = "Country Code &";
    // } else error.phoneCode = "";

    if (jobApplicationData.mobile === "") {
      error.mobile = "Mobile number is required";
    } else if (!new RegExp(mobileRegex).test(jobApplicationData.mobile)) {
      error.mobile = "Mobile number in between 10-13 digits";
    } else error.mobile = "";

    if (jobApplicationData.currentEmployer === "") {
      error.currentEmployer = "This field is required.";
    } else error.currentEmployer = "";

    if (jobApplicationData.totalExperiance === "") {
      error.totalExperiance = "This field is required.";
    } else error.totalExperiance = "";

    if (jobApplicationData.currentSalary === "") {
      error.currentSalary = "This field is required.";
    } else error.currentSalary = "";
    if (jobApplicationData.noticePeriod === "") {
      error.noticePeriod = "This field is required.";
    } else error.currentSalary = "";
    if (jobApplicationData.city === "") {
      error.city = "This field is required.";
    } else error.city = "";

    if (jobApplicationData.qualificationId === "") {
      error.qualification = "This field is required";
    } else error.qualification = "";
    if (jobApplicationData.country === "") {
      error.country = "This field is required";
    } else error.qualification = "";
    if (jobApplicationData.skillSet.length === 0) {
      error.skillSet = "This field is required";
    } else error.skillSet = "";
    if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== "" ||
      // error.phoneCode !== "" ||
      error.mobile !== "" ||
      error.currentEmployer !== "" ||
      error.totalExperiance !== "" ||
      error.currentSalary !== "" ||
      error.qualification !== "" ||
      error.city !== "" ||
      error.country !== "" ||
      error.noticePeriod !== "" ||
      error.skillSet !== ""
    ) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    setErrors(error);
    return isFormValid;
    //  }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let files = [];
      file && files.push({ name: "resume", item: file });
      image && files.push({ name: "photo", item: image });
      setUploadList(files);

      setIsLoading(true);
      let list = [];
      jobApplicationData.skillSet.map((item) => list.push(item.value));

      let obj = {
        postingTitle: [jobData._id],
        firstName: jobApplicationData.firstName,
        lastName: jobApplicationData.lastName,
        emailId: jobApplicationData.email,
        phoneCode: jobApplicationData.phoneCode,
        mobile: jobApplicationData.mobile,
        noticePeriod: jobApplicationData.noticePeriod.value,
        currentEmployer: jobApplicationData.currentEmployer,
        totalExperiance: jobApplicationData.totalExperiance,
        currentSalary: jobApplicationData.currentSalary,
        skillSet: jobApplicationData.skillSet,
        // ...(educationDetails.length > 0 && {
        //   educations: educationDetails,
        // }),
        // ...(experienceDetails.length > 0 && {
        //   experiences: experienceDetails,
        // }),
        street: jobApplicationData.street,
        zipCode: jobApplicationData.zipCode,
        city: jobApplicationData.city,
        state: jobApplicationData.state,
        country: jobApplicationData.country,
        //  faceBook: jobApplicationData.faceBook,
        //     linkdin: jobApplicationData.linkdin,
        qualification: jobApplicationData.qualificationId.value,

        resume: jobApplicationData.resume,
        photo: jobApplicationData.photo,
        recruiterName: jobData.assignedRecruiters,
        createdForm: "webForm",
      };
      dispatch(addCandidateData(obj));
    }
  };

  const handleChange = async (file) => {
    if (file) {
      setErrors({
        ...errors,
        succsessFile: "File added succsessfully",
        file: "",
      });
    }
    let type = file.name.split(".")[1];
    if (
      type === "pdf" ||
      type === "docx" ||
      type === "jpg" ||
      type === "jpeg"
    ) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
    setFile(file);
    setSelectedFile(file);
    let formData = new FormData();
    formData.append("file", file);
    await dispatch(addCvDetails(formData));
  };

  const handleImageChange = async ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      setProfileList(newFileList);
      setErrors({
        ...errors,
        successImageFile: "Image added succsessfully",
        file: "",
      });
    }
    setImage(newFileList[0].originFileObj);

    // let payload = new FormData();
    // payload.append("file", newFileList[0].originFileObj);
    // payload.append("type", "photo");
    // dispatch(candidateFileUpload(payload)).then((res) => {
    //   setJobApplicationData({
    //     ...jobApplicationData,
    //     photo: res?.data?.data?.response,
    //   });
    // });
  };

  const addProfile = async (profile) => {
    let payload = new FormData();
    payload.append("file", profile);
    payload.append("type", "photo");
    dispatch(candidateFileUpload(payload)).then((res) => {
      setJobApplicationData({
        ...jobApplicationData,
        photo: res?.data?.data?.response,
      });
    });
  };

  const handleDataChange = (e, type) => {
    if (type === "num") {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: e.target.value,
      });
    } else if (type === "email") {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: emailValidator(e.target.value),
      });
    } else {
      setJobApplicationData({
        ...jobApplicationData,
        [e.target.name]: textValidator(e.target.value),
      });
    }
  };

  const getSkillSetList = (searchText) => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      if (searchText !== "") {
        await dispatch(getSkillDetails(searchText));
      } else {
        setSkillSetOption(null);
      }
    }, 600);
  };

  const resetData = () => {
    setIsLoading(false);
    setJobApplicationData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      // phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      qualificationId: "",
      qualification: "",
    });
    setSelectedSkill([]);
    setJobSuccess(false);
    setInterested(false);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      // phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: "",
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      qualificationId: "",
      qualification: "",
    });
    setShow(false);
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
    ]);
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
    ]);
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      successImageFile: "",
      file: "",
    });
    setImage();
    setProfileList([]);
  };

  const fileTypes = ["PDF", "DOC", "DOCX", "JPG", "JPEG", "PNG"];

  var noticePeriod = [
    "Immediately",
    "15 Days",
    "30 Days",
    "45 Days",
    "60 Days",
    "90 Days",
  ].map(function (notperiod) {
    return { label: notperiod, value: notperiod };
  });

  const handleClose = () => {
    setShow(false);
    resetData();
  };
  // const formatPhoneNumber = (phoneNumber, country) => {
  //   if (!phoneNumber || !country) return phoneNumber;

  //   const reducedPhone = phoneNumber.replace(country.dialCode, "");
  //   const formattedPhone = `+${country.dialCode}-${reducedPhone}`;

  //   return formattedPhone;
  // };
  const formatPhoneNumber = async (phoneNumber, country) => {
    const reducedPhone = phoneNumber.replace(country.dialCode, "");

    setJobApplicationData({
      ...jobApplicationData,
      mobile: reducedPhone,
      phoneCode: country.dialCode,
    });
  };
  const renderPreview = useMemo(() => {
    if (fileType === "pdf" || fileType === "gif") {
      return <iframe src={preview} />;
    } else if (!showPreview) {
      return (
        <DocViewer
          documents={[
            {
              uri: jobApplicationData?.resume,
              fileType: fileType,
            },
          ]}
          pluginRenderers={[
            PDFRenderer,
            MSDocRenderer,
            PNGRenderer,
            JPGRenderer,
          ]}
          theme={{
            primary: "#5296d8",
            secondary: "#00000099",
            tertiary: "#5296d899",
            text_primary: "#ffffff",
            text_secondary: "#5296d8",
            text_tertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
        />
      );
    }

    // Default case or other conditions if needed
    return null;
  }, [preview, fileType, showPreview, jobApplicationData.resume]);

  return (
    <React.Fragment>
      <Container>
        <header
          className="consultant-header d-block"
          // style={{ backgroundImage: `url(${bgImg})` }}
        >
          <Image
            src={inicLogo}
            width="140"
            height="140"
            alt="IndiaNIC Infotech Pvt. Ltd."
            className="inic-logo"
          />
          <div className="my-5">
            {/* <h4 className="text-white">
            IndiaNIC Infotech Ltd.
          </h4> */}
            <h4 className="text-primary mb-0">Apply for</h4>
            <h1 className="text-dark display-4 font-weight-bold">
              {jobData && jobData.title}
            </h1>
            {/* <h4 className="text-white mb-4">
            Posted on{" "}
            
          </h4> */}
            {/* {!interested && (
            <>
              {" "}
              <Button variant="outline-light" className="mr-3">
                Refer a Friend
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  setInterested(true);
                  setJobSuccess(false);
                  setFile(false);
                }}
              >
                I'm interested
              </Button>
            </>
          )} */}
            {/*<div className="social-icons-header">
            <span className="bx bxs-share-alt"></span>
            <Button variant="outline-light" title="Facebook">
              <span className="bx bxl-facebook"></span>
            </Button>
            <Button variant="outline-light" title="Instagram">
              <span className="bx bxl-instagram"></span>
            </Button>
            <Button variant="outline-light" title="Linked In">
              <span className="bx bxl-linkedin"></span>
            </Button>
            <Button variant="outline-light" title="Whatsapp">
              <span className="bx bxl-whatsapp"></span>
            </Button>
            <Button variant="outline-light" title="Telegram">
              <span className="bx bxl-telegram"></span>
            </Button>
            <Button variant="outline-light" title="Telegram">
              <span className="bx bx-link"></span>
            </Button>
          </div> */}
          </div>
        </header>
        {!interested && (
          <div className="jd-body-panel ">
            <React.Fragment>
              {/* <BreadCrumb
              routes={[
                {
                  name: "Job details",
                  path: `/job-detail/${param.id}`,
                },
              ]}
            /> */}
              <Row>
                <Col md="9">
                  <div className="panel">
                    <div className="panel-content pb-0">
                      <h3 className="title text-gray mb-2">Job Description</h3>
                      <section
                        className="job-description-content"
                        dangerouslySetInnerHTML={{
                          __html: jobData && jobData.description,
                        }}
                      />
                    </div>
                    <hr />
                    <div className="jdp-jobdescription panel-content pt-0">
                      <h3 className="title text-gray mb-3">About us</h3>
                      <h4 className="sub-title">About IndiaNIC</h4>
                      <p>
                        With a talented team of 550+ engineers, project managers
                        and leaders, we excel in providing top-notch award
                        winning solutions. Seeing the clients passionate about
                        their idea makes us excited. We always look for new ways
                        to provide solutions and create a better product.
                      </p>

                      <p className="mb-2 text-primary">We work on - </p>

                      <h4 className="sub-title">WEB DEVELOPMENT</h4>
                      <p>
                        We’ve been working with robust and popular web
                        technologies for just about two decades, and it’s
                        something we still love doing today. Whether creating a
                        web presence for your company, an information hub for
                        your business or empowering your apps / IoT devices
                        through backend or an Online Shopping – we cover a wide
                        spectrum of solutions and frameworks by leveraging the
                        power of theinternet.
                      </p>

                      <h4 className="sub-title">MOBILE APPS</h4>
                      <p>
                        From B2B or B2E apps for enterprises, small businesses,
                        and startups, we have a proven track record of creating
                        high-impact, result driven and engaging mobile apps on
                        all popular platforms with Native, Cross-Platform, and
                        Hybrid Technologies.
                      </p>

                      <h4 className="sub-title">UI / UX</h4>
                      <p>
                        Just as the designs themselves, we love the process of
                        creating them as well. The innovative ideas need greater
                        innovation in the way their visual representation is
                        created.
                      </p>
                      <p>
                        We tend to lean more on function over the form. We
                        believe in simplicity and focus. We know the fine
                        differentiator between the visually appealing versus the
                        functional design. We help you craft niche experiences
                        for Web and Mobile via designing Information
                        Architecture, Wireframes, Prototypes and Visual Design.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md="3">
                  <div className="side-panel">
                    <div className="panel">
                      <div className="panel-content">
                        <h3 className="title text-gray mb-3">Requirements</h3>
                        <h4 className="sub-title">Work Experience</h4>
                        <p>
                          {jobData &&
                            jobData.workExperience_min +
                              "-" +
                              jobData.workExperience_max}{" "}
                          years
                        </p>

                        <h4 className="sub-title">Job Type</h4>
                        <p>{jobData && jobData.jobType}</p>

                        <h4 className="sub-title">Posted on</h4>
                        <p>
                          {jobData &&
                            moment(jobData.publishedOn).format("DD MMM YYYY")}
                        </p>

                        <h4 className="sub-title">Skills</h4>
                        {jobData &&
                          jobData?.technologySet &&
                          jobData?.technologySet?.map((item) => {
                            return (
                              <Badge variant="dark" className="mr-2">
                                {item?.skill}
                              </Badge>
                            );
                          })}
                      </div>
                    </div>
                    <Button
                      size="block"
                      variant="primary"
                      onClick={() => {
                        setInterested(true);
                        setFile(false);
                      }}
                      className="py-3"
                    >
                      Apply Here
                    </Button>
                    {/* <Button type="clear" variant="light" size="block" className="mt-3 py-3">Cancel</Button> */}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        )}

        <div className="jd-body-panel job-details-panel">
          {interested && !jobSuccess && (
            <section className="consultant-add-form ">
              <Row>
                {/* <Col md={12}>
                <BreadCrumb
                  routes={[
                    {
                      name: "Job details",
                      path: `/job-detail/${param.id}`,
                    },
                    {
                      name: "Job application",
                    },
                  ]}
                />
              </Col> */}

                <Col lg={12}>
                  {/* Form Fields Begins */}
                  <div className="consultant-add-form-body ">
                    <Form onSubmit={handleSubmit}>
                      <div className="panel">
                        {/* Start: Basic Info */}
                        <div className="panel-header">
                          <h4 className="title">Basic Info</h4>
                        </div>
                        <div className="panel-content py-0">
                          <Row>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formFirstName">
                                <Form.Label>
                                  First Name
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    maxLength="20"
                                    className={
                                      errors.firstName !== "" ? "error-red" : ""
                                    }
                                    // disabled={!file}
                                    value={jobApplicationData.firstName}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                      setErrors({
                                        ...errors,
                                        firstName: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.firstName}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formLastName">
                                <Form.Label>
                                  Last Name
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="lastName"
                                    maxLength="20"
                                    // disabled={!file}
                                    className={
                                      errors.lastName !== "" ? "error-red" : ""
                                    }
                                    value={jobApplicationData.lastName}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                      setErrors({
                                        ...errors,
                                        lastName: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.lastName}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formEmail">
                                <Form.Label>
                                  Email
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    maxLength="45"
                                    // disabled={!file}
                                    className={
                                      errors.email !== "" ? "error-red" : ""
                                    }
                                    value={jobApplicationData.email}
                                    onChange={(e) => {
                                      handleDataChange(e, "email");
                                      setErrors({
                                        ...errors,
                                        email: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.email}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formMobile">
                                <Form.Label>
                                  Mobile
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div className="form-input">
                                  {/* <div className="w-25">
                                  <SimpleSelectComponent
                                    name="mobile"
                                    disabled={!file}
                                    className={
                                      errors.phoneCode
                                        ? "phone-code error-red"
                                        : !file
                                        ? "disabled-field phone-code"
                                        : "phone-code"
                                    }
                                    options={phoneCodeOptionsData}
                                    onValueChange={(e) => {
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          phoneCode: e,
                                        });
                                      e &&
                                        setErrors({
                                          ...errors,
                                          phoneCode: "",
                                        });
                                    }}
                                  />
                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.phoneCode}
                                  </span>
                                </div> */}

                                  <div className="w-100">
                                    <PhoneInput
                                      country={"in"}
                                      countryCodeEditable={false}
                                      enableSearch
                                      autoFormat={false}
                                      maxLength={13}
                                      disableSearchIcon={true}
                                      className={
                                        errors.mobile !== "" ? "error-red " : ""
                                      }
                                      value={
                                        jobApplicationData.phoneCode +
                                        jobApplicationData.mobile
                                      }
                                      onChange={async (
                                        phoneNumber,
                                        country
                                      ) => {
                                        formatPhoneNumber(phoneNumber, country);

                                        setErrors({
                                          ...errors,
                                          mobile: "",
                                        });
                                      }}
                                    />

                                    <span
                                      className="error-msg"
                                      style={{ color: "red" }}
                                    >
                                      {errors.mobile}
                                    </span>

                                    {/* <Form.Control
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className={
                                      errors.mobile !== ""
                                        ? "form-control error-red"
                                        : ""
                                    }
                                    value={jobApplicationData.mobile}
                                    onChange={(e) => {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        mobile: e.target.value,
                                      });
                                      setErrors({
                                        ...errors,
                                        mobile: "",
                                      });
                                    }}
                                  /> */}
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formEmail">
                                <Form.Label>Notice Period</Form.Label>
                                <span className="text-danger">&nbsp;*</span>
                                <div>
                                  <SimpleSelectComponent
                                    name="noticePeriod"
                                    // disabled={!file}
                                    value={jobApplicationData.noticePeriod}
                                    options={noticePeriod}
                                    className={
                                      errors.noticePeriod !== ""
                                        ? "error-red"
                                        : ""
                                    }
                                    onValueChange={(e) => {
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          noticePeriod: e,
                                        });
                                      setErrors({
                                        ...errors,
                                        noticePeriod: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.noticePeriod}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        {/* Ends: Basic Info */}
                        {/* <div className="divider-x"></div> */}
                        {/* Start: Professional Details */}
                        <div className="panel-header pt-0">
                          <h4 className="title">Professional Details</h4>
                        </div>
                        <div className="panel-content py-0">
                          <Row>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formHighQualifHeld">
                                <Form.Label>
                                  {" "}
                                  Highest Qualification Held
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <SimpleSelectComponent
                                    name="qualification"
                                    // disabled={!file}
                                    value={jobApplicationData.qualification}
                                    className={
                                      errors.qualification
                                        ? "error-red"
                                        : !file
                                        ? "disabled-field"
                                        : "form-control"
                                    }
                                    // options={skillSetOption}
                                    // values={selectedSkill}
                                    onValueChange={(e) => {
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          qualificationId: e,
                                          qualification: e,
                                        });
                                      setErrors({
                                        ...errors,
                                        qualification: "",
                                      });
                                    }}
                                  >
                                    {qualificationDetail?.data?.map((data) => (
                                      <option value={data._id}>
                                        {data.qualification}
                                      </option>
                                    ))}
                                  </SimpleSelectComponent>

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.qualification}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formCurrentEmployer">
                                <Form.Label>
                                  Current Employer
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="currentEmployer"
                                    maxLength="30"
                                    // disabled={!file}
                                    className={
                                      errors.currentEmployer !== ""
                                        ? "error-red"
                                        : ""
                                    }
                                    value={jobApplicationData.currentEmployer}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                      setErrors({
                                        ...errors,
                                        currentEmployer: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.currentEmployer}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formtotalExperiance">
                                <Form.Label>
                                  Total Experience(IN YEAR)
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="number"
                                    //    disabled={!file}
                                    className={
                                      errors.totalExperiance !== ""
                                        ? "error-red form-control"
                                        : "form-control"
                                    }
                                    value={jobApplicationData.totalExperiance}
                                    onChange={(e) => {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        totalExperiance: e.target.value,
                                      });
                                      setErrors({
                                        ...errors,
                                        totalExperiance: "",
                                      });
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onInput={(e) => {
                                      if (
                                        e.target.value.length >
                                        e.target.maxLength
                                      )
                                        e.target.value = e.target.value.slice(
                                          0,
                                          e.target.maxLength
                                        );
                                    }}
                                    maxlength={5}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.totalExperiance}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formCurrentSalary">
                                <Form.Label>
                                  Current Salary(IN LPA)
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="number"
                                    // disabled={!file}
                                    className={
                                      errors.currentSalary !== ""
                                        ? "error-red form-control"
                                        : "form-control"
                                    }
                                    value={jobApplicationData.currentSalary}
                                    onChange={(e) => {
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        currentSalary: e.target.value,
                                      });
                                      setErrors({
                                        ...errors,
                                        currentSalary: "",
                                      });
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onInput={(e) => {
                                      if (
                                        e.target.value.length >
                                        e.target.maxLength
                                      )
                                        e.target.value = e.target.value.slice(
                                          0,
                                          e.target.maxLength
                                        );
                                    }}
                                    maxlength={5}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.currentSalary}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"8"} sm={"6"}>
                              <Form.Group controlId="formEmail">
                                <Form.Label>Skill Set</Form.Label>
                                <span className="text-danger">&nbsp;*</span>
                                <div>
                                  <MultiSelectComponent
                                    name="skillSet"
                                    options={skillSetOption.filter(
                                      (data) => data.label !== ""
                                    )}
                                    values={selectedSkill}
                                    className={
                                      errors.skillSet !== "" ? "error-red " : ""
                                    }
                                    // disabled={!file}
                                    onValuesChange={(e) => {
                                      let list = [];

                                      e.map((item) => list.push(item.value));
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          skillSet: list,
                                        });

                                      setSelectedSkill(e);

                                      skillSetOption?.forEach((item) => {
                                        if (
                                          item?.value?.trim() === "" &&
                                          item?.label?.trim() !== ""
                                        ) {
                                          dispatch(
                                            addSkillsMaster({
                                              skill: item.label,
                                            })
                                          );
                                          // setSkillSetOption(e);
                                        }
                                      });
                                      setErrors({
                                        ...errors,
                                        skillSet: "",
                                      });
                                    }}
                                    onSearchChange={(value) => {
                                      if (value && value.trim() !== "") {
                                        const lowercasedValue =
                                          value.toLowerCase();
                                        const isExistingOption =
                                          skillSetOption?.some(
                                            (option) =>
                                              option.label.toLowerCase() ===
                                              lowercasedValue
                                          );
                                        clearTimeout(typingTimer);
                                        if (!isExistingOption) {
                                          typingTimer = setTimeout(() => {
                                            let updatedOptions = skillSetOption;
                                            updatedOptions[
                                              updatedOptions.length - 1
                                            ].label = value;

                                            setSkillSetOption([
                                              ...updatedOptions,
                                            ]);
                                          }, 1000);
                                          return;
                                        }
                                      }
                                    }}
                                    onBlur={() => {
                                      let list = [];
                                      skillDetail?.map((item) => {
                                        return list.push({
                                          label: item?.skill,
                                          value: item?._id,
                                        });
                                      });
                                      setSkillSetOption([
                                        ...list,
                                        {
                                          label: "",
                                          value: "",
                                        },
                                      ]);
                                    }}
                                    onRemoveItem={function (item) {
                                      const skills = selectedSkill?.filter(
                                        function (d) {
                                          return item.value !== d?.value;
                                        }
                                      );
                                      setSelectedSkill(skills);
                                    }}
                                  />
                                  {/* <MultiSelectComponent
                                    name="skillSet"
                                    placeholder="Type to select"
                                    options={skillSetOption}
                                    values={selectedSkill}
                                    className={
                                      errors.skillSet !== "" ? "error-red " : ""
                                    }
                                    // disabled={!file}
                                    onValuesChange={(e) => {
                                      let list = [];
                                      e.map((item) => list.push(item.value));
                                      e &&
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          skillSet: list,
                                        });
                                      setSelectedSkill(e);
                                      setErrors({
                                        ...errors,
                                        skillSet: "",
                                      });
                                    }}
                                    onSearchChange={(e) => {
                                      getSkillSetList(e);
                                    }}
                                    onRemoveItem={function (item) {
                                      const skills = selectedSkill?.filter(
                                        function (d) {
                                          return item.value !== d?.value;
                                        }
                                      );
                                      setSelectedSkill(skills);
                                    }}
                                  ></MultiSelectComponent> */}
                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.skillSet}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        {/* Ends: Professional Details */}
                        {/* <div className="divider-x"></div> */}
                        {/* Start: Educational Details */}
                        {/* <div className="panel-header pt-0">
                        <div className="section-heading">
                          <div className="heading">
                            <h2 className="title">Educational Details</h2>
                          </div>
                        </div>
                      </div>
                      <div className="panel-content">
                        {educationDetails &&
                          educationDetails.map((i, index) => {
                            return (
                              Object.keys(i).includes("institute") && (
                                <Form className="form repeater-default add-columns-btn">
                                  <div className="field-form" key={index}>
                                    <Row>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>
                                            Institute / School
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            name="institute"
                                            value={i.institute}
                                            onChange={(e) =>
                                              handleEducationDetails(
                                                e.target.value,
                                                index,
                                                "institute"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>
                                            Major / Department
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            name="major"
                                            value={i.major}
                                            onChange={(e) =>
                                              handleEducationDetails(
                                                e.target.value,
                                                index,
                                                "major"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>Degree</Form.Label>
                                          <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            name="degree"
                                            value={i.degree}
                                            onChange={(e) =>
                                              handleEducationDetails(
                                                e.target.value,
                                                index,
                                                "degree"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>Duration</Form.Label>
                                          <Row>
                                            <Col sm={6}>
                                              <DatePicker
                                                placeholderText="From"
                                                className="form-control"
                                                value={i.from}
                                                onChange={(date) => {
                                                  handleEducationDetails(
                                                    moment(date).format(
                                                      "MM/yyyy"
                                                    ),
                                                    index,
                                                    "from"
                                                  );
                                                  setStartMonth(new Date(date));
                                                  i.to = "";
                                                }}
                                                selectsStart
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                              />
                                            </Col>

                                            <Col sm={6}>
                                              <DatePicker
                                                placeholderText="To"
                                                className="form-control"
                                                value={
                                                  i.pursuing !== true
                                                    ? i.to
                                                    : "Present"
                                                }
                                                minDate={startMonth}
                                                disabled={i.pursuing === true}
                                                onChange={(date) => {
                                                  handleEducationDetails(
                                                    moment(date).format(
                                                      "MM/YYYY"
                                                    ),
                                                    index,
                                                    "to"
                                                  );
                                                }}
                                                selectsStart
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                              />
                                            </Col>
                                          </Row>
                                          <div className="custom-checkbox text-right mt-2">
                                            <label
                                              className="form-label"
                                              htmlFor={`pursuing${index}`}
                                            >
                                              Currently Pursuing
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="pursuing"
                                                id={`pursuing${index}`}
                                                checked={i.pursuing}
                                                onChange={(e) => {
                                                  handleEducationDetails(
                                                    e.target.checked,
                                                    index,
                                                    "pursuing"
                                                  );
                                                }}
                                              />
                                              <span></span>
                                            </label>
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                  {btnForm.educationDetails && (
                                    <div className="add-row text-right">
                                      {educationDetails.length - 1 ===
                                        index && (
                                        <span
                                          className="bx bxs-plus-circle text-success"
                                          title="Add"
                                          onClick={(e) => {
                                            handleAddEducation(e);
                                          }}
                                        >
                                          <span className="btn-text">Add</span>
                                        </span>
                                      )}
                                      {educationDetails.length !== 1 && (
                                        <span
                                          className="bx bxs-minus-circle text-danger"
                                          title="Delete"
                                          onClick={() =>
                                            handleRemoveEducation(index)
                                          }
                                        >
                                          <span className="btn-text">
                                            Remove
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Form>
                              )
                            );
                          })}
                      </div> */}
                        {/* Ends: Educational Details */}
                        {/* <div className="divider-x"></div> */}
                        {/* Begin: Experience Details */}
                        {/* <div className="panel-header pt-0">
                        <div className="section-heading">
                          <div className="heading">
                            <h2 className="title">Experience Details</h2>
                            <small>
                              <em>
                                To edit/update any field please click over Edit
                                icon on right side so that, it will become
                                editable.
                              </em>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="panel-content py-0">
                        {experienceDetails &&
                          experienceDetails.map((i, index) => {
                            return (
                              Object.keys(i).includes("company") && (
                                <Form className="form repeater-default add-columns-btn">
                                  <div className="field-form" key={index}>
                                    <Row>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>
                                            Occupation / Title
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            name="occupation"
                                            value={i.occupation}
                                            onChange={(e) =>
                                              handleExpDetail(
                                                e.target.value,
                                                index,
                                                "occupation"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>Company</Form.Label>
                                          <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            name="company"
                                            value={i.company}
                                            onChange={(e) =>
                                              handleExpDetail(
                                                e.target.value,
                                                index,
                                                "company"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>Summary</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows="2"
                                            name="summary"
                                            value={i.summary}
                                            onChange={(e) =>
                                              handleExpDetail(
                                                e.target.value,
                                                index,
                                                "summary"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={6} lg={6}>
                                        <Form.Group>
                                          <Form.Label>Work Duration</Form.Label>
                                          <Row>
                                            <Col sm={6}>
                                              <DatePicker
                                                placeholderText="From"
                                                className="form-control"
                                                value={i.from}
                                                onChange={(date) => {
                                                  handleExpDetail(
                                                    moment(date).format(
                                                      "MM/yyyy"
                                                    ),
                                                    index,
                                                    "from"
                                                  );
                                                  setExperienceStartMont(
                                                    new Date(date)
                                                  );
                                                  i.to = "";
                                                }}
                                                selectsStart
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                              />
                                            </Col>
                                            <Col sm={6}>
                                              <DatePicker
                                                placeholderText="To"
                                                className="form-control"
                                                value={
                                                  i.isWorking !== true
                                                    ? i.to
                                                    : "Present"
                                                }
                                                minDate={experienceStartMonth}
                                                disabled={i.isWorking === true}
                                                onChange={(date) => {
                                                  handleExpDetail(
                                                    moment(date).format(
                                                      "MM/yyyy"
                                                    ),
                                                    index,
                                                    "to"
                                                  );
                                                }}
                                                selectsStart
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                              />
                                            </Col>
                                          </Row>
                                          <div className="custom-checkbox text-right mt-2">
                                            <label
                                              className="form-label"
                                              htmlFor={`isWorking${index}`}
                                            >
                                              I currently work here
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="isWorking"
                                                id={`isWorking${index}`}
                                                checked={i.isWorking}
                                                onChange={(e) => {
                                                  handleExpDetail(
                                                    e.target.checked,
                                                    index,
                                                    "isWorking"
                                                  );
                                                }}
                                              />
                                              <span></span>
                                            </label>
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="add-row text-right">
                                    {experienceDetails.length - 1 === index && (
                                      <span
                                        className="bx bxs-plus-circle text-success"
                                        title="Add"
                                        onClick={(e) => {
                                          handleExeperienceAdd(e);
                                        }}
                                      >
                                        <span className="btn-text">Add</span>
                                      </span>
                                    )}
                                    {experienceDetails.length !== 1 && (
                                      <span
                                        className="bx bxs-minus-circle text-danger"
                                        title="Delete"
                                        onClick={() =>
                                          handleRemoveExperience(index)
                                        }
                                      >
                                        <span className="btn-text">Remove</span>
                                      </span>
                                    )}
                                  </div>
                                </Form>
                              )
                            );
                          })}
                      </div> */}
                        {/* Ends: Experience Details */}
                        {/* <div className="divider-x"></div> */}
                        {/* Begin: Address Details */}
                        <div className="panel-header pt-0">
                          <h4 className="title">Address Details</h4>
                        </div>
                        <div className="panel-content py-0">
                          <Row>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formStreet">
                                <Form.Label>Street</Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="street"
                                    maxLength="45"
                                    // disabled={!file}

                                    className={
                                      errors.skillSet !== ""
                                        ? "error-red form-control"
                                        : "form-control"
                                    }
                                    value={jobApplicationData.street}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formZip">
                                <Form.Label>Zip / Postal Code</Form.Label>
                                <div>
                                  <InputMask
                                    // disabled={!file}
                                    className={"form-control"}
                                    mask={
                                      jobApplicationData.zipCode !== ""
                                        ? "999999"
                                        : ""
                                    }
                                    maskChar=""
                                    value={jobApplicationData.zipCode}
                                    onChange={(e) =>
                                      setJobApplicationData({
                                        ...jobApplicationData,
                                        zipCode: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                {/* <Form.Control
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  setJobApplicationData({
                                    ...jobApplicationData,
                                    zipCode: e.target.value,
                                  });
                                }}
                              /> */}
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formCity">
                                <Form.Label>
                                  City
                                  <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="city"
                                    maxLength="30"
                                    // disabled={!file}
                                    className={
                                      errors.city !== "" ? "error-red" : ""
                                    }
                                    value={jobApplicationData.city}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                      setErrors({
                                        ...errors,
                                        city: "",
                                      });
                                    }}
                                  />

                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.city}
                                  </span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formProvince">
                                <Form.Label>State / Province</Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="state"
                                    maxLength="30"
                                    // disabled={!file}
                                    // className={!file ? "disabled-field" : ""}
                                    value={jobApplicationData.state}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={"4"} sm={"6"}>
                              <Form.Group controlId="formCountry">
                                <Form.Label>Country</Form.Label>
                                <span className="text-danger">&nbsp;*</span>
                                <div>
                                  <SimpleSelectComponent
                                    placeholder="Select country"
                                    className={
                                      errors.country !== "" ? "error-red" : ""
                                    }
                                    options={countryOptions}
                                    defaultValue={{
                                      label: jobApplicationData?.country.label,
                                      value: jobApplicationData?.country.value,
                                    }}
                                    onValueChange={(e) => {
                                      if (e === undefined) {
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          country: "",
                                        });
                                      } else {
                                        setJobApplicationData({
                                          ...jobApplicationData,
                                          country: e.value,
                                        });
                                        setErrors({ ...errors, country: "" });
                                      }
                                    }}
                                  />
                                  <span
                                    className="error-msg"
                                    style={{ color: "red" }}
                                  >
                                    {errors.country}
                                  </span>
                                  {/* <Form.Control
                                    type="text"
                                    name="country"
                                    maxLength="30"
                                    // disabled={!file}
                                    // className={!file ? "disabled-field" : ""}
                                    value={jobApplicationData.country}
                                    onChange={(e) => {
                                      handleDataChange(e);
                                    }}
                                  /> */}
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <div className="divider-x"></div>
                        <Row>
                          <Col lg={6} sm={6}>
                            {/* File Uploader */}
                            <div className="document-file-uploader mb-5">
                              <div className="section-title-heading d-block">
                                <h4 className="doc-name mb-3">
                                  Upload Resume/CV
                                  {/* <span className="text-danger">&nbsp;*</span> */}
                                </h4>
                                {/* <span className="divider"></span> */}
                              </div>

                              <div className="document-upload">
                                <div className="document-uploader-block">
                                  {file && (
                                    <div className="preview-delete-btn">
                                      {(preview ||
                                        jobApplicationData?.resume) && (
                                        <Button
                                          variant="icon"
                                          onClick={() => setShowPreview(true)}
                                          title="Preview"
                                        >
                                          <span className="bx bx-show"></span>
                                        </Button>
                                      )}
                                      <Button
                                        variant="icon"
                                        onClick={handleReupload}
                                        title="Re-Upload"
                                      >
                                        <span className="bx bx-upload"></span>
                                      </Button>
                                    </div>
                                  )}
                                  {!preview && (
                                    <>
                                      <div className="file-uploader-block">
                                        <div className="upload-icon-title">
                                          {/* <span className="bx bx-cloud-upload"></span> */}
                                          <Image
                                            src={UploadIcon}
                                            className="upload-resume-doc"
                                            alt="Upload-icon"
                                          />
                                          <p className="upload-icon-title-text mt-2 mb-0">
                                            Upload or Drag your Resume here
                                          </p>
                                          <p className="or-text position-relative m-0 ">
                                            OR
                                          </p>
                                          <button
                                            type="button"
                                            class="btn btn-light "
                                          >
                                            Browse
                                          </button>
                                        </div>
                                        {!fileType && (
                                          <div className="upload-file-extensions">
                                            {fileTypes.map((badge) => (
                                              <Badge
                                                key={badge}
                                                variant="dark"
                                                className="badge-custom"
                                              >
                                                {badge}
                                              </Badge>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                      <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onResumeChange}
                                        customRequest={({
                                          file: resume,
                                          onSuccess,
                                          onError,
                                        }) => {
                                          Promise.resolve().then(() => {
                                            if (
                                              resume.name.split(".")[1] !==
                                              "pdf"
                                            ) {
                                              onSuccess();
                                              addResume(resume);
                                            } else {
                                              addResume(resume);
                                            }
                                          });
                                        }}
                                        // onPreview={onPreview}
                                        accept=".pdf,.doc,.docx,.jpg,.jpeg, .gif"
                                        onRemove={() => {
                                          setFile();
                                          setPreview("");
                                          setFileList([]);
                                          setFileType("");
                                          setErrors({
                                            ...errors,
                                            succsessFile: "",
                                            file: "",
                                          });
                                        }}
                                      >
                                        {fileList.length < 1}
                                      </Upload>{" "}
                                    </>
                                  )}
                                  {preview && (
                                    <div className="preview-frame">
                                      {renderPreview}
                                    </div>
                                  )}
                                  {/* {preview && (
                                    <div className="preview-frame">
                                      <iframe src={preview} />{" "}
                                    </div>
                                  )} */}
                                </div>

                                {/* {(fileType === "docx" ||
                                  fileType === "doc") && (
                                  <img
                                    src={docIcon}
                                    alt="Word document icon"
                                    className="preview-doc-icon"
                                  />
                                )} */}
                              </div>
                              <small style={{ color: "red" }}>
                                {errors.file}
                              </small>
                              <small style={{ color: "green" }}>
                                {errors.succsessFile}
                              </small>
                            </div>

                            {/* File Uploader */}
                          </Col>
                          <Col lg={6} sm={6}>
                            {/* Begin: Attach Image */}
                            <div className="document-file-uploader upload-img">
                              <div className="document-upload">
                                {profileList && (
                                  <>
                                    <div className="section-title-heading d-block">
                                      <h4 className="doc-name mb-3">
                                        Upload Profile Picture
                                      </h4>
                                      {/* <span className="divider"></span> */}
                                    </div>
                                    <div className="position-relative profile-img-block">
                                      <div className="document-uploader-block">
                                        <div className="preview-delete-btn">
                                          {image && (
                                            <Button
                                              variant="icon"
                                              onClick={handleImageReupload}
                                              title="Re-Upload"
                                            >
                                              <span className="bx bx-upload"></span>
                                            </Button>
                                          )}
                                        </div>
                                        <div className="upload-icon-title">
                                          {/* <span className="bx bx-cloud-upload"></span> */}
                                          <Image
                                            src={UploadImageIcon}
                                            alt="Upload-image-icon"
                                          />
                                        </div>
                                        {profileList.length === 0 && (
                                          <>
                                            {" "}
                                            <small className="upload-icon-title-text">
                                              Upload or Drag here
                                            </small>
                                          </>
                                        )}
                                        <ImgCrop grid rotate>
                                          <Upload
                                            listType="picture-card"
                                            fileList={profileList}
                                            onChange={(e) =>
                                              handleImageChange(e)
                                            }
                                            customRequest={({
                                              file,
                                              onSuccess,
                                              onError,
                                            }) => {
                                              Promise.resolve().then(() => {
                                                onSuccess();
                                                addProfile(file);
                                              });
                                            }}
                                            onPreview={onPreview}
                                            onRemove={() => {
                                              setProfileList([]);
                                              setImage();
                                              setErrors({
                                                ...errors,
                                                successImageFile: "",
                                              });
                                            }}
                                          >
                                            {profileList.length < 1}
                                          </Upload>
                                        </ImgCrop>
                                      </div>
                                    </div>
                                    {!errors.successImageFile && (
                                      <div className="upload-file-extensions">
                                        <Badge
                                          variant="dark"
                                          className="badge-custom"
                                        >
                                          JPG
                                        </Badge>
                                        <Badge
                                          variant="dark"
                                          className="badge-custom"
                                        >
                                          PNG
                                        </Badge>
                                        <Badge
                                          variant="dark"
                                          className="badge-custom"
                                        >
                                          JPEG
                                        </Badge>
                                      </div>
                                    )}

                                    <small style={{ color: "green" }}>
                                      {errors.successImageFile}
                                    </small>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* Ends: Attach Image */}
                          </Col>
                        </Row>
                        {/* Ends: Address Details */}
                        {/* <div className="divider-x"></div> */}
                        {/* Begin: Social Network Details */}
                        {/* <div className="panel-header pt-0">
                        <h4 className="title">Social Network</h4>
                      </div>
                      <div className="panel-content py-0">
                        <Row>
                          <Col md="6">
                            <Form.Group controlId="formLinkedIn">
                              <Form.Label>LinkedIn</Form.Label>
                              <Form.Control
                                type="text"
                                name="linkdin"
                                maxLength="30"
                                value={jobApplicationData.linkdin}
                                onChange={(e) => {
                                  handleDataChange(e, "num");
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formFacebook">
                              <Form.Label>Facebook</Form.Label>
                              <Form.Control
                                type="text"
                                name="faceBook"
                                maxLength="30"
                                value={jobApplicationData.faceBook}
                                onChange={(e) => {
                                  handleDataChange(e, "num");
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div> */}
                        {/* Ends: Social Network Details */}
                      </div>

                      {/* Begin: Submit Button */}
                      <div className="button-group d-flex justify-content-end align-items-center my-4">
                        {!isLoading && (
                          <>
                            <Button
                              variant="secondary"
                              type="button"
                              className="mr-2"
                              onClick={resetData}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="primary"
                              type="submit"
                              disabled={isLoading}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                        {isLoading && (
                          <>
                            <Button variant="primary d-inline-flex align-items-center">
                              Please wait a moment...
                              <Spinner
                                animation="border"
                                variant="light"
                                className="spinner-loader ml-2"
                              />
                            </Button>
                          </>
                        )}
                      </div>
                      {/* Ends: Submit Button */}
                    </Form>
                  </div>
                  {/* Form Fields Ends */}
                </Col>
              </Row>
            </section>
          )}
        </div>
      </Container>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="text-center flex-shrink-0 flex-grow-1">
            <span className="bx bx-check-circle display-4 d-block text-success"></span>
            <h5 className="text-secondary mb-0">Success!</h5>
          </div>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <p>
            Dear{" "}
            {addJob &&
              addJob.data &&
              addJob.data.firstName + " " + addJob.data.lastName}
            ,
          </p>
          <p>Thank you for applying and sharing the information.</p>
          <p>
            Our team will get in touch with you soon. If you have submitted
            details with no current openings with us at the moment, please look
            out for any emails from IndiaNIC. We will be in touch with you when
            appropriate vacancies open up.
          </p>
          <p className="mb-2">
            To know more about IndiaNIC - visit{" "}
            <a href="candidate-portal-indianic.com" target="_blank">
              https://www.indianic.com
            </a>
          </p>
          <p>
            Your further updates will be send to your{" "}
            <a
              href="http://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {addJob && addJob.data && addJob.data.emailId}
            </a>{" "}
          </p>
          <h5 className="font-weight-semibold">HR Team</h5>
          <p className="font-weight-medium mb-1">
            Have a Good Day and Best wishes for your further process.
          </p>
          {/* <img src={bgImg} alt="Career Page Header" /> */}
          <p>+91 (79) 6191 6000</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={resetData}>Ok</Button>
          {/* <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showPreview} size="lg" onHide={() => setShowPreview(false)}>
        <Modal.Header closeButton>
          {/* <h5 className="text-secondary">Preview</h5>
          <div className="text-center">
          </div> */}
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {(preview || jobApplicationData?.resume) &&
            (fileType !== "gif" ? (
              <DocViewer
                documents={[
                  {
                    uri: jobApplicationData.resume,
                    fileType: fileType,
                  },
                ]}
                pluginRenderers={[
                  PDFRenderer,
                  MSDocRenderer,
                  PNGRenderer,
                  JPGRenderer,
                ]}
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                }}
                theme={{
                  primary: "#5296d8",
                  secondary: "#00000099",
                  tertiary: "#5296d899",
                  text_primary: "#ffffff",
                  text_secondary: "#5296d8",
                  text_tertiary: "#00000099",
                  disableThemeScrollbar: false,
                }}
              />
            ) : (
              <iframe title="resume" src={preview} />
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            Close
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setShowPreview(false);
              handleReupload();
            }}
          >
            Re-Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </React.Fragment>
  );
};

export default JobDetails;
