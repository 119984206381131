import React from "react";
import { Skeleton } from "antd";
const SkeletonLoader = () => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "block",
          width: 1000,
          padding: 30,
          height: 1000,
        }}
      >
        <Skeleton active paragraph={{ rows: 25 }} />
      </div>
    </React.Fragment>
  );
};

export default SkeletonLoader;
