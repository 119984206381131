import React from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import  MultiSelect  from "../../../components/Multiselect/MultiSelectComponent";
// import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';

const settingForm = () => {
  return (
		<React.Fragment>
        {/* <BreadCrumb
          routes={[
            {
              name: "Via Consultant Form",
              path: "/web-form",
            },
            {
              name: "Form Settings",
              // path: "/webForm",
            },
          ]}
        /> */}
        <Container>
          <Row>
            <Col md="6">
              <div className="consultant-select panel mt-4">
                <div className="panel-content">
                  <Form.Group>
                    <div className="custom-radio">
                      <Form.Label className="form-label">
                        Choose a Consultant
                        <input
                          type="radio"
                          className="form-check-input"
                          name="selectConsultant"
                          id="selectConsultant1"
                        />
                        <span></span>
                      </Form.Label>
                    </div>
                    <div className="pl-5">
                      <MultiSelect />
                      {/* <div className="disabled-input-and-copy">
                        <span className="disabled-input-field" aria-disabled></span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button size="sm" variant="primary" className="mr-2">
                          Re-Generate
                        </Button>
                        <Button size="sm" variant="light">
                          Copy
                        </Button>
                      </div> */}
                    </div>

                    <div className="custom-radio mt-5">
                      <Form.Label className="form-label">
                        Public
                        <input
                          type="radio"
                          className="form-check-input"
                          name="selectConsultant"
                          id="selectConsultant2"
                        />
                        <span></span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
		</React.Fragment>
	);
};

export default settingForm;
