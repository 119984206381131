export default {
  LOGIN_USER: "LOGIN_USER",
  RESET_USER: "RESET_USER",
  JOB_LISTING: "JOB_LISTING",
  GET_JOB_BY_ID: "GET_JOB_BY_ID",
  GET_CANDIDATE_CV_DETAILS: "GET_CANDIDATE_CV_DETAILS",
  GET_SKILL_DETAILS: "GET_SKILL_DETAILS",
  ADD_CANDIDATE_DETAILS: "ADD_CANDIDATE_DETAILS",
  GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
  ADD_SKILLS_MASTER: "ADD_SKILLS_MASTER",
  ADD_WALK_IN_DRIVE: "ADD_WALK_IN_DRIVE",
  MASTER_TECHNOLOGY_API_LISTING: "MASTER_TECHNOLOGY_API_LISTING",

  GET_QUALIFICATION_DETAILS: "GET_QUALIFICATION_DETAILS",
  FILEUPLOAD_CANDIDATE: "FILEUPLOAD_CANDIDATE",
  CANDIDATE_FILEDELETE: "CANDIDATE_FILEDELETE",
  GET_FORM_SETTING: "GET_FORM_SETTING",
  GET_WALFINDRIVE_DETAILS: "GET_WALFINDRIVE_DETAILS",
  COUNTRY_DETAIL_LIST: "COUNTRY_DETAIL_LIST",
};
