import React from "react";
import DataTable from "react-data-table-component";
import NoDataFoundTableError from "../../utils/errorMessages";
import LinearLoader from "../Loader/LinerLoader";
import SkeletonLoader from "../Loader/SkeletonLoader";

const DataTableComponent = (props) => {
  /******************* 
 @Purpose : Add ALL in pagination options
 @Parameter : {}
 @Author : INIC
 ******************/
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  /******************* 
  @Purpose : Set custom checkbox 
  @Parameter : value
  @Author : INIC
  ******************/
  const customCheckbox = (value) => (
    <div className="custom-checkbox">
      <label className="datatable-checkbox-label">
        <input type="checkbox" {...value} />
        <span></span>
      </label>
    </div>
  );

  return (
    <DataTable
      {...props}
      selectableRows={props.selectableRows === false ? false : true}
      selectableRowsComponent={customCheckbox}
      noDataComponent={<NoDataFoundTableError name={props.tableError} />}
      pagination={props.pagination === false ? false : true}
      paginationPerPage={
        props.paginationPerPages ? props.paginationPerPages : 25
      }
      paginationRowsPerPageOptions={
        props.paginationPerPages
          ? [10, 20, 30, 40, 50, 100, 150, 200]
          : [25, 50, 100, 150, 200]
      }
      paginationServer
      sortServer
      // progressComponent={<LinearLoader />}
      progressComponent={<SkeletonLoader />}
      paginationComponentOptions={paginationComponentOptions}
      fixedHeader
      selectableRowsVisibleOnly={true}
      // fixedHeaderScrollHeight="540px"
      // highlightOnHover={props.highlightOnHover === false ? false : true}
    />
  );
};

export default DataTableComponent;
