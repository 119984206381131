import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { getItem, setItem } from "../../utils/Functions";

/************************************ 
   @Purpose : Used for Sidebar
   @Parameter : 
   @Author : INIC
   *********************************/

function Sidebar(props) {
  const toggle = getItem("toggle");
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const setToggle = (clickedToggle) => {
    setItem("toggle", clickedToggle);
  };
  const sidebar = () => {
    setSidebarToggle(!sidebarToggle);
    if (sidebarToggle) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-inner-wrapper">
        <div className="close-left-sidebar three col" onClick={sidebar}>
          <div className="hamburger" id="hamburger-3">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="sidebar-menu-list">
            {/* <li className="menu-list-item">
              <Link to="/dashboard" className="menu-link active-link">
                <span className="bx bx-home-alt"></span>
                <span className="menu-title">Dashboard</span>
              </Link>
            </li> */}
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master"
                className="menu-link"
              >
                <span className="bx bx-trophy"></span>
                <span className="menu-title">Skill</span>
              </NavLink>
            </li>
            <li className="menu-list-item">
              <NavLink
                activeClassName="active"
                to="/master/candidate-source"
                className="menu-link"
              >
                <span className="bx bx-link"></span>
                <span className="menu-title">Candidate Source</span>
              </NavLink>
            </li>
            {/* Not in use for now, comment */}
            <li className="menu-list-item child-menu level2">
              <NavLink activeClassName="active" to="/master/technology" className="menu-link">
                <span className="bx bx-laptop"></span>
                <span className="menu-title">Technology</span>
              </NavLink>
            </li>
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/educational-qualification"
                className="menu-link"
              >
                <span className="bx bx-book-alt"></span>
                <span className="menu-title">Educational Qualification</span>
              </NavLink>
            </li>
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/hiring-pipeline"
                className="menu-link"
              >
                <span className="bx bx-search-alt"></span>
                <span className="menu-title">Hiring Pipelines</span>
              </NavLink>
            </li>
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/interview-name"
                className="menu-link"
              >
                <span className="bx bx-message-rounded-dots"></span>
                <span className="menu-title">Interview Title</span>
              </NavLink>
            </li>
            {/* <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/interview-schedule"
                className="menu-link"
              >
                <span className="bx bx-notification"></span>
                <span className="menu-title">Interview Schedule Status</span>
              </NavLink>
            </li> */}
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/country"
                className="menu-link"
              >
                <span className="bx bx-globe"></span>
                <span className="menu-title">Country</span>
              </NavLink>
            </li>
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/time-zone"
                className="menu-link"
              >
                <span className="bx bx-time-five"></span>
                <span className="menu-title">Time Zone</span>
              </NavLink>
            </li>
            <li className="menu-list-item child-menu level2">
              <NavLink
                activeClassName="active"
                to="/master/currency"
                className="menu-link"
              >
                <span className="bx bx-rupee"></span>
                <span className="menu-title">Currency</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;