import {
  getItem,
  showErrorToast,
  setItem,
  showSuccessToast,
} from "../../utils/Functions";
import { API_URL } from "../../config/index";
import API from "../../api/Routes";
import ACTION from "../../actions/index";
import ApiServices from "../../services/Api.services";

import axios from "axios";

/******************** 
   @Purpose : Used for Login
   @Parameter : 
   @Author : INIC
   *******************/

export const login = (payload) => async (dispatch) => {
  // const { googleId } = payload;
  try {
    await ApiServices.postApi(API_URL + API.LOGIN, payload).then((response) => {
      dispatch({
        type: ACTION.LOGIN_USER,
        payload: response?.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};

export const resetUser = () => (dispatch) => {
  dispatch({
    type: ACTION.RESET_USER,
  });
};
