import { paths } from "./Constant";
import Login from "../pages/Login/Login";
import Home from "../pages/HomePage/Homepage";
import WebForm from "../pages/WebForm/WebForm";
import WebFormViewDetail from "../pages/WebForm/WebFormViewDetail";
import ConsultantForm from "../pages/WebForm/ConsultantForm";
import FormSettings from "../pages/WebForm/tabs/FormSettings";
import JobApplicationForm from "../pages/WebForm/JobApplicationForm";
import ReferralForm from "../pages/WebForm/ReferralForm";
import Career from "../pages/Career/Career";
import JobDetails from "../pages/Career/JobDetails";
import WalkInDrive from "../pages/WalkInDrive/walkInDrive";

const {
  login,
  // home,
  webForm,
  webFormViewDetail,
  consultantForm,
  formSettings,
  jobApplicationForm,
  referralForm,
  career,
  jobDetails,
  walkInDrive,
} = paths;

export const routes = {
  Login: {
    path: login,
    component: Login,
    exact: true,
    private: false,
  },
  // Home: {
  //   path: home,
  //   component: Home,
  //   exact: true,
  //   private: true,
  // },
  WebForm: {
    path: webForm,
    component: WebForm,
    exact: true,
    private: true,
  },
  WebFormViewDetail: {
    path: webFormViewDetail,
    component: WebFormViewDetail,
    exact: true,
    private: true,
  },
  ConsultantForm: {
    path: consultantForm,
    component: ConsultantForm,
    exact: true,
    private: true,
  },
  FormSettings: {
    path: formSettings,
    component: FormSettings,
    exact: true,
    private: true,
  },
  JobApplicationForm: {
    path: jobApplicationForm,
    component: JobApplicationForm,
    exact: true,
    private: false,
  },
  ReferralForm: {
    path: referralForm,
    component: ReferralForm,
    exact: true,
    private: false,
  },
  Career: {
    path: career,
    component: Career,
    exact: true,
    private: false,
  },
  JobDetails: {
    path: jobDetails,
    component: JobDetails,
    exact: true,
    private: false,
  },
  WalkInDrive: {
    path: walkInDrive,
    component: WalkInDrive,
    exact: true,
    private: false,
  },
};

export const renderRoutes = Object.entries(routes);
