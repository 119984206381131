import React from "react";
import { Container } from "react-bootstrap";

/************************************************ 
@Purpose : Used for Footer
@Parameter : {}
@Author : INIC
**************************************************/
function Footer() {
  return (
    // <footer className="mt-auto text-center p-3">
    //   <h6 className="font-weight-medium mb-0">
    //     IndiaNIC © {new Date().getFullYear()}
    //   </h6>
    // </footer>
    <footer className="mt-auto border-top py-3 bg-white">
    <Container>
      <div className='d-flex align-items-center justify-content-md-between flex-md-nowrap flex-wrap  justify-content-center'>
        <p className='mb-md-0 '>Candidate Portal</p>
        <p className='mb-0 ms-md-3 ms-0'>IndiaNIC Infotech Limited © {new Date().getFullYear()}</p>
      </div>
    </Container>
  </footer>
  );
}
export default Footer;
