import React from 'react';
import { Container } from 'react-bootstrap';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import Layout from "../../components/Layout";
import Tabs from '../../components/Tabs/Tabs';
import { Link } from 'react-router-dom';
import ConsultantForm from './ConsultantForm';
import FormSettings from './tabs/FormSettings';
import JobApplicationForm from './JobApplicationForm';
import ReferralForm from './ReferralForm';

const WebFormViewDetail = () => {
  return (
		<React.Fragment>
      <Layout>
        <BreadCrumb
          routes={[
            {
              name: "Web Form",
              path: "/web-form",
            },
            {
              name: "Consultant Form",
              // path: "/webForm",
            },
          ]}
        />
        <Container>
          <Tabs
            initialActiveTab="ConsultantForm"
            components={[
              {
                name: <ConsultantForm />,
                button: "ConsultantForm",
                tabName: "View Form",
              },
              {
                name: <FormSettings />,
                button: "FormSettings",
                tabName: "Form Settings",
              },
            ]}
            extraProps = {
              <h5 className="title mb-0">
                <Link to="/" className="d-inline-flex align-items-center">
                  Share <span className="bx bx-link-external"></span>
                </Link>
              </h5>
            }
          />
        </Container>
      </Layout>
		</React.Fragment>
	);
}

export default WebFormViewDetail