import DefaultUserPicture from "../assets/images/defaultUser.png";
const commonUtils = {
  errorClass: "error-red",
};

export default commonUtils;

export const addDefaultSrc = (ev) => {
  ev.target.src = DefaultUserPicture;
};

export const DefaultUserPic = DefaultUserPicture;

/*******************
  @Purpose : Used to set new order values
  @Parameter : list, startIndex, endIndex
  @Author : INIC
  ******************/
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export function ckEditorRemoveTags(data) {
  return data
    .replace("<p>", "")
    .replace("</p>", "")
    .replace("<h2>", "")
    .replace("</h2>", "")
    .replace("<h3>", "")
    .replace("</h3>", "")
    .replace("<h4>", "")
    .replace("</h4>", "")
    .replace("<strong>", "")
    .replace("</strong>", "")
    .replace("<i>", "")
    .replace("</i>", "")
    .replace("<ul>", "<li>", "")
    .replace("</ul>", "</li>", "")
    .replace("<li>", "")
    .replace("</li>", "")
    .replace("<li>", "")
    .replace("</li>", "")
    .replace("<ol>", "")
    .replace("</ol>", "")
    .replace("<i>", "<p>", "<strong>", "")
    .replace("</i>", "</p>", "</strong>", "")
    .replace('<figure class="image">', ",")
    .replace('<img src="', "")
    .replace('"></figure>', "");
}
