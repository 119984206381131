import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Image,
  Badge,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import SimpleSelectComponent from "../../components/SimpleSelect/SimpleSelectComponent";
import DatePicker from "react-datepicker";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import InputMask from "react-input-mask";
// import bgImg from "../../assets/images/main-bg.png";
import UploadIcon from "../../assets/images/Folder.svg";
import UploadImageIcon from "../../assets/images/avatar-image.png";
import { useDispatch, useSelector } from "react-redux";
import {
  emailValidator,
  getItem,
  removeItem,
  showSuccessToast,
  textValidator,
} from "../../utils/Functions";
import MultiSelectComponent from "../../components/Multiselect/MultiSelectComponent";
import {
  addCandidateData,
  addCvDetails,
  candidateFileUpload,
  countryCodeListing,
  getFormSetting,
  getQualificationList,
  getSkillDetails,
  getWalkInDriveDetails,
  candidateFileDelete,
} from "../Career/action";
import swal from "sweetalert";
import Footer from "./../../components/Footer";
import docIcon from "../../assets/images/icons-doc.png";

const ConsultantForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const timeOut = useRef();
  const fileUpload = useSelector((state) => state.career.getCandidateCvDetails);
  const code = useSelector((state) => state.career.getCountryCode.data);
  const skillDetail = useSelector((state) => state.career.getSkillList.data);
  const addJob = useSelector((state) => state.career.addJobOpening);
  const qualificationDetail = useSelector(
    (state) => state.career.getQualificationDetails
  );
  const walkinDriveSourceDetail = useSelector(
    (state) => state.career.getWalkInDrivedetails.data
  );
  const walkinStatus = useSelector((state) => state.career.walkinDriveStatus);
  const [walkinDriveSourceOption, setWalkinDriveSourceOption] = useState([]);
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [phoneCodeOptionsData, setPhoneCodeOptionsData] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const [experienceStartMonth, setExperienceStartMont] = useState();
  const [preview, setPreview] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [walkinDrive, setWalkinDrive] = useState("");
  const [consultantData, setConsultantData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    source: "",
    sourceName: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    qualificationId: "",
    qualification: "",
    walkinDriveSourceId: "",
    walkinDriveSource: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    source: "",
    sourceName: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    qualificationId: "",
    qualification: "",
  });
  const [educationDetails, setEducationDetails] = useState([
    {
      institute: "",
      major: "",
      degree: "",
      from: "",
      to: "",
      pursuing: false,
    },
  ]);
  const [experienceDetails, setExperienceDetails] = useState([
    {
      occupation: "",
      company: "",
      summary: "",
      from: "",
      to: "",
      isWorking: false,
    },
  ]);

  useEffect(() => {
    document.title = "Consultant Form";
    const query = new URLSearchParams(location.search);
    const keyName = query.get("key");
    keyName && dispatch(getFormSetting(keyName));
  }, []);

  useEffect(() => {
    if (skillDetail && skillDetail.length > 0) {
      const list = [];
      skillDetail?.map((item) => {
        return list.push({
          label: item?.skill,
          value: item?._id,
        });
      });
      setSkillSetOption(list);
    } else {
      setSkillSetOption([]);
    }
  }, [skillDetail]);

  useEffect(() => {
    if (addJob && addJob.status === 1) {
      setIsLoading(false);
      let payload = new FormData();
      payload.append("userId", addJob?.data?._id);
      payload.append("type", "resume");
      payload.append("file", file);
      dispatch(candidateFileUpload(payload));
      setShow(true);
    } else if (addJob && addJob.status === 0) {
      swal({
        title:
          "Candidate has been already exists. do you want to add another candidate ?",
        icon: "warning",
        buttons: ["No", "Yes"],
        // dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          resetData();
        }
      });
    }
  }, [addJob]);

  useEffect(() => {
    if (fileUpload) {
      let list = [];
      let ids = [];
      fileUpload?.data?.candidateSkillSet.map((item) =>
        list.push({ value: item._id, label: item.skill })
      );

      setSelectedSkill(list);
      fileUpload?.data?.candidateSkillSet.map((item) => {
        if (item._id !== "") {
          ids.push(item._id);
        }
      });
      setConsultantData({
        ...consultantData,
        firstName: fileUpload?.data?.firstName
          ? fileUpload?.data?.firstName
          : "",
        lastName: fileUpload?.data?.lastName ? fileUpload?.data?.lastName : "",
        email: fileUpload?.data?.emailId ? fileUpload?.data?.emailId : "",
        mobile: fileUpload?.data?.phone ? fileUpload?.data?.phone : "",
        skillSet: ids.length > 0 ? ids : [],
      });
      setErrors({
        ...errors,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
      });
    }
  }, [fileUpload]);

  useEffect(() => {
    if (walkinDriveSourceDetail && walkinDriveSourceDetail.length > 0) {
      const list = [];
      walkinDriveSourceDetail?.map((item) => {
        return list.push({
          label: item?.walkInDrive,
          value: item?._id,
        });
      });
      setWalkinDriveSourceOption(list);
    } else {
      setWalkinDriveSourceOption([]);
    }
  }, [walkinDriveSourceDetail]);

  useEffect(() => {
    dispatch(countryCodeListing());
    setSkillSetOption([]);
    dispatch(getQualificationList());
    dispatch(getWalkInDriveDetails());
  }, []);

  useEffect(() => {
    if (code && code.length > 0) {
      const list = [];
      code?.map((item) => {
        list.push({
          label: `+${item?.phoneCode}`,
          value: `+${item?.phoneCode}`,
        });
        setPhoneCodeOptionsData(list);
      });
    }
  }, [code]);

  useEffect(() => {
    if (walkinStatus && walkinStatus.status === 1) {
      setWalkinDrive(walkinStatus?.data?.isWalkInDrive);
    }
  }, [walkinStatus]);

  const handleEducationDetails = (e, index, name) => {
    const eduValues = [...educationDetails];
    eduValues[index][name] = e;
    let today = new Date();
    if (name === "pursuing" && e === true) {
      eduValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setEducationDetails(eduValues);
  };

  const handleReupload = () => {
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      file: "",
    });

    setSelectedSkill([]);
    let deletePayload = {
      type: "resume",
      filePath: consultantData.resume,
    };
    dispatch(candidateFileDelete(deletePayload));
    setConsultantData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      resume: "",
      qualificationId: "",
      qualification: "",
    });
  };

  const handleExpDetail = (e, index, name) => {
    const expValues = [...experienceDetails];
    expValues[index][name] = e;
    let today = new Date();
    if (name === "isWorking" && e === true) {
      expValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setExperienceDetails(expValues);
  };

  const handleAddEducation = (e) => {
    e.preventDefault();
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
      ...educationDetails,
    ]);
  };

  const handleExeperienceAdd = (e) => {
    e.preventDefault();
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
      ...experienceDetails,
    ]);
  };

  const handleRemoveEducation = (index) => {
    const educationValues = [...educationDetails];
    let obj = educationDetails[index];
    delete obj["institute"];
    delete obj["degree"];
    delete obj["from"];
    delete obj["to"];
    delete obj["major"];
    delete obj["pursuing"];
    educationValues.splice(index, 1);
    setEducationDetails(educationValues);
  };

  const handleRemoveExperience = (index) => {
    const experienceValues = [...experienceDetails];
    let obj = experienceDetails[index];
    delete obj["company"];
    delete obj["from"];
    delete obj["isWorking"];
    delete obj["occupation"];
    delete obj["summary"];
    delete obj["to"];
    experienceValues.splice(index, 1);
    setExperienceDetails(experienceValues);
  };
  const userData = getItem("userInfo") ? JSON.parse(getItem("userInfo")) : {};
  const logoutHandler = () => {
    // localStorage.clear();
    removeItem("accessToken");
    removeItem("userInfo");
    history.push("/");
    showSuccessToast("Logout Successfully");
  };

  const addResume = async (resume) => {
    let type = resume.name.split(".")[1];
    setFileType(type);
    if (type === "pdf") {
      const objectUrl = URL.createObjectURL(resume);
      setPreview(objectUrl);
    }
    setFile(resume);
    let obj = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      skillSet: [],
      resume: "",
    };
    let formData = new FormData();
    formData.append("file", resume);
    await dispatch(addCvDetails(formData)).then((res) => {
      if (res) {
        let list = [];
        let ids = [];
        res?.data?.data?.candidateSkillSet.map((item) =>
          list.push({ value: item._id, label: item.skill })
        );

        setSelectedSkill(list);
        res?.data?.data?.candidateSkillSet.map((item) => {
          if (item._id !== "") {
            ids.push(item._id);
          }
        });

        obj.firstName = res?.data?.data?.firstName
          ? res?.data?.data?.firstName
          : "";
        obj.lastName = res?.data?.data?.lastName
          ? res?.data?.data?.lastName
          : "";
        obj.email = res?.data?.data?.emailId ? res?.data?.data?.emailId : "";
        obj.mobile = res?.data?.data?.phone ? res?.data?.data?.phone : "";
        obj.skillSet = ids.length > 0 ? ids : [];

        setErrors({
          ...errors,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
      }
    });
    let payload = new FormData();
    payload.append("file", resume);
    payload.append("type", "resume");
    await dispatch(candidateFileUpload(payload)).then((res) => {
      obj.resume = res?.data?.data?.response;
    });

    setConsultantData({
      ...consultantData,
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      mobile: obj.mobile,
      resume: obj.resume,
    });
  };

  const fileTypes = [
    "PDF",
    "DOC",
    "DOCX",
    "JPG",
    "JPEG",
    "PNG",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  var noticePeriod = [
    "Immediately",
    "15 Days",
    "30 Days",
    "45 Days",
    "60 Days",
  ].map(function (notperiod) {
    return { label: notperiod, value: notperiod };
  });

  // const getWalkInDriveList = (searchText) => {
  //   clearTimeout(timeOut.current);
  //   timeOut.current = setTimeout(async () => {
  //     if (searchText !== "") {
  //       await dispatch(getWalkInDriveDetails(searchText));
  //     } else {
  //       setWalkinDriveSourceOption(null);
  //     }
  //   }, 600);
  // };

  const getSkillSetList = (searchText) => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      if (searchText !== "") {
        await dispatch(getSkillDetails(searchText));
      } else {
        setSkillSetOption(null);
      }
    }, 600);
  };

  const handleChange = async (file) => {
    if (file) {
      setErrors({
        ...errors,
        succsessFile: "File added succsessfully",
        file: "",
      });
    }
    setFile(file);
    let formData = new FormData();
    formData.append("file", file);
    await dispatch(addCvDetails(formData));
  };

  const addProfile = async (profile) => {
    let payload = new FormData();
    payload.append("file", profile);
    payload.append("type", "photo");
    dispatch(candidateFileUpload(payload)).then((res) => {
      setConsultantData({
        ...consultantData,
        photo: res?.data?.data?.response,
      });
    });
  };

  const handleDataChange = (e, type) => {
    if (type === "num") {
      setConsultantData({ ...consultantData, [e.target.name]: e.target.value });
    } else if (type === "email") {
      setConsultantData({
        ...consultantData,
        [e.target.name]: emailValidator(e.target.value),
      });
    } else {
      setConsultantData({
        ...consultantData,
        [e.target.name]: textValidator(e.target.value),
      });
    }
  };

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onResumeChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      setErrors({
        ...errors,
        succsessFile: "Resume/CV added succsessfully",
        file: "",
      });

      // let type = newFileList[0].originFileObj.name.split(".")[1];
      // setFileType(type);
      // if (type === "pdf") {
      //   const objectUrl = URL.createObjectURL(newFileList[0].originFileObj);
      //   setPreview(objectUrl);
      // }
      //   setFile(newFileList[0].originFileObj);

      //   let formData = new FormData();
      //   formData.append("file", newFileList[0].originFileObj);
      //   await dispatch(addCvDetails(formData));
      //   let payload = new FormData();
      //   payload.append("file", newFileList[0].originFileObj);
      //   payload.append("type", "resume");
      //   // dispatch(candidateFileUpload(payload)).then((res) => {
      //   //   setConsultantData({
      //   //     ...consultantData,
      //   //     resume: res?.data?.data?.response,
      //   //   });
      //   // });
    }
  };

  const handleImageChange = async ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      setProfileList(newFileList);
      setErrors({
        ...errors,
        successImageFile: "Image added succsessfully",
        file: "",
      });
    }
    setImage(newFileList[0].originFileObj);

    // let payload = new FormData();
    // payload.append("file", newFileList[0].originFileObj);
    // payload.append("type", "photo");
    // dispatch(candidateFileUpload(payload)).then((res) => {
    //   setJobApplicationData({
    //     ...jobApplicationData,
    //     photo: res?.data?.data?.response,
    //   });
    // });
  };

  const handleImageReupload = () => {
    setImage();
    // setPreview("");
    setProfileList([]);
    // setFileType("");
    setErrors({
      ...errors,
      successImageFile: "",
      // file: "",
    });

    let payload = {
      type: "photo",
      filePath: consultantData.photo,
    };
    dispatch(candidateFileDelete(payload));
    setConsultantData({
      ...consultantData,
      photo: "",
    });
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let error = {
      firstName: "",
      phoneCode: "",
      lastName: "",
      email: "",
      mobile: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      city: "",
      qualification: "",
    };
    let mobileRegex = "^[0-9][0-9]{9,12}$";
    let emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (consultantData.firstName === "") {
      error.firstName = "First Name is required";
    } else error.firstName = "";

    if (consultantData.lastName === "") {
      error.lastName = "Last Name is required";
    } else error.lastName = "";

    if (consultantData.email === "") {
      error.email = "Email is required";
    } else if (!new RegExp(emailRegex).test(consultantData.email)) {
      error.email = "Please provide a valid email";
    } else error.email = "";

    if (consultantData.phoneCode === "") {
      error.phoneCode = "Phone Code is required";
    } else error.phoneCode = "";

    if (consultantData.mobile === "") {
      error.mobile = "Mobile is required";
    } else if (!new RegExp(mobileRegex).test(consultantData.mobile)) {
      error.mobile = "Mobile number in between 10-13 digits";
    } else error.mobile = "";

    if (consultantData.currentEmployer === "") {
      error.currentEmployer = "This field is required.";
    } else error.currentEmployer = "";

    if (consultantData.totalExperiance === "") {
      error.totalExperiance = "This field is required.";
    } else error.totalExperiance = "";

    if (consultantData.currentSalary === "") {
      error.currentSalary = "This field is required.";
    } else error.currentSalary = "";

    if (consultantData.city === "") {
      error.city = "This field is required.";
    } else error.city = "";

    if (consultantData.qualificationId === "") {
      error.qualification = "This field is required";
    } else error.qualification = "";

    if (file === undefined) {
      error.file = "Please upload resume";
    } else error.file = "";

    if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== "" ||
      error.phoneCode !== "" ||
      error.mobile !== "" ||
      error.currentEmployer !== "" ||
      error.totalExperiance !== "" ||
      error.currentSalary !== "" ||
      error.qualification !== "" ||
      error.city !== "" ||
      error.file !== ""
    ) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    setErrors(error);
    return isFormValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      let list = [];
      consultantData.skillSet.map((item) => list.push(item.value));

      let obj = {
        // postingTitle: [jobData._id],
        firstName: consultantData.firstName,
        lastName: consultantData.lastName,
        emailId: consultantData.email,
        phoneCode: consultantData.phoneCode.value,
        mobile: consultantData.mobile,
        noticePeriod: consultantData.noticePeriod.value,
        currentEmployer: consultantData.currentEmployer,
        totalExperiance: consultantData.totalExperiance,
        currentSalary: consultantData.currentSalary,
        skillSet: consultantData.skillSet,
        ...(educationDetails.length > 0 && {
          educations: educationDetails,
        }),
        ...(experienceDetails.length > 0 && {
          experiences: experienceDetails,
        }),
        street: consultantData.street,
        zipCode: consultantData.zipCode,
        city: consultantData.city,
        state: consultantData.state,
        country: consultantData.country,
        faceBook: consultantData.faceBook,
        linkdin: consultantData.linkdin,
        qualification: consultantData.qualificationId.value,
        ...(consultantData.walkinDriveSourceId !== "" && {
          walkinDriveSource: consultantData.walkinDriveSourceId.value,
        }),
        resume: "resume",
        consultant: JSON.parse(getItem("userInfo"))?.companyId?._id,
        source: JSON.parse(getItem("userInfo"))?._id,
        createdFrom: "consultant",
      };
      dispatch(addCandidateData(obj));
    }
  };

  const resetData = () => {
    setConsultantData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      referrerName: "",
      referrerEmail: "",
      relation: "",
      knownPeriod: "",
      qualificationId: "",
      qualification: "",
      walkinDriveSourceId: "",
      walkinDriveSource: "",
    });
    setSelectedSkill([]);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: "",
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      referrerName: "",
      referrerEmail: "",
      relation: "",
      knownPeriod: "",
      qualificationId: "",
      qualification: "",
    });
    setShow(false);
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
    ]);
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
    ]);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      {/* <Container>
        <Row className="justify-content-md-center">
          <Col xl="10">
            <div className="btn-logout-webform">
              <span className="font-weight-bold">
                Hii, {userData && userData.firstName + " " + userData.lastName}
              </span>
              <Button variant="danger" onClick={logoutHandler} className="ml-3">
                <span className="bx bx-log-out"></span> LOGOUT
              </Button>
            </div>
          </Col>
        </Row>
      </Container> */}

      {/* <div className="consultant-header-new">
        <Container>
          <Row className="justify-content-md-center">
            <Col xl="12">
            <div className="panel">
              <div className="panel-header">
                <h2 className="display-4 font-weight-semibold">
                  {userData && userData.companyId && userData.companyId.name}
                </h2>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <Container>
        {/* Start: Header */}
        <div
          className="consultant-header"
          // style={{ backgroundImage: `url(${bgImg})` }}
        >
          <div className="page-heading">
            <h4 className="text-primary mb-0">Consultant Name</h4>
            <h1 className="text-dark display-4 font-weight-semibold">
              {userData && userData.companyId && userData.companyId.name}
            </h1>
          </div>

          <div className="btn-logout-webform">
            <span className="font-weight-bold">
              Hii, {userData && userData.firstName + " " + userData.lastName}
            </span>
            <Button variant="danger" onClick={logoutHandler} className="ml-3">
              <span className="bx bx-log-out"></span> LOGOUT
            </Button>
          </div>
        </div>
        {/* Ends: Header */}

        {/* Start: Form */}
        <section className="consultant-add-form">
          <div className="consultant-add-form-body">
            <Row>
              <Col lg="3">
                <aside className="doc-upload-block">
                  <div className="mb-4">
                    <h5 className="font-weight-semibold">
                      Autofill Application
                    </h5>
                    <small className="text-gray">
                      Upload your Resume/CV in seconds with the below Drag &amp;
                      Drop option
                    </small>
                  </div>

                  <Row>
                    <Col lg={12} sm={6}>
                      {/* File Uploader */}
                      <div className="document-file-uploader mb-5">
                        <div className="section-title-heading d-block">
                          <h4 className="doc-name mb-3">
                            Upload Resume/CV
                            <span className="text-danger">&nbsp;*</span>
                          </h4>
                          {/* <span className="divider"></span> */}
                        </div>

                        <div className="document-upload">
                          <div className="document-uploader-block">
                            {file && (
                              <div className="preview-delete-btn">
                                {preview && fileType === "pdf" && (
                                  <Button
                                    variant="icon"
                                    onClick={() => setShowPreview(true)}
                                    title="Preview"
                                  >
                                    <span className="bx bx-show"></span>
                                  </Button>
                                )}
                                <Button
                                  variant="icon"
                                  onClick={handleReupload}
                                  title="Re-Upload"
                                >
                                  <span className="bx bx-upload"></span>
                                </Button>
                              </div>
                            )}
                            {!preview && (
                              <>
                                <div className="file-uploader-block">
                                  <div className="upload-icon-title">
                                    {/* <span className="bx bx-cloud-upload"></span> */}
                                    <Image src={UploadIcon} alt="Upload-icon" />
                                    <p className="upload-icon-title-text my-2">
                                      Upload or Drag your Resume/CV here
                                    </p>
                                    <p className="or-text position-relative">
                                      OR
                                    </p>
                                    <Button type="button" variant="light">
                                      Browse
                                    </Button>
                                  </div>
                                  {fileType === "" && (
                                    <div className="upload-file-extensions">
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        PDF
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        DOC
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        DOCX
                                      </Badge>
                                    </div>
                                  )}
                                </div>
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList}
                                  onChange={onResumeChange}
                                  customRequest={({
                                    file: resume,
                                    onSuccess,
                                    onError,
                                  }) => {
                                    Promise.resolve().then(() => {
                                      if (resume.name.split(".")[1] !== "pdf") {
                                        onSuccess();
                                        addResume(resume);
                                      } else {
                                        addResume(resume);
                                      }
                                    });
                                  }}
                                  // onPreview={onPreview}
                                  accept=".pdf,.doc,.docx"
                                  onRemove={() => {
                                    setFile();
                                    setPreview("");
                                    setFileList([]);
                                    setFileType("");
                                    setErrors({
                                      ...errors,
                                      succsessFile: "",
                                      file: "",
                                    });
                                  }}
                                >
                                  {fileList.length < 1}
                                </Upload>{" "}
                              </>
                            )}
                            <div className="preview-frame">
                              {preview && <iframe src={preview} />}
                            </div>
                          </div>
                          {(fileType === "docx" || fileType === "doc") && (
                            <img
                              src={docIcon}
                              alt="Word document icon"
                              className="preview-doc-icon"
                            />
                          )}
                        </div>
                        <small style={{ color: "red" }}>{errors.file}</small>
                        <small style={{ color: "green" }}>
                          {errors.succsessFile}
                        </small>
                      </div>
                      {/* File Uploader */}
                    </Col>
                    <Col lg={12} sm={6}>
                      {/* Begin: Attach Image */}
                      <div className="document-file-uploader upload-img">
                        <div className="document-upload">
                          {profileList && (
                            <>
                              <div className="section-title-heading d-block">
                                <h4 className="doc-name mb-3">
                                  Upload Profile Picture
                                </h4>
                                {/* <span className="divider"></span> */}
                              </div>
                              <div className="position-relative">
                                <div className="document-uploader-block">
                                  <div className="preview-delete-btn">
                                    {image && (
                                      <Button
                                        variant="icon"
                                        onClick={handleImageReupload}
                                        title="Re-Upload"
                                      >
                                        <span className="bx bx-upload"></span>
                                      </Button>
                                    )}
                                  </div>
                                  <div className="upload-icon-title">
                                    {/* <span className="bx bx-cloud-upload"></span> */}
                                    <Image
                                      src={UploadImageIcon}
                                      alt="Upload-image-icon"
                                    />
                                    {profileList.length === 0 && (
                                      <>
                                        {" "}
                                        <small className="upload-icon-title-text">
                                          Upload or Drag here
                                        </small>
                                      </>
                                    )}
                                  </div>
                                  <ImgCrop grid rotate>
                                    <Upload
                                      listType="picture-card"
                                      fileList={profileList}
                                      onChange={(e) => handleImageChange(e)}
                                      customRequest={({
                                        file,
                                        onSuccess,
                                        onError,
                                      }) => {
                                        Promise.resolve().then(() => {
                                          onSuccess();
                                          addProfile(file);
                                        });
                                      }}
                                      onPreview={onPreview}
                                      onRemove={() => {
                                        setProfileList([]);
                                        setImage();
                                        setErrors({
                                          ...errors,
                                          successImageFile: "",
                                        });
                                      }}
                                    >
                                      {profileList.length < 1}
                                    </Upload>
                                  </ImgCrop>
                                </div>
                                {!errors.successImageFile && (
                                  <div className="upload-file-extensions">
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      JPG
                                    </Badge>
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      PNG
                                    </Badge>
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      JPEG
                                    </Badge>
                                  </div>
                                )}
                                <div className="text-center">
                                  <small style={{ color: "green" }}>
                                    {errors.successImageFile}
                                  </small>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* Ends: Attach Image */}
                    </Col>
                  </Row>
                </aside>
              </Col>
              <Col lg="9">
                {/* Form Fields Begins */}
                <Form onSubmit={handleSubmit}>
                  <div className="panel">
                    {/* Begin: Basic Info */}
                    <div className="panel-header">
                      <h4 className="title">Basic Info</h4>
                    </div>

                    <div className="panel-content py-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formFirstName">
                            <Form.Label>
                              First Name
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              maxLength="20"
                              disabled={!file}
                              className={
                                errors.firstName !== ""
                                  ? "error-red"
                                  : !file
                                  ? "disabled-field"
                                  : ""
                              }
                              value={consultantData.firstName}
                              onChange={(e) => {
                                handleDataChange(e);
                                setErrors({
                                  ...errors,
                                  firstName: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.firstName}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formLastName">
                            <Form.Label>
                              Last Name
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              maxLength="20"
                              disabled={!file}
                              className={
                                errors.lastName !== ""
                                  ? "error-red"
                                  : !file
                                  ? "disabled-field"
                                  : ""
                              }
                              value={consultantData.lastName}
                              onChange={(e) => {
                                handleDataChange(e);
                                setErrors({
                                  ...errors,
                                  lastName: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.lastName}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>
                              Email
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              maxLength="45"
                              disabled={!file}
                              className={
                                errors.email !== ""
                                  ? "error-red"
                                  : !file
                                  ? "disabled-field"
                                  : ""
                              }
                              value={consultantData.email}
                              onChange={(e) => {
                                handleDataChange(e, "email");
                                setErrors({
                                  ...errors,
                                  email: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.email}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formMobile">
                            <Form.Label>
                              Mobile
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <div className="form-input">
                              <div>
                                <SimpleSelectComponent
                                  name="mobile"
                                  value={consultantData.phoneCode}
                                  disabled={!file}
                                  className={
                                    errors.phoneCode
                                      ? "phone-code error-red"
                                      : !file
                                      ? "disabled-field phone-code"
                                      : "phone-code"
                                  }
                                  options={phoneCodeOptionsData}
                                  onValueChange={(e) => {
                                    e &&
                                      setConsultantData({
                                        ...consultantData,
                                        phoneCode: e,
                                      });
                                    e &&
                                      setErrors({
                                        ...errors,
                                        phoneCode: "",
                                      });
                                  }}
                                />
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.phoneCode}
                                </span>
                              </div>

                              <div className="w-100">
                                <InputMask
                                  disabled={!file}
                                  className={
                                    errors.mobile !== ""
                                      ? "form-control error-red"
                                      : !file
                                      ? "disabled-field form-control"
                                      : "form-control"
                                  }
                                  mask={
                                    consultantData.mobile !== ""
                                      ? "9999999999"
                                      : ""
                                  }
                                  maskChar=""
                                  // placeholder="dd/mm/yyyy"
                                  value={consultantData.mobile}
                                  onChange={(e) => {
                                    setConsultantData({
                                      ...consultantData,
                                      mobile: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      mobile: "",
                                    });
                                  }}
                                />
                                {/* <Form.Control
                                  type="number"
                                  name="mobile"
                                  onWheel={(e) => e.target.blur()}
                                  className={
                                    errors.mobile !== ""
                                      ? "form-control error-red"
                                      : ""
                                  }
                                  value={consultantData.mobile}
                                  onChange={(e) => {
                                    setConsultantData({
                                      ...consultantData,
                                      mobile: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      mobile: "",
                                    });
                                  }}
                                /> */}
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errors.mobile}
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>Notice Period</Form.Label>
                            <SimpleSelectComponent
                              options={noticePeriod}
                              name="noticePeriod"
                              value={consultantData.noticePeriod}
                              disabled={!file}
                              className={!file ? "disabled-field" : ""}
                              onValueChange={(e) => {
                                e &&
                                  setConsultantData({
                                    ...consultantData,
                                    noticePeriod: e,
                                  });
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    {/* Ends: Basic Info */}

                    <div className="divider-x"></div>

                    {/* Begin: Professional Details */}
                    <div className="panel-header pt-0">
                      <h4 className="title">Professional Details</h4>
                    </div>

                    <div className="panel-content py-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formHighQualifHeld">
                            <Form.Label>
                              {" "}
                              Highest Qualification Held
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <SimpleSelectComponent
                              name="qualification"
                              value={consultantData.qualification}
                              disabled={!file}
                              className={
                                errors.qualification
                                  ? "error-red"
                                  : !file
                                  ? "disabled-field"
                                  : ""
                              }
                              onValueChange={(e) => {
                                e &&
                                  setConsultantData({
                                    ...consultantData,
                                    qualificationId: e,
                                    qualification: e,
                                  });
                                setErrors({
                                  ...errors,
                                  qualification: "",
                                });
                              }}
                            >
                              {qualificationDetail?.data?.map((data) => (
                                <option value={data._id}>
                                  {data.qualification}
                                </option>
                              ))}
                            </SimpleSelectComponent>
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.qualification}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCurrentEmployer">
                            <Form.Label>
                              Current Employer
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="currentEmployer"
                              maxLength="30"
                              disabled={!file}
                              className={
                                errors.currentEmployer !== ""
                                  ? "error-red"
                                  : !file
                                  ? "disabled-field"
                                  : ""
                              }
                              value={consultantData.currentEmployer}
                              onChange={(e) => {
                                handleDataChange(e);
                                setErrors({
                                  ...errors,
                                  currentEmployer: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.currentEmployer}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formtotalExperiance">
                            <Form.Label>
                              Total Experience(IN YEAR)
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              disabled={!file}
                              className={
                                errors.totalExperiance !== ""
                                  ? "error-red form-control"
                                  : !file
                                  ? "disabled-field form-control"
                                  : "form-control"
                              }
                              value={consultantData.totalExperiance}
                              onChange={(e) => {
                                setConsultantData({
                                  ...consultantData,
                                  totalExperiance: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  totalExperiance: "",
                                });
                              }}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                              }}
                              maxlength={5}
                            />
                            {/* <InputMask
                              className={
                                errors.totalExperiance !== ""
                                  ? "error-red form-control"
                                  : "form-control"
                              }
                              mask={
                                consultantData.totalExperiance !== ""
                                  ? "99.99"
                                  : ""
                              }
                              maskChar=""
                              value={consultantData.totalExperiance}
                              onChange={(e) => {
                                setConsultantData({
                                  ...consultantData,
                                  totalExperiance: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  totalExperiance: "",
                                });
                              }}
                            /> */}
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.totalExperiance}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCurrentSalary">
                            <Form.Label>
                              Current Salary(IN LPA)
                              <span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              disabled={!file}
                              className={
                                errors.currentSalary !== ""
                                  ? "error-red form-control"
                                  : !file
                                  ? "disabled-field form-control"
                                  : "form-control"
                              }
                              value={consultantData.currentSalary}
                              onChange={(e) => {
                                setConsultantData({
                                  ...consultantData,
                                  currentSalary: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  currentSalary: "",
                                });
                              }}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                              }}
                              maxlength={5}
                            />
                            {/* <InputMask
                              className={
                                errors.currentSalary !== ""
                                  ? "error-red form-control"
                                  : "form-control"
                              }
                              mask={
                                consultantData.currentSalary !== ""
                                  ? "99.99"
                                  : ""
                              }
                              maskChar=""
                              value={consultantData.currentSalary}
                              onChange={(e) => {
                                setConsultantData({
                                  ...consultantData,
                                  currentSalary: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  currentSalary: "",
                                });
                              }}
                            />
                            */}
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.currentSalary}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>Skill Set</Form.Label>
                            <MultiSelectComponent
                              name="skillSet"
                              options={skillSetOption}
                              values={selectedSkill}
                              disabled={!file}
                              className={!file ? "disabled-field" : ""}
                              onValuesChange={(e) => {
                                let list = [];
                                e.map((item) => list.push(item.value));
                                e &&
                                  setConsultantData({
                                    ...consultantData,
                                    skillSet: list,
                                  });
                                setSelectedSkill(e);
                              }}
                              onSearchChange={(e) => {
                                getSkillSetList(e);
                              }}
                              onRemoveItem={function (item) {
                                const skills = selectedSkill?.filter(function (
                                  d
                                ) {
                                  return item.value !== d?.value;
                                });
                                setSelectedSkill(skills);
                              }}
                            ></MultiSelectComponent>
                          </Form.Group>
                        </Col>
                        {walkinStatus?.data?.isWalkInDrive && (
                          <Col md="6">
                            <Form.Group controlId="formWalk-inDrive ">
                              <Form.Label>
                                Candidate walk-in drive source :
                              </Form.Label>
                              <SimpleSelectComponent
                                // options={walkinDriveSourceOption}
                                name="walkinDriveSource"
                                className={!file ? "disabled-field" : ""}
                                disabled={!file}
                                value={consultantData.walkinDriveSource}
                                // defaultValue={{
                                //   value: consultantData?.walkinDriveSourceId,
                                //   label: consultantData?.walkinDriveSource,
                                // }}
                                onValueChange={(e) => {
                                  e &&
                                    setConsultantData({
                                      ...consultantData,
                                      walkinDriveSourceId: e,
                                      walkinDriveSource: e,
                                    });
                                }}
                                // onSearchChange={(e) => {
                                //   getWalkInDriveList(e);
                                // }}
                              >
                                {walkinDriveSourceDetail?.map((data) => (
                                  <option value={data._id}>
                                    {data.walkInDrive}
                                  </option>
                                ))}
                              </SimpleSelectComponent>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </div>
                    {/* Ends: Professional Details */}

                    <div className="divider-x"></div>

                    {/* Begin: Educational Details */}
                    {/* <div className="form-fields educational-details">
                        <h2 className="title">Educational Details</h2>
                        <React.Fragment>
                          {educationDetails &&
                            educationDetails.map((i, index) => {
                              return (
                                Object.keys(i).includes("institute") && (
                                  <Form className="form repeater-default add-columns-btn">
                                    <div className="field-form" key={index}>
                                      <Row>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>
                                              Institute / School
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              autoComplete="off"
                                              name="institute"
                                              value={i.institute}
                                              onChange={(e) =>
                                                handleEducationDetails(
                                                  e.target.value,
                                                  index,
                                                  "institute"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>
                                              Major / Department
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              autoComplete="off"
                                              name="major"
                                              value={i.major}
                                              onChange={(e) =>
                                                handleEducationDetails(
                                                  e.target.value,
                                                  index,
                                                  "major"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>Degree</Form.Label>
                                            <Form.Control
                                              type="text"
                                              autoComplete="off"
                                              name="degree"
                                              value={i.degree}
                                              onChange={(e) =>
                                                handleEducationDetails(
                                                  e.target.value,
                                                  index,
                                                  "degree"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>Duration</Form.Label>
                                            <Row>
                                              <Col sm={6}>
                                                <DatePicker
                                                  placeholderText="From"
                                                  className="form-control"
                                                  value={i.from}
                                                  onChange={(date) => {
                                                    handleEducationDetails(
                                                      moment(date).format(
                                                        "MM/yyyy"
                                                      ),
                                                      index,
                                                      "from"
                                                    );
                                                    setStartMonth(
                                                      new Date(date)
                                                    );
                                                    i.to = "";
                                                  }}
                                                  selectsStart
                                                  dateFormat="MM/yyyy"
                                                  showMonthYearPicker
                                                />
                                              </Col>

                                              <Col sm={6}>
                                                <DatePicker
                                                  placeholderText="To"
                                                  className="form-control"
                                                  value={
                                                    i.pursuing !== true
                                                      ? i.to
                                                      : "Present"
                                                  }
                                                  minDate={startMonth}
                                                  disabled={i.pursuing === true}
                                                  onChange={(date) => {
                                                    handleEducationDetails(
                                                      moment(date).format(
                                                        "MM/YYYY"
                                                      ),
                                                      index,
                                                      "to"
                                                    );
                                                  }}
                                                  selectsStart
                                                  dateFormat="MM/yyyy"
                                                  showMonthYearPicker
                                                />
                                              </Col>
                                            </Row>
                                            <div className="custom-checkbox text-right mt-2">
                                              <label
                                                className="form-label"
                                                htmlFor={`pursuing${index}`}
                                              >
                                                Currently Pursuing
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  name="pursuing"
                                                  id={`pursuing${index}`}
                                                  checked={i.pursuing}
                                                  onChange={(e) => {
                                                    handleEducationDetails(
                                                      e.target.checked,
                                                      index,
                                                      "pursuing"
                                                    );
                                                  }}
                                                />
                                                <span></span>
                                              </label>
                                            </div>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                    {btnForm.educationDetails && (
                                      <div className="add-row text-right">
                                        {educationDetails.length - 1 ===
                                          index && (
                                          <span
                                            className="bx bxs-plus-circle text-success"
                                            title="Add"
                                            onClick={(e) => {
                                              handleAddEducation(e);
                                            }}
                                          >
                                            <span className="btn-text">
                                              Add
                                            </span>
                                          </span>
                                        )}
                                        {educationDetails.length !== 1 && (
                                          <span
                                            className="bx bxs-minus-circle text-danger"
                                            title="Delete"
                                            onClick={() =>
                                              handleRemoveEducation(index)
                                            }
                                          >
                                            <span className="btn-text">
                                              Remove
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Form>
                                )
                              );
                            })}
                        </React.Fragment>
                      </div> */}
                    {/* Ends: Educational Details */}

                    {/* <div className="divider-x"></div> */}

                    {/* Begin: Experience Details */}
                    {/* <div className="form-fields experience-details">
                        <h2 className="title">Experience Details</h2>
                        <React.Fragment>
                          {experienceDetails &&
                            experienceDetails.map((i, index) => {
                              return (
                                Object.keys(i).includes("company") && (
                                  <Form className="form repeater-default add-columns-btn">
                                    <div className="field-form" key={index}>
                                      <Row>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>
                                              Occupation / Title
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              autoComplete="off"
                                              name="occupation"
                                              value={i.occupation}
                                              onChange={(e) =>
                                                handleExpDetail(
                                                  e.target.value,
                                                  index,
                                                  "occupation"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control
                                              type="text"
                                              autoComplete="off"
                                              name="company"
                                              value={i.company}
                                              onChange={(e) =>
                                                handleExpDetail(
                                                  e.target.value,
                                                  index,
                                                  "company"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>Summary</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows="2"
                                              name="summary"
                                              value={i.summary}
                                              onChange={(e) =>
                                                handleExpDetail(
                                                  e.target.value,
                                                  index,
                                                  "summary"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                          <Form.Group>
                                            <Form.Label>
                                              Work Duration
                                            </Form.Label>
                                            <Row>
                                              <Col sm={6}>
                                                <DatePicker
                                                  placeholderText="From"
                                                  className="form-control"
                                                  value={i.from}
                                                  onChange={(date) => {
                                                    handleExpDetail(
                                                      moment(date).format(
                                                        "MM/yyyy"
                                                      ),
                                                      index,
                                                      "from"
                                                    );
                                                    setExperienceStartMont(
                                                      new Date(date)
                                                    );
                                                    i.to = "";
                                                  }}
                                                  selectsStart
                                                  dateFormat="MM/yyyy"
                                                  showMonthYearPicker
                                                />
                                              </Col>
                                              <Col sm={6}>
                                                <DatePicker
                                                  placeholderText="To"
                                                  className="form-control"
                                                  value={
                                                    i.isWorking !== true
                                                      ? i.to
                                                      : "Present"
                                                  }
                                                  minDate={experienceStartMonth}
                                                  disabled={
                                                    i.isWorking === true
                                                  }
                                                  onChange={(date) => {
                                                    handleExpDetail(
                                                      moment(date).format(
                                                        "MM/yyyy"
                                                      ),
                                                      index,
                                                      "to"
                                                    );
                                                  }}
                                                  selectsStart
                                                  dateFormat="MM/yyyy"
                                                  showMonthYearPicker
                                                />
                                              </Col>
                                            </Row>
                                            <div className="custom-checkbox text-right mt-2">
                                              <label
                                                className="form-label"
                                                htmlFor={`isWorking${index}`}
                                              >
                                                I currently work here
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  name="isWorking"
                                                  id={`isWorking${index}`}
                                                  checked={i.isWorking}
                                                  onChange={(e) => {
                                                    handleExpDetail(
                                                      e.target.checked,
                                                      index,
                                                      "isWorking"
                                                    );
                                                  }}
                                                />
                                                <span></span>
                                              </label>
                                            </div>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="add-row text-right">
                                      {experienceDetails.length - 1 ===
                                        index && (
                                        <span
                                          className="bx bxs-plus-circle text-success"
                                          title="Add"
                                          onClick={(e) => {
                                            handleExeperienceAdd(e);
                                          }}
                                        >
                                          <span className="btn-text">Add</span>
                                        </span>
                                      )}
                                      {experienceDetails.length !== 1 && (
                                        <span
                                          className="bx bxs-minus-circle text-danger"
                                          title="Delete"
                                          onClick={() =>
                                            handleRemoveExperience(index)
                                          }
                                        >
                                          <span className="btn-text">
                                            Remove
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </Form>
                                )
                              );
                            })}
                        </React.Fragment>
                      </div> */}
                    {/* Ends: Experience Details */}

                    {/* <div className="divider-x"></div> */}

                    {/* Begin: Address Details */}
                    <div className="panel-header pt-0">
                      <h4 className="title">Address Details</h4>
                    </div>

                    <div className="panel-content py-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formStreet">
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                              type="text"
                              name="street"
                              maxLength="45 "
                              value={consultantData.street}
                              onChange={(e) => {
                                handleDataChange(e);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formZip">
                            <Form.Label>Zip / Postal Code</Form.Label>
                            <InputMask
                              className="form-control"
                              mask={
                                consultantData.zipCode !== "" ? "999999" : ""
                              }
                              maskChar=""
                              value={consultantData.zipCode}
                              onChange={(e) =>
                                setConsultantData({
                                  ...consultantData,
                                  zipCode: e.target.value,
                                })
                              }
                            />
                            {/* <Form.Control
                                type="number"
                                name="zipCode"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  setConsultantData({
                                    ...consultantData,
                                    zipCode: e.target.value,
                                  });
                                }}
                              /> */}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCity">
                            <Form.Label>
                              City<span className="text-danger">&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="city"
                              maxLength="30"
                              className={errors.city !== "" ? "error-red" : ""}
                              value={consultantData.city}
                              onChange={(e) => {
                                handleDataChange(e);
                                setErrors({
                                  ...errors,
                                  city: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.city}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formProvince">
                            <Form.Label>State / Province</Form.Label>
                            <Form.Control
                              type="text"
                              name="state"
                              maxLength="30"
                              value={consultantData.state}
                              onChange={(e) => {
                                handleDataChange(e);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              type="text"
                              name="country"
                              maxLength="30"
                              value={consultantData.country}
                              onChange={(e) => {
                                handleDataChange(e);
                              }}
                            />{" "}
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    {/* Ends: Address Details */}

                    {/* <div className="divider-x"></div> */}

                    {/* Begin: Social Network Details */}
                    {/* <div className="form-fields social-network-details">
                        <Row>
                          <Col md="12">
                            <h4 className="title">Social Network</h4>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formLinkedIn">
                              <Form.Label>LinkedIn</Form.Label>
                              <Form.Control
                                type="text"
                                name="linkdin"
                                maxLength="30"
                                value={consultantData.linkdin}
                                onChange={(e) => {
                                  handleDataChange(e, "num");
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formFacebook">
                              <Form.Label>Facebook</Form.Label>
                              <Form.Control
                                type="text"
                                name="faceBook"
                                maxLength="30"
                                value={consultantData.faceBook}
                                onChange={(e) => {
                                  handleDataChange(e, "num");
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div> */}
                    {/* Ends: Social Network Details */}
                  </div>

                  {/* Begin: Submit Button */}
                  <div className="button-group d-flex justify-content-end align-items-center my-4">
                    <Button
                      variant="link"
                      type="button"
                      className="mr-2"
                      onClick={resetData}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                    {isLoading && (
                      <Spinner
                        animation="border"
                        variant="primary"
                        className="spinner-loader"
                      />
                    )}
                  </div>
                  {/* Ends: Submit Button */}
                </Form>
                {/* Form Fields Ends */}
              </Col>
            </Row>
          </div>
        </section>
        {/* Ends: Form */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="text-center mb-4">
              <span className="bx bx-check-circle"></span>
              <h5 className="text-secondary">Success!</h5>
            </div>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <p>
              {addJob &&
                addJob.data &&
                addJob.data.firstName + " " + addJob.data.lastName}
              ,
            </p>
            <p className="">
              Thank you for applying and sharing the information.
            </p>
            <p>
              Our team will get in touch with you soon. If you have submitted
              details with no current openings with us at the moment, please
              look out for any emails from IndiaNIC. We will be in touch with
              you when appropriate vacancies open up.
            </p>
            <p className="mb-2">
              To know more about IndiaNIC - visit{" "}
              <a href="candidate-portal-indianic.com" target="_blank">
                https://www.indianic.com
              </a>
            </p>
            <p>
              Your further updates will be send to your{" "}
              <a
                href="http://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {addJob && addJob.data && addJob.data.emailId}
              </a>{" "}
            </p>
            <h5 className="font-weight-semibold">HR Team</h5>
            <p className="font-weight-medium">
              Have a Good Day and Best wishes for your further process.
            </p>
            {/* <img src={bgImg} alt="Career Page Header" /> */}
            <p>+91 (79) 6191 6041</p>
            <p>+1 (408) 922-9083</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={resetData}>Ok</Button>
            {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default ConsultantForm;
