import { Route, Redirect } from "react-router-dom";
import { Authorization } from "../utils/Functions";
import { paths } from "./Constant";

/*********
@Purpose : Used to declare private route
@Parameter : {}
@Author : INIC
***********/
export const PrivateRoute = ({
  component: Component,
  privateRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return privateRoute ? (
        Authorization() ? (
          <Component {...props} />
        ) : (
          <Redirect to={paths.login} />
        )
      ) : props.location.pathname === paths.login ||
        props.location.pathname === paths.consultantForm ? (

        !Authorization() ? (
          <Component {...props} />
        ) : (
          <Redirect to={paths.home} />
        )
      ) : (
        <Component {...props} />
      );
    }}
  />
);
