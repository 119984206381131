import { Dropdown, Menu } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
// import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap" ;
import logo from "../../../src/assets/images/logo.webp";
import { addDefaultSrc, DefaultUserPic } from "../../utils/commonUtils";
import { getEncryptedItem, removeItem, showSuccessToast } from "../../utils/Functions";

/************************************************ 
@Purpose : Used for Header
@Parameter : {}
@Author : INIC
**************************************************/
function Header() {
  const SUPERADMIN_KEY = "SUPER_ADMIN";
  const dispatch = useDispatch();
  let history = useHistory();
  const [width, setWidth] = useState(0);
  const [show, setShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  // const [NavMenu] = useState([
  //   {
  //     title: "Home",
  //     link: "/home",
  //     isShow: false,
  //     currentWidth: 400,
  //     icon: "bx bx-home",
  //   },
  //   {
  //     ...((getEncryptedItem("CANDIDATE", "candidateInfo_tab_visibility") ===
  //       true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Candidate",
  //       link: "/candidate",
  //       isShow: false,
  //       currentWidth: 500,
  //       icon: "bx bx-user",
  //     }),
  //   },
  //   {
  //     ...((getEncryptedItem("JOB_OPENING", "jobOpening_tab_visibility") ===
  //       true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Job Opening",
  //       link: "/job-opening",
  //       isShow: false,
  //       currentWidth: 600,
  //       icon: "bx bx-file-find",
  //     }),
  //   },
  //   {
  //     ...((getEncryptedItem("INTERVIEW", "interview_tab_visibility") === true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Interview",
  //       link: "/interviews",
  //       isShow: false,
  //       currentWidth: 700,
  //       icon: "bx bx-message-dots",
  //     }),
  //   },
  //   {
  //     ...((getEncryptedItem("DEPARTMENT", "department_tab_visibility") ===
  //       true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Department",
  //       link: "/department",
  //       isShow: false,
  //       currentWidth: 800,
  //       icon: "bx bx-sitemap",
  //     }),
  //   },
  //   {
  //     title: "Offer",
  //     link: "/offer",
  //     isShow: false,
  //     currentWidth: 900,
  //     icon: "bx bx-award",
  //   },
  //   {
  //     ...((getEncryptedItem("USERS", "users_tab_visibility") === true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Users",
  //       link: "/users",
  //       isShow: false,
  //       currentWidth: 1000,
  //       icon: "bx bx-group",
  //     }),
  //   },
  //   {
  //     ...((getEncryptedItem("ASSESSMENT", "assessment_tab_visibility") ===
  //       true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Assessment",
  //       link: "/assessment",
  //       isShow: false,
  //       currentWidth: 1100,
  //       icon: "bx bx-detail",
  //     }),
  //   },
  //   {
  //     ...((getEncryptedItem(
  //       "EMAIL_SMS_TEMPLATE",
  //       "email_sms_template_tab_visibility"
  //     ) === true ||
  //       getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
  //       title: "Email",
  //       link: "/email-template",
  //       isShow: false,
  //       currentWidth: 1200,
  //       icon: "bx bx-envelope",
  //     }),
  //   },
  //   {
  //     ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
  //       title: "Web Form",
  //       link: "/web-form",
  //       isShow: false,
  //       currentWidth: 1300,
  //       icon: "bx bx-file",
  //     }),
  //   },
  //   {
  //     title: "Consultant",
  //     link: "/consultant",
  //     isShow: false,
  //     currentWidth: 1400,
  //     icon: "bx bx-user-voice",
  //   },
  //   {
  //     ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
  //       title: "Role",
  //       link: "/roles",
  //       isShow: false,
  //       currentWidth: 1500,
  //       icon: "bx bx-cog",
  //     }),
  //   },
  //   {
  //     ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
  //       title: "Permission",
  //       link: "/permissions",
  //       isShow: false,
  //       currentWidth: 1600,
  //       icon: "bx bx-shield-quarter",
  //     }),
  //   },
  //   {
  //     ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
  //       title: "Master",
  //       link: "/master",
  //       isShow: false,
  //       currentWidth: 1700,
  //       icon: "bx bx-check-shield",
  //     }),
  //   },
  // ]);

  const [NavMenu] = useState([
    { title: "Home", link: "/home", isShow: false, currentWidth: 1000, icon: "bx bx-home", },
    {
      ...((getEncryptedItem("CANDIDATE", "candidateInfo_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Candidate",
        link: "/candidate",
        isShow: false,
        currentWidth: 1100,
        icon: "bx bx-user",
      }),
    },
    {
      ...((getEncryptedItem("JOB_OPENING", "jobOpening_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Job Opening",
        link: "/job-opening",
        isShow: false,
        currentWidth: 1200,
        icon: "bx bx-file-find",
      }),
    },
    {
      ...((getEncryptedItem("INTERVIEW", "interview_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Interview",
        link: "/interviews",
        isShow: false,
        currentWidth: 1300,
        icon: "bx bx-message-dots",
      }),
    },
    {
      ...((getEncryptedItem("DEPARTMENT", "department_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Department",
        link: "/department",
        isShow: false,
        currentWidth: 1500,
        icon: "bx bx-sitemap",
      }),
    },
        {
      title: "Offer",
      link: "/offer",
      isShow: false,
      currentWidth: 1600,
      icon: "bx bx-award",
    },
    {
      ...((getEncryptedItem("USERS", "users_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Users",
        link: "/users",
        isShow: false,
        currentWidth: 1700,
        icon: "bx bx-group",
      }),
    },
    {
      ...((getEncryptedItem("ASSESSMENT", "assessment_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Assessment",
        link: "/assessment",
        isShow: false,
        currentWidth: 1800,
        icon: "bx bx-detail",
      }),
    },
    {
      ...((getEncryptedItem("EMAIL_SMS_TEMPLATE","email_sms_template_tab_visibility") === true ||
        getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY) && {
        title: "Email",
        link: "/email-template",
        isShow: false,
        currentWidth: 1900,
        icon: "bx bx-envelope",
      }),
    },
    {
      ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
        title: "Web Form",
        link: "/web-form",
        isShow: false,
        currentWidth: 2000,
        icon: "bx bx-file",
      }),
    },
        {
      title: "Consultant",
      link: "/consultant",
      isShow: false,
      currentWidth: 2100,
      icon: "bx bx-user-voice",
    },
    {
      ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
        title: "Role",
        link: "/roles",
        isShow: false,
        currentWidth: 2200,
        icon: "bx bx-cog",
      }),
    },
    {
      ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
        title: "Permission",
        link: "/permissions",
        isShow: false,
        currentWidth: 2300,
        icon: "bx bx-shield-quarter",
      }),
    },
    {
      ...(getEncryptedItem("SUPER_ADMIN") === SUPERADMIN_KEY && {
        title: "Master",
        link: "/master",
        isShow: false,
        currentWidth: 2400,
        icon: "bx bx-check-shield",
      }),
    },
  ]);
  
  /******************************************* 
  @Purpose : Used for change menu of screen resize
  @Parameter : {}
  @Author : INIC
  ********************************************/
  const menu = (
    <Menu>
      {NavMenu.map((item, index) => (
        <React.Fragment key={index}>
          {item.isShow && (
            <Menu.Item key={index}>
              <Link to={item.link}>{item.title}</Link>
            </Menu.Item>
          )}
          {!item.isShow && (
            <React.Fragment>
              {item.currentWidth >= width && (
                <Menu.Item key={index}>
                  <Link to={item.link}>{item.title}</Link>
                </Menu.Item>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </Menu>
  );

  /**************************************************** 
   @Purpose : Used to get current width of window size
   @Parameter : {}
   @Author : INIC
   *****************************************************/
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  /****************************************** 
   @Purpose : Used for Logout
   @Parameter : 
   @Author : INIC
   ******************************************/

  const logoutHandler = () => {
    // localStorage.clear();
    removeItem("accessToken")
    removeItem("userInfo")
    history.push("/");
    showSuccessToast("Logout Successfully");
  };

  const menuItems = (
    <Menu>
      <Menu.Item key="0">
        <Link className="dropdown-item" to="/edit-profile">
          <span className="bx bx-edit"></span> Edit Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <a className="dropdown-item" onClick={logoutHandler}>
          <span className="bx bx-exit"></span> Log Out
        </a>
      </Menu.Item>
    </Menu>
  );

  const checkForActive = (item) => {
    let url = window.location.pathname;
    if (url.search(item.link) === 0) {
      return "active";
    }
  };

  return (
    <React.Fragment>
      {/* Begin: Common Header */}
      <header className="header-wrapp">
        <nav className="navbar navbar-expand-md bg-faded">
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              alt="brand-logo"
              className="brand-logo"
              width="40"
              height="40"
            />
          </Link>
          {/* <div className="navbar-collapse collapse"></div> */}
          <ul className="navbar-nav nav main-menu">
            {NavMenu.map((item, index) => (
              <React.Fragment key={index}>
                {item.currentWidth <= width && (
                  <li className="nav-item" key={index}>
                    <NavLink
                      exact
                      activeClassName="active"
                      to={item.link}
                      className={"nav-link " + checkForActive(item)}
                      title={item.title}
                    >
                      <span className={item.icon}></span>
                      {item.title}
                    </NavLink>
                  </li>
                )}
              </React.Fragment>
            ))}
            <li className="nav-item menu-select-dropdown">
              <Dropdown overlay={menu} trigger={["click"]} className="nav-link">
                <Link
                  to="#"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="bx bx-dots-horizontal-rounded"></span>
                </Link>
              </Dropdown>
            </li>
          </ul>
          {/* Search Menu */}
          <ul className="nav navbar-nav ml-auto align-items-center justify-content-end social-icon-menu">
            <li className="nav-item ">
              <div className="nav-link box-icon" onClick={() => setShow(true)}>
                <span className="bx bx-search"></span>
              </div>
              {show && (
                <div className="universal-search-box">
                  <form className="global-search-form">
                    <span className="bx bx-search"></span>
                    <input
                      type="text"
                      name="q"
                      placeholder="Type search term & press return"
                    />
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShow(false)}
                    >
                      <span className="bx bx-x"></span>
                    </button>
                  </form>
                </div>
              )}
            </li>

            {/* Notification Menu */}
            <li className="nav-item dropdown dropdown-notification">
              <div
                className="nav-link nav-link-label box-icon"
                onClick={() => setNotificationShow(true)}
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="bx bx-bell"></span>
                <span className="count">5</span>
              </div>
              <div className="dropdown-menu dropdown-menu-right dropdown-notification-body">
                {notificationShow && (
                  <React.Fragment>
                    <div className="dropdown-header">
                      <h6 className="notification-title">Notifications</h6>
                      <Link to="/notifications">View All</Link>
                    </div>
                    <ul className="dropdown-menu-media">
                      <li className="scrollable-container media-list">
                        <Link to="/notifications">
                          <div className="media">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img
                                  className="rounded-circle"
                                  src={DefaultUserPic}
                                  onError={addDefaultSrc}
                                  alt="avatar"
                                  height="32"
                                  width="32"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading">
                                Interview Scheduled for Node
                              </h6>
                              <small className="notification-text">
                                You have 18 unread messages
                              </small>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="scrollable-container media-list">
                        <Link to="/notifications">
                          <div className="media">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img
                                  className="rounded-circle"
                                  src={DefaultUserPic}
                                  onError={addDefaultSrc}
                                  alt="avatar"
                                  height="32"
                                  width="32"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading">
                                New Message received
                              </h6>
                              <small className="notification-text">
                                You have 18 unread messages
                              </small>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="scrollable-container media-list">
                        <Link to="/notifications">
                          <div className="media">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img
                                  className="rounded-circle"
                                  src={DefaultUserPic}
                                  onError={addDefaultSrc}
                                  alt="avatar"
                                  height="32"
                                  width="32"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading">
                                New Message received
                              </h6>
                              <small className="notification-text">
                                You have 18 unread messages
                              </small>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="scrollable-container media-list">
                        <Link to="/notifications">
                          <div className="media">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img
                                  className="rounded-circle"
                                  src={DefaultUserPic}
                                  onError={addDefaultSrc}
                                  alt="avatar"
                                  height="32"
                                  width="32"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading">
                                New Message received
                              </h6>
                              <small className="notification-text">
                                You have 18 unread messages
                              </small>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="scrollable-container media-list">
                        <Link to="/notifications">
                          <div className="media">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img
                                  className="rounded-circle"
                                  src={DefaultUserPic}
                                  onError={addDefaultSrc}
                                  alt="avatar"
                                  height="32"
                                  width="32"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading">
                                New Message received
                              </h6>
                              <small className="notification-text">
                                You have 18 unread messages
                              </small>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </React.Fragment>
                )}
              </div>
            </li>

            {/* Setting Menu */}
            <li className="nav-item">
              <Link className="nav-link box-icon" to="/settings">
                <span className="bx bx-cog"></span>
              </Link>
            </li>

            {/* User Profile Menu */}
            <li className="profile-dropdown">
              <div className="nav-link">
                <Dropdown overlay={menuItems} trigger={["click"]}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {getEncryptedItem("Profile") ? (
                      <img
                        src={getEncryptedItem("Profile")}
                        onError={addDefaultSrc}
                        alt="user-profile"
                        width="32"
                        height="32"
                        className="rounded-circle ml-2"
                      />
                    ) : (
                      <img
                        src={DefaultUserPic}
                        width="32"
                        height="32"
                        className="rounded-circle ml-2"
                        alt="Default Image"
                      />
                    )}
                  </a>
                </Dropdown>
              </div>
            </li>
          </ul>
        </nav>

        {/* New Navbar */}
        {/* <Container fluid>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
                <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                  Dank memes
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container> */}
        {/* New Navbar */}
      </header>
      {/* Ends: Common Header */}
    </React.Fragment>
  );
}
export default Header;
