import React from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  return (
		<div className="breadcrumb-wrapper">
			<Breadcrumb>
				{/* <Breadcrumb.Item href="/">
					<Link to="/">Home</Link>
				</Breadcrumb.Item> */}
				{props.routes.map((route, index) => {
					// let heading = route.name;
					return (
						<React.Fragment key={index}>
							<Breadcrumb.Item active href={route.path}>
								<Link to={route.path}>{route.name}</Link>
							</Breadcrumb.Item>
						</React.Fragment>
					);
				})}
			</Breadcrumb>
			{props.routes.slice(-1).map((route) => {
				// return <h2 className="page-heading">{route?.name}</h2>;
			})}
		</div>
	);
};
export default BreadCrumb;
