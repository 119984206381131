import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import SimpleSelectComponent from "../../components/SimpleSelect/SimpleSelectComponent";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import bgImg from "../../assets/images/careers-bg.jpeg";

const JobApplicationForm = () => {
  const [jobApplicationData, setJobApplicationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperience: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    source: "",
    sourceName: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperience: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    source: "",
    sourceName: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  });

  useEffect(() => {
    document.title = "Job Application Form ";
  }, []);

  /******************* 
  @Purpose : function for upload Files  
  @Parameter : {}
  @Author : INIC
  ******************/

  const fileTypes = [
    "PDF",
    "DOC",
    "DOCX",
    "JPG",
    "JPEG",
    "PNG",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const validateForm = () => {
    let isFormValid = true;
    let error = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      currentEmployer: "",
      totalExperience: "",
      currentSalary: "",
      city: "",
    };
    if (jobApplicationData.firstName === "") {
      error.firstName = "First Name is required";
    } else error.firstName = "";

    if (jobApplicationData.lastName === "") {
      error.lastName = "Last Name is required";
    } else error.lastName = "";

    if (jobApplicationData.email === "") {
      error.email = "Email is required";
    } else error.email = "";

    if (jobApplicationData.mobile === "") {
      error.mobile = "Mobile is required";
    } else error.mobile = "";

    if (jobApplicationData.currentEmployer === "") {
      error.currentEmployer = "This field is required.";
    } else error.currentEmployer = "";

    if (jobApplicationData.totalExperience === "") {
      error.totalExperience = "This field is required.";
    } else error.totalExperience = "";

    if (jobApplicationData.currentSalary === "") {
      error.currentSalary = "This field is required.";
    } else error.currentSalary = "";

    if (jobApplicationData.city === "") {
      error.city = "This field is required.";
    } else error.city = "";

    if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== "" ||
      error.mobile !== "" ||
      error.currentEmployer !== "" ||
      error.totalExperience !== "" ||
      error.currentSalary !== "" ||
      error.city !== ""
    ) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    setErrors(error);
    return isFormValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
    }
  };

  return (
    <React.Fragment>
      {/* <section className="webform-header">
				<Container fluid>
					<div className="section-heading mb-0">
						<h5 className="title mb-0">
							<Link to="/web-form" className="d-inline-flex align-items-center">
								<span className="bx bx-left-arrow-alt"></span> Back
							</Link>
						</h5>
						<h5 className="title mb-0">
							<Link to="/" className="d-inline-flex align-items-center">
								Share <span className="bx bx-link-external"></span>
							</Link>
						</h5>
					</div>
				</Container>
			</section> */}
      <div
        className="consultant-header text-center mt-4"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="position-relative">
          {/* <h4 className="text-white">Hindustan Computer Limited | Full Time</h4> */}
          <h1 className="text-white display-4 font-weight-semibold">Job Application Form</h1>
          {/* <h4 className="text-white">Remote Job | Posted on 04 May 2022</h4> */}
        </div>
      </div>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg="6" md="11">
            <section className="consultant-add-form">
              <div className="consultant-add-form-body">
                <div className="text-center">
                  <h4>Autofill Application</h4>
                  <p className="text-gray">
                    Upload your Resume/CV in seconds with the below Drag &amp;
                    Drop option
                  </p>
                </div>

                <div className="file-uploader text-center">
                  <FileUploader
                    // disabled={!datas.type ? true : false}
                    // handleChange={(e) =>
                    //   handleChange(e, idx)
                    // }
                    name="file"
                    types={fileTypes}
                    // style={wrapper}
                    // maxSize={5}
                    // onSizeError={fileSizeValidation}
                    // onTypeError={fileTypeValidation}
                    className="mx-auto"
                  />
                  <span className="error-msg text-danger">
                    {/* {datas.FileNameError} */} Please Upload correct file
                  </span>
                </div>

                {/* Form Fields Begins */}
                <Form onSubmit={handleSubmit}>
                  {/* Begin: Basic Info */}
                  <div className="panel">
                    <div className="panel-header">
                      <h4 className="title">Basic Info</h4>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formFirstName">
                            <Form.Label>
                              First Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                errors.firstName !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  firstName: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  firstName: "",
                                });
                              }}
                            />

                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.firstName}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formLastName">
                            <Form.Label>
                              Last Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                errors.lastName !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  lastName: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  lastName: "",
                                });
                              }}
                            />

                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.lastName}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>
                              Email<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className={errors.email !== "" ? "error-red" : ""}
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  email: "",
                                });
                              }}
                            />

                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.email}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formMobile">
                            <Form.Label>
                              Mobile<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className={
                                errors.mobile !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  mobile: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  mobile: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.mobile}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>Notice Period</Form.Label>
                            <SimpleSelectComponent
                            // className={errorClass?.phoneCode ?? ""}
                            // options={phoneCodeOptionsData}
                            // value={{
                            // 	value: candidateData?.phoneCode,
                            // 	label: candidateData?.phoneCode,
                            // }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Basic Info */}

                  {/* Begin: Professional Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <h4 className="title">Professional Details</h4>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formCurrentEmployer">
                            <Form.Label>
                              Current Employer
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                errors.currentEmployer !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  currentEmployer: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  currentEmployer: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.currentEmployer}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formTotalExperience">
                            <Form.Label>
                              Total Experience
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                errors.totalExperience !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  totalExperience: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  totalExperience: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.totalExperience}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCurrentSalary">
                            <Form.Label>
                              Current Salary
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                errors.currentSalary !== "" ? "error-red" : ""
                              }
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  currentSalary: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  currentSalary: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.currentSalary}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formEmail">
                            <Form.Label>Skill Set</Form.Label>
                            <SimpleSelectComponent
                              // className={errorClass?.phoneCode ?? ""}
                              // options={phoneCodeOptionsData}
                              // value={{
                              // 	value: candidateData?.phoneCode,
                              // 	label: candidateData?.phoneCode,
                              // }}
                              onValueChange={(e) => {
                                e &&
                                  setJobApplicationData({
                                    ...jobApplicationData,
                                    skillSet: e.value,
                                  });
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Begin: Professional Details */}

                  {/* Begin: Educational Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <div className="section-heading">
                        <h4 className="title">Educational Details</h4>
                        <div className="add-row">
                          <span
                            className="bx bxs-minus-circle cursor-pointer text-danger"
                            title="Delete"
                            // onClick={() => handleRemoveEducation(index)}
                          ></span>
                          <span
                            className="bx bxs-plus-circle cursor-pointer text-success"
                            title="Add"
                            // onClick={(e) => {
                            // 	handleEducationAdd(e);
                            // }}
                          ></span>
                          {/* {educationDetail.length - 1 === index && (
														<span
															className="bx bxs-plus-circle text-success"
															title="Add"
															onClick={(e) => {
																handleEducationAdd(e);
															}}
														></span>
													)}
													{educationDetail.length !== 1 && (
														<span
															className="bx bxs-minus-circle text-danger"
															title="Delete"
															onClick={() => handleRemoveEducation(index)}
														></span>
													)} */}
                        </div>
                      </div>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formInstitute">
                            <Form.Label>Institute / School</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formDepartment">
                            <Form.Label>Major Department</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formDegree">
                            <Form.Label>Degree</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formDuration">
                            <Form.Label>Duration</Form.Label>
                            <Row>
                              <Col sm={6}>
                                <DatePicker
                                  placeholderText="From"
                                  className="form-control"
                                  // value={i.from}
                                  // onChange={(date) => {
                                  // 	handleEduDetail(
                                  // 		moment(date).format("MM/yyyy"),
                                  // 		index,
                                  // 		"from"
                                  // 	);
                                  // }}
                                  selectsStart
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                />
                              </Col>
                              <Col sm={6}>
                                <DatePicker
                                  placeholderText="To"
                                  className="form-control"
                                  // value={i.to}
                                  // onChange={(date) => {
                                  // 	handleEduDetail(
                                  // 		moment(date).format("MM/YYYY"),
                                  // 		index,
                                  // 		"to"
                                  // 	);
                                  // }}
                                  selectsStart
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                />
                              </Col>
                            </Row>
                            <div className="custom-checkbox text-right mt-2">
                              <label
                                className="form-label"
                                htmlFor="currentlyPursuing" //{`pursuing${index}`}
                              >
                                Currently Pursuing
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="currentlyPursuing"
                                  id="currentlyPursuing" //{`pursuing${index}`}
                                  // checked={i.pursuing}
                                  // onChange={(e) => {
                                  // 	handleEduDetail(
                                  // 		e.target.checked,
                                  // 		index,
                                  // 		"pursuing"
                                  // 	);
                                  // }}
                                />
                                <span></span>
                              </label>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Educational Details */}

                  {/* Begin: Experience Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <div className="section-heading">
                        <h4 className="title">Experience Details</h4>
                        <div className="add-row">
                          <span
                            className="bx bxs-minus-circle cursor-pointer text-danger"
                            title="Delete"
                            // onClick={() => handleRemoveEducation(index)}
                          ></span>
                          <span
                            className="bx bxs-plus-circle cursor-pointer text-success"
                            title="Add"
                            // onClick={(e) => {
                            // 	handleEducationAdd(e);
                            // }}
                          ></span>
                          {/* {educationDetail.length - 1 === index && (
													<span
														className="bx bxs-plus-circle text-success"
														title="Add"
														onClick={(e) => {
															handleEducationAdd(e);
														}}
													></span>
												)}
												{educationDetail.length !== 1 && (
													<span
														className="bx bxs-minus-circle text-danger"
														title="Delete"
														onClick={() => handleRemoveEducation(index)}
													></span>
												)} */}
                        </div>
                      </div>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formOccupation">
                            <Form.Label>Occupation / Title</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCompany">
                            <Form.Label>Company</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formSummary">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control as="textarea" type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formWorkDuration">
                            <Form.Label>Work Duration</Form.Label>
                            <Row>
                              <Col sm={6}>
                                <DatePicker
                                  placeholderText="From"
                                  className="form-control"
                                  // value={i.from}
                                  // onChange={(date) => {
                                  // 	handleEduDetail(
                                  // 		moment(date).format("MM/yyyy"),
                                  // 		index,
                                  // 		"from"
                                  // 	);
                                  // }}
                                  selectsStart
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                />
                              </Col>
                              <Col sm={6}>
                                <DatePicker
                                  placeholderText="To"
                                  className="form-control"
                                  // value={i.to}
                                  // onChange={(date) => {
                                  // 	handleEduDetail(
                                  // 		moment(date).format("MM/YYYY"),
                                  // 		index,
                                  // 		"to"
                                  // 	);
                                  // }}
                                  selectsStart
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                />
                              </Col>
                            </Row>
                            <div className="custom-checkbox text-right mt-2">
                              <label
                                className="form-label"
                                htmlFor="currentlyWorking" //{`pursuing${index}`}
                              >
                                I currently work here
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="currentlyWorking"
                                  id="currentlyWorking" //{`pursuing${index}`}
                                  // checked={i.pursuing}
                                  // onChange={(e) => {
                                  // 	handleEduDetail(
                                  // 		e.target.checked,
                                  // 		index,
                                  // 		"pursuing"
                                  // 	);
                                  // }}
                                />
                                <span></span>
                              </label>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Experience Details */}

                  {/* Begin: Address Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <h4 className="title">Address Details</h4>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formStreet">
                            <Form.Label>Street</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formZip">
                            <Form.Label>Zip / Postal Code</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCity">
                            <Form.Label>
                              City <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={errors.city !== "" ? "error-red" : ""}
                              onChange={(e) => {
                                setJobApplicationData({
                                  ...jobApplicationData,
                                  city: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  city: "",
                                });
                              }}
                            />
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            >
                              {errors.city}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formProvince">
                            <Form.Label>State / Province</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Address Details */}

                  {/* Begin: Social Network Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <h4 className="title">Social Network</h4>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <Form.Group controlId="formLinkedIn">
                            <Form.Label>LinkedIn</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group controlId="formFacebook">
                            <Form.Label>Facebook</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Social Network Details */}

                  {/* Begin: Attachment Details */}
                  <div className="panel">
                    <div className="panel-header">
                      <h4 className="title">Attachment Information</h4>
                    </div>
                    <div className="panel-content pt-0">
                      <Row>
                        <Col md="6">
                          <div className="document-upload">
                            <Form.Label>Image</Form.Label>
                            <FileUploader
                              // disabled={!datas.type ? true : false}
                              // handleChange={(e) =>
                              //   handleChange(e, idx)
                              // }
                              name="file"
                              types={fileTypes}
                              // style={wrapper}
                              // maxSize={5}
                              // onSizeError={fileSizeValidation}
                              // onTypeError={fileTypeValidation}
                            />
                            <span className="error-msg text-danger">
                              {/* {datas.FileNameError} */} Please Upload
                              correct file
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Ends: Attachment Details */}

                  {/* Begin: Submit Button */}
                  <div className="button-group d-flex justify-content-end align-items-center my-4">
                    <Button variant="link" type="button" className="mr-2">
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                  {/* Ends: Submit Button */}
                </Form>
                {/* Form Fields Ends */}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      {/* <section className="webform-footer">
				<Container fluid>
					<h6 className="font-weight-medium mb-0">
						IndiaNIC © {new Date().getFullYear()}
					</h6>
				</Container>
			</section> */}
    </React.Fragment>
  );
};

export default JobApplicationForm;
