import { input } from "react-dom-factories";
import { combineReducers } from "redux";
import LoginReducer from "../pages/Login/reducer";
import careerReducer from "../pages/Career/reducer";

const appReducer = combineReducers({
  login: LoginReducer,
  career: careerReducer,
});

export default appReducer;
