import { API_URL } from "../../config";
import API from "../../api/Routes";
import axios from "axios";
import { toast } from "react-toastify";
import ACTION from "../../actions";
import ApiServices from "../../services/Api.services";
import { showErrorToast, showSuccessToast } from "../../utils/Functions";

let openAPIToken = process.env.REACT_APP_OPEN_API_TOKEN;
export const jobListing = () => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.JOB_LISTING,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.JOB_LISTING,
        payload: response.data.data,
      });
    });
  } catch (error) {
    toast.info(error);
  }
};

export const getJobDetailById = (id) => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.GET_JOB_BY_ID + `?jobId=${id}`,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.GET_JOB_BY_ID,
        payload: response.data.data,
      });

      return response;
    });
  } catch (error) {
    toast.info(error);
  }
};

// export const addCvDetails = (payload) => async (dispatch) => {
//   try {
//     await axios({
//       method: "POST",
//       url: API_URL + API.GET_CANDIDATE_CV_DETAILS,
//       headers: {
//         "Content-type": "multipart/form-data",
//         // Authorization: getItem("accessToken"),
//       },
//       body: payload,
//     }).then((response) => {
//       dispatch({
//         type: ACTION.GET_CANDIDATE_CV_DETAILS,
//         payload: response?.data,
//       });
//       showSuccessToast(response.data.message);
//     });
//   } catch (error) {
//     showErrorToast(error);
//   }
// };

export const addCvDetails = (payload) => async (dispatch) => {
  try {
    const response = await ApiServices.postApi(
      API_URL + API.GET_CANDIDATE_CV_DETAILS,
      payload
    );
    if (response.data.status === 1) {
      dispatch({
        type: ACTION.GET_CANDIDATE_CV_DETAILS,
        payload: response.data,
      });
      // showSuccessToast(response.data.message);
    } else if (response.data.status === 0) {
      dispatch({
        type: ACTION.GET_CANDIDATE_CV_DETAILS,
      });
      // showErrorToast("Please upload valid CV/Resume");
    } else {
      dispatch({
        type: ACTION.GET_CANDIDATE_CV_DETAILS,
        payload: response.data,
      });
      // showSuccessToast(response.data.message);
    }
    return response;
  } catch (error) {
    showErrorToast(error);
  }
};

export const getSkillDetails = (payload) => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.GET_SKILL_DETAILS,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.GET_SKILL_DETAILS,
        payload: response.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};

export const addCandidateData = (payload) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      url: API_URL + API.ADD_CANDIDATE_DETAILS,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
      data: JSON.stringify(payload),
    });
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 1) {
        dispatch({
          type: ACTION.ADD_CANDIDATE_DETAILS,
          payload: response.data,
        });
        // showSuccessToast(response.data.message);
      } else if (response.data.status === 0) {
        dispatch({
          type: ACTION.ADD_CANDIDATE_DETAILS,
          payload: response.data,
        });
        // toast.info(response.data.message);
      } else {
        dispatch({
          type: ACTION.ADD_CANDIDATE_DETAILS,
          payload: response.data,
        });
        // showSuccessToast(response.data.message);
      }
    }
  } catch (error) {
    showErrorToast(error);
  }
};

// export const countryDetailsListing = (payload) => async (dispatch) => {
//   try {
//     await ApiServices.getApi(API_URL + API.GET_COUNTRY_CODE, payload).then(
//       (response) => {
//         dispatch({
//           type: ACTION.GET_COUNTRY_CODE,
//           countryDetailedList: response.data,
//         });
//       }
//     );
//   } catch (error) {
//     showErrorToast(error);
//   }
// };

export const countryCodeListing = () => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.GET_COUNTRY_CODE,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.GET_COUNTRY_CODE,
        payload: response.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
  @Purpose : Used for Qualification  data
  @Parameter : payload
  @Author : INIC
  ******************/

export const getQualificationList = () => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.GET_QUALIFICATION_DETAILS,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.GET_QUALIFICATION_DETAILS,
        payload: response.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
  @Purpose : Used for Candidate fileupload
  @Parameter : payload
  @Author : INIC
  ******************/
export const candidateFileUpload = (payload) => async (dispatch) => {
  try {
    const response = await ApiServices.postApi(
      API_URL + API.FILEUPLOAD_CANDIDATE,
      payload
    );
    if (response.data.status === 1) {
      dispatch({
        type: ACTION.FILEUPLOAD_CANDIDATE,
        payload: response.data,
      });

      // showSuccessToast(response.data.message);
    } else if (response.data.status === 0) {
      dispatch({
        type: ACTION.FILEUPLOAD_CANDIDATE,
      });
      showErrorToast("File not uploaded");
    } else {
      dispatch({
        type: ACTION.FILEUPLOAD_CANDIDATE,
        payload: response.data,
      });
      // showSuccessToast(response.data.message);
    }

    return response;
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
  @Purpose : Used for Candidate filedelete
  @Parameter : payload
  @Author : INIC
  ******************/
export const candidateFileDelete = (payload) => async (dispatch) => {
  try {
    const response = await ApiServices.postApi(
      API_URL + API.CANDIDATE_FILEDELETE,
      payload
    );
    if (response.data.status === 1) {
      dispatch({
        type: ACTION.CANDIDATE_FILEDELETE,
        payload: response.data,
      });

      // showSuccessToast(response.data.message);
    } else if (response.data.status === 0) {
      dispatch({
        type: ACTION.CANDIDATE_FILEDELETE,
      });
      showErrorToast("File not uploaded");
    } else {
      dispatch({
        type: ACTION.CANDIDATE_FILEDELETE,
        payload: response.data,
      });
      // showSuccessToast(response.data.message);
    }

    return response;
  } catch (error) {
    showErrorToast(error);
  }
};

export const getFormSetting = (value) => async (dispatch) => {
  try {
    await axios({
      method: "GET",
      url: API_URL + API.GET_FORM_SETTING + `?KEY=${value} `,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
    }).then((response) => {
      dispatch({
        type: ACTION.GET_FORM_SETTING,
        payload: response.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
  @Purpose : Used for get WalkInDriveDetails  
  @Parameter : payload
  @Author : INIC
  ******************/

export const getWalkInDriveDetails = (payload) => async (dispatch) => {
  try {
    await ApiServices.getApi(API_URL + API.GET_WALFINDRIVE_DETAILS).then(
      (response) => {
        dispatch({
          type: ACTION.GET_WALFINDRIVE_DETAILS,
          payload: response.data,
        });
      }
    );
  } catch (error) {
    showErrorToast(error);
  }
};

export const getCountryDetails = () => async (dispatch) => {
  try {
    await ApiServices.getApi(API_URL + API.GET_COUNTRY_DETAILS).then(
      (response) => {
        dispatch({
          type: ACTION.COUNTRY_DETAIL_LIST,
          payload: response.data.data,
        });
      }
    );
  } catch (error) {
    showErrorToast(error);
  }
};

export const addSkillsMaster = (payload) => async (dispatch) => {
  try {
    await ApiServices.postApi(API_URL + API.ADD_SKILL_MASTER, payload).then(
      (response) => {
        dispatch({
          type: ACTION.ADD_SKILLS_MASTER,
          masterSkills: response.data.data,
        });
        dispatch(getSkillDetails());
        return response;
      }
    );
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
  @Purpose : Used for add candidate form for walk-in drive
  @Parameter : payload
  @Author : INIC
  ******************/
export const addWalkInDriveData = (payload) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      url: API_URL + API.ADD_WALK_IN_DRIVE,
      headers: {
        "Content-type": "application/json",
        Authorization: openAPIToken,
      },
      data: JSON.stringify(payload),
    });
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 1) {
        dispatch({
          type: ACTION.ADD_WALK_IN_DRIVE,
          payload: response.data,
        });
        // showSuccessToast(response.data.message);
      } else if (response.data.status === 0) {
        dispatch({
          type: ACTION.ADD_WALK_IN_DRIVE,
          payload: response.data,
        });
        // toast.info(response.data.message);
      } else {
        dispatch({
          type: ACTION.ADD_WALK_IN_DRIVE,
          payload: response.data,
        });
        // showSuccessToast(response.data.message);
      }
    }
  } catch (error) {
    showErrorToast(error);
  }
};

/******************* 
@Purpose : Used for technology listing
@Parameter : payload
@Author : INIC
******************/
export const technologyListing = (payload) => async (dispatch) => {
  try {
    await ApiServices.postApi(
      API_URL + API.MASTER_TECHNOLOGY_API_LISTING,
      payload
    ).then((response) => {
      dispatch({
        type: ACTION.MASTER_TECHNOLOGY_API_LISTING,
        payload: response.data,
      });
    });
  } catch (error) {
    showErrorToast(error);
  }
};
