import React, { useState } from "react";
import Layout from "../../components/Layout";
// import CreateForm from "./ConsultantForm";
// import Settings from "./tabs/ViewDetail";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { Container, Modal, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DataTableComponent from "../../components/DataTable/DataTable";

/***********
@Purpose : Used for handling tabs of user's and group
@Parameter : {}
@Author : INIC
********************/
const WebForm = () => {
  const [show2, setShow2] = useState(false);

  /******************* 
  @Purpose : Close modal
  @Parameter : {}
  @Author : INIC
  ******************/
  const closeModalHandler = () => {
    setShow2(false);
  };

  /******************* 
@Purpose : Set custom checkbox 
@Parameter : value
@Author : INIC ̰
******************/
  const customCheckbox = (value) => (
    <div className="custom-checkbox">
      <label htmlFor={value.name} className="datatable-checkbox-label">
        <input type="checkbox" id={value.name} {...value} />
        <span></span>
      </label>
    </div>
  );

  // Begin: Datatable Column & Data
  const columns = [
    {
      name: "Form Name",
      selector: (row) => row.formName,
      grow: 3,
      sortable: true,
    },
    {
      name: "Get Link",
      selector: (row) => row.getLink,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = [
    {
      id: 1,
      formName: <Link to="/web-form/viewForm">Via Consultant Form</Link>,
      getLink: (
        <Link
          to="#"
          onClick={() => setShow2(true)}
          variant="link"
          className="d-inline-flex align-items-center"
        >
          Share <span className="bx bx-share-alt"></span>
        </Link>
      ),
      status: (
        <div className="custom-control custom-switch light">
          <input type="checkbox" className="custom-control-input" />
          <label className="custom-control-label"></label>
        </div>
      ),
      action: (
        <Link to="/web-form/tabs/FormSettings">
          <span className="bx bx-cog"></span>
        </Link>
      ),
    },
    {
      id: 2,
      formName: (
        <Link to="/web-form/job-application-form">Job Application Form</Link>
      ),
      getLink: (
        <Link
          to="#"
          onClick={() => setShow2(true)}
          variant="link"
          className="d-inline-flex align-items-center"
        >
          Share <span className="bx bx-share-alt"></span>
        </Link>
      ),
      status: (
        <div className="custom-control custom-switch light">
          <input type="checkbox" className="custom-control-input" />
          <label className="custom-control-label"></label>
        </div>
      ),
      action: (
        <Link to="/web-form/tabs/FormSettings">
          <span className="bx bx-cog"></span>
        </Link>
      ),
    },
    {
      id: 3,
      formName: <Link to="/web-form/referral-form">Referral Form</Link>,
      getLink: (
        <Link
          to="#"
          onClick={() => setShow2(true)}
          variant="link"
          className="d-inline-flex align-items-center"
        >
          Share <span className="bx bx-share-alt"></span>
        </Link>
      ),
      status: (
        <div className="custom-control custom-switch light">
          <input type="checkbox" className="custom-control-input" />
          <label className="custom-control-label"></label>
        </div>
      ),
      action: (
        <Link to="/web-form/tabs/FormSettings">
          <span className="bx bx-cog"></span>
        </Link>
      ),
    },
  ];
  // Ends: Datatable Column & Data

  return (
    <Layout>
      <BreadCrumb
        routes={[
          {
            name: "Web Forms",
          },
        ]}
      />
      <Container>
        <div className="panel">
          <div className="panel-header">
            <h5 className="title">Web Forms</h5>
            <p className="sub-title">
              Seamlessly push date from your custom webforms into your recruit
              system.
            </p>
            {/* <div className="section-heading">
              <div className="title-box">
                <h5 className="title">Web Forms</h5>
                <p className="sub-title">Seamlessly push date from your custom webforms into your recruit system.</p>
              </div>
              <Link to="/web-form/create" className="btn btn-primary"> <span className="bx bxs-plus-circle"></span> Create</Link>
            </div> */}
          </div>
          <div className="panel-content master-data-table px-0">
            <DataTableComponent columns={columns} data={data} />
          </div>
        </div>
      </Container>

      {/* Begin: Get Share Code */}
      <Modal show={show2} onHide={closeModalHandler} centered>
        <Modal.Header>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="title mb-0">Link to your webform</h5>
          <small>
            Your forms has been published securely and is ready to use at the
            address below
          </small>
          <Form.Group className="mt-4" controlId="password">
            <div className="d-flex align-items-center">
              <span className="disabled-input-field" aria-disabled></span>
              {/* <Link to="#" className="btn btn-icon-only btn-link bx bx-copy p-2 ml-2"></Link> */}
            </div>
          </Form.Group>
          <div className="flex-box">
            <Button size="sm" variant="outline-primary">
              Shorten URL
            </Button>
            <div className="d-flex justify-content-end">
              <Button size="sm" variant="link" className="mr-2">
                <span className="bx bx-link-external"></span> Open Link
              </Button>
              <Button size="sm" variant="primary">
                Copy
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={closeModalHandler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Ends: Get Share Code */}
    </Layout>
  );
};

export default WebForm;
