import React, { Component } from "react";
import "./LinearLoader.css";

class LinearLoader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="loader-container">
          <p className="preloader-text">Loading...</p>
          <div className="inner-loader">
            <div className="linear-activity">
              <div className="indeterminate"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LinearLoader;