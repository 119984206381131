import React, { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { login, resetUser } from "./actions";
import { Link, useHistory } from "react-router-dom";
import {
  setItem,
  showSuccessToast,
  showErrorToast,
} from "../../utils/Functions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import InicImg from "../../../src/assets/images/INIC-logo.png";
import tileImages1 from "../../../src/assets/images/login-img-1.jpeg";
import tileImages2 from "../../../src/assets/images/login-img-2.jpeg";
import tileImages3 from "../../../src/assets/images/login-img-3.jpeg";
import tileImages4 from "../../../src/assets/images/login-img-4.jpeg";

/******************* 
@Purpose : Object containing paths for application
@Parameter : {}
@Author : INIC
******************/
function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.login);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [webLogin, setWebLogin] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (data?.status === 1) {
      data?.data?.companyId && history.push("/web-form/consultant-form");
      dispatch(resetUser());
      showSuccessToast("Login Successfully");
    } else {
      showErrorToast(data?.message);
    }
  }, [data]);

  const validateForm = () => {
    let isFormValid = true;
    let error = {
      email: "",
      password: "",
    };
    let emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (webLogin.email === "") {
    //   error.email = "Please Enter Valid Email";
    // } else error.email = "";
    if (webLogin.email === "") {
      error.email = "Email is required";
    } else if (!new RegExp(emailRegex).test(webLogin.email)) {
      error.email = "Please provide a valid email";
    } else error.email = "";

    if (webLogin.password === "") {
      error.password = "Please Enter Valid Password";
    } else error.password = "";

    if (error.email !== "" || error.password !== "") {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    setErrors(error);
    return isFormValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let obj = {
        emailId: webLogin.email,
        password: webLogin.password,
      };
      await dispatch(login(obj));
    }
  };

  return (
    <div className="main-containt">
      <div className="loginScreen">
        <div className="loginScreen__wrapper">
          <div className="loginScreen__content">
            <div className="white-box">
              <Image src={InicImg} alt="IndiaNIC Logo" className="mb-2" fluid />
              <h1 className="display-4 font-weight-semibold text-primary mb-0">
                Consultant Portal
              </h1>
            </div>

            <div className="tiles-img-container">
              <Image
                src={tileImages2}
                alt="Login Images"
                fluid
                className="tiles-img-1"
              />
              <Image
                src={tileImages1}
                alt="Login Images"
                fluid
                className="tiles-img-2"
              />
              <Image
                src={tileImages4}
                alt="Login Images"
                fluid
                className="tiles-img-3"
              />
              <Image
                src={tileImages3}
                alt="Login Images"
                fluid
                className="tiles-img-4"
              />
            </div>
          </div>
          <div className="loginScreen__form">
            <div className="loginScreen__form-body">
              <div className="loginScreen__form-body-heading">
                <h2 className="main-heading">Sign in</h2>
                <span className="sub-heading">
                  Hey, Enter your details to get sign in to your account.
                </span>
              </div>

              <Form onSubmit={handleLogin}>
                <Form.Group controlId="userName" className="mb-2">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    className={errors.email !== "" ? "error-red" : ""}
                    placeholder="Enter your email address"
                    // value={email}
                    maxLength="40"
                    onChange={(e) => {
                      if (e) {
                        setWebLogin({
                          ...webLogin,
                          email: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          email: "",
                        });
                      }
                    }}
                  />
                  <span className="error-msg" style={{ color: "red" }}>
                    {errors.email}
                  </span>
                </Form.Group>

                <Form.Group controlId="userPassword" className="mb-2">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    className={errors.password !== "" ? "error-red" : ""}
                    placeholder="Enter your password"
                    // value={password}
                    onChange={(e) => {
                      if (e) {
                        setWebLogin({
                          ...webLogin,
                          password: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          password: "",
                        });
                      }
                    }}
                  />
                  <span className="error-msg" style={{ color: "red" }}>
                    {errors.password}
                  </span>
                </Form.Group>

                <div className="d-flex align-items-center justify-content-between mb-2">
                  <Form.Group controlId="rememberMe">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>

                  <Link href="#" to="/" className="forgot-password-link">
                    Forgot Password?
                  </Link>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  className="justify-content-center py-2"
                  size="block"
                >
                  Submit
                </Button>
              </Form>
              {/* <div className="google-login-btn text-center mt-2">
								<GoogleLogin
									clientId={clientId}
									buttonText="Login With Google"
									onSuccess={(e) => handleLogin(e)}
									cookiePolicy={"single_host_origin"}
								/>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
