import React, { useState } from "react";

const Tabs = ({ initialActiveTab, components, extraProps }) => {
  const [activetab, setActiveTab] = useState(initialActiveTab);

  const getActiveTabClass = (tab) => {
    if (tab === activetab) return "nav-item nav-link active";
    else return "nav-item nav-link";
  };

  const setTabActive = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <React.Fragment>
      <nav className="tab-section d-flex justify-content-between align-items-center">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {components.map((item, index) => {
            return (
              <button
                key={index}
                className={getActiveTabClass(item.button)}
                onClick={() => {
                  setTabActive(item.button);
                }}
              >
                {item.tabName}
              </button>
            );
          })}
        </div>
        {extraProps && extraProps}
      </nav>
      <React.Fragment>
        {components.map((item) => item.button === activetab && item?.name)}
      </React.Fragment>
    </React.Fragment>
  );
};

export default Tabs;
