import React from "react";
import { MultiSelect } from "react-selectize";

class MultiSelectComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  /******************* 
    @Purpose : Used for create removable multiselect
    @Parameter : 
    @Author : INIC
  ******************/
  render() {
    const self = this;
    return (
      <MultiSelect
        {...this.props}
        style={{ borderRadius: "6px" }}
        renderValue={function (item) {
          return (
            <div className="simple-value">
              <span>{item.label}</span>
              <span className="bx bx-x"   onClick={() => self?.props?.onRemoveItem(item)}></span>
            </div>
          );
        }}
      />
    );
  }
}

export default MultiSelectComponent;
