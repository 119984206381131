import actionTypes from "../../actions/index";
import { setItem } from "../../utils/Functions";
/******************* 
@Purpose : Object containing paths for application
@Parameter : {}
@Author : INIC
******************/

const LoginReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      setItem("accessToken", action?.payload?.token);
      setItem("userInfo",JSON.stringify(action.payload && action.payload.data))
      return {
        loading: false,
        data: action.payload,
      };

    case actionTypes.USER_LOGOUT:
      return {
        loading: false,
        data: {},
      };
      case actionTypes.RESET_USER:
      return {
        loading: false,
        data: {},
      };


    case actionTypes.LOGIN_ENCRYPTED_DATA:
      return {
        loginEncryptedData: action.loginEncryptedData,
      };

    default:
      return state;
  }
};

export default LoginReducer;
