import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { renderRoutes } from "./Router/Routes";
import { PrivateRoute } from "./Router/PrivateRoute";
import { Authorization } from "./utils/Functions";
import store from "./store";
import { Provider } from "react-redux";
import { paths } from "./Router/Constant";
// import "react-datepicker/dist/react-datepicker.css";

function App() {
  useEffect(() => {
    !Authorization() && <Redirect exact from="/" to={paths.login} />;
  }, []);

  return (
    <Provider store={store}>
      <div className="app h-100 d-flex flex-column">
        <Router>
          <Switch>
            {renderRoutes.map(([key, route]) => {
              return (
                <PrivateRoute
                  key={key}
                  {...route}
                  privateRoute={route.private}
                />
              );
            })}
            <Redirect to="/home" />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
