import actionTypes from "../../actions/index";

const initialState = {
  jobDetailsLising: {},
  getJobData: {},
  getCandidateCvDetails: {},
  getSkillList: [],
  addJobOpening: {},
  getCountryCode: {},
  getQualificationDetails: [],
  walkinDriveStatus: {},
  getWalkInDrivedetails: {},
  getCountryDetails: {},
  addWalkInDriveCandidate: {},
  technologyDetailedList: {},
};

const careerListing = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.JOB_LISTING:
      return {
        ...state,
        jobDetailsLising: action.payload,
      };

    case actionTypes.GET_JOB_BY_ID:
      return {
        ...state,
        getJobData: action.payload,
      };

    case actionTypes.GET_CANDIDATE_CV_DETAILS:
      return { ...state, getCandidateCvDetails: action.payload };

    case actionTypes.GET_SKILL_DETAILS:
      return { ...state, getSkillList: action.payload };

    case actionTypes.ADD_CANDIDATE_DETAILS:
      return { ...state, addJobOpening: action.payload };

    case actionTypes.GET_COUNTRY_CODE:
      return { ...state, getCountryCode: action.payload };

    case actionTypes.GET_QUALIFICATION_DETAILS:
      return { ...state, getQualificationDetails: action.payload };

    case actionTypes.GET_FORM_SETTING:
      return { ...state, walkinDriveStatus: action.payload };

    case actionTypes.GET_WALFINDRIVE_DETAILS:
      return { ...state, getWalkInDrivedetails: action.payload };

    case actionTypes.COUNTRY_DETAIL_LIST:
      return { ...state, getCountryDetails: action.payload };

    case actionTypes.ADD_WALK_IN_DRIVE:
      return { ...state, addWalkInDriveCandidate: action.payload };

    case actionTypes.MASTER_TECHNOLOGY_API_LISTING:
      return {
        ...state,
        technologyDetailedList: action.payload,
      };
    default:
      return state;
  }
};

export default careerListing;
