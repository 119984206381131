import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Image,
  Spinner,
} from "react-bootstrap";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import SimpleSelectComponent from "../../components/SimpleSelect/SimpleSelectComponent";
import DatePicker from "react-datepicker";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import InputMask from "react-input-mask";
import inicLogo from "../../assets/images/INIC-logo.png";
// import bgImg from "../../assets/images/main-bg.png";
import UploadIcon from "../../assets/images/Folder.svg";
import UploadImageIcon from "../../assets/images/avatar-image.png";
import { useDispatch, useSelector } from "react-redux";
import {
  countryCodeListing,
  addCandidateData,
  getQualificationList,
  addCvDetails,
  getSkillDetails,
  candidateFileUpload,
  getFormSetting,
  getWalkInDriveDetails,
  candidateFileDelete,
} from "../Career/action";
import MultiSelectComponent from "../../components/Multiselect/MultiSelectComponent";
import { emailValidator, textValidator } from "../../utils/Functions";
import swal from "sweetalert";
import Footer from "./../../components/Footer";
import ToolTipComponent from "../../components/ToolTip/ToolTipComponent";
import docIcon from "../../assets/images/icons-doc.png";

const ReferralForm = () => {
  const timeOut = useRef();
  const firstNameRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const fileUpload = useSelector((state) => state.career.getCandidateCvDetails);
  const code = useSelector((state) => state.career.getCountryCode.data);
  const skillDetail = useSelector((state) => state.career.getSkillList.data);
  const addJob = useSelector((state) => state.career.addJobOpening);

  const qualificationDetail = useSelector(
    (state) => state.career.getQualificationDetails
  );
  const walkinDriveSourceDetail = useSelector(
    (state) => state.career.getWalkInDrivedetails.data
  );
  const walkinStatus = useSelector((state) => state.career.walkinDriveStatus);
  const [preview, setPreview] = useState();
  const [fileType, setFileType] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [phoneCodeOptionsData, setPhoneCodeOptionsData] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const [experienceStartMonth, setExperienceStartMont] = useState();
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walkinDrive, setWalkinDrive] = useState("");
  const [walkinDriveSourceOption, setWalkinDriveSourceOption] = useState([]);

  const [consultantData, setConsultantData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: {},
    noticePeriod: {},
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: [],
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    referrerName: "",
    referrerEmail: "",
    relation: "",
    knownPeriod: "",
    qualificationId: {},
    qualification: {},
    walkinDriveSourceId: "",
    walkinDriveSource: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phoneCode: "",
    noticePeriod: "",
    currentEmployer: "",
    totalExperiance: "",
    currentSalary: "",
    skillSet: "",
    educationalDetails: [],
    experienceDetails: [],
    faceBook: "",
    linkdin: "",
    summary: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    referrerName: "",
    referrerEmail: "",
    relation: "",
    knownPeriod: "",
    qualificationId: "",
    qualification: "",
  });
  const [educationDetails, setEducationDetails] = useState([
    {
      institute: "",
      major: "",
      degree: "",
      from: "",
      to: "",
      pursuing: false,
    },
  ]);
  const [experienceDetails, setExperienceDetails] = useState([
    {
      occupation: "",
      company: "",
      summary: "",
      from: "",
      to: "",
      isWorking: false,
    },
  ]);

  useEffect(() => {
    document.title = "Referral Form";
    const query = new URLSearchParams(location.search);
    const keyName = query.get("key");
    keyName && dispatch(getFormSetting(keyName));
  }, []);

  useEffect(() => {
    if (skillDetail && skillDetail.length > 0) {
      const list = [];
      skillDetail?.map((item) => {
        return list.push({
          label: item?.skill,
          value: item?._id,
        });
      });
      setSkillSetOption(list);
    } else {
      setSkillSetOption([]);
    }
  }, [skillDetail]);

  useEffect(() => {
    if (addJob && addJob.status === 1) {
      setIsLoading(false);
      let payload = new FormData();
      payload.append("userId", addJob?.data?._id);
      payload.append("type", "resume");
      payload.append("file", file);
      dispatch(candidateFileUpload(payload));
      setShow(true);
    } else if (addJob && addJob.status === 0) {
      swal({
        title:
          "Candidate has been already exists. do you want to add another candidate ?",
        icon: "warning",
        buttons: ["No", "Yes"],
        // dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          resetData();
        }
      });
    }
  }, [addJob]);

  useEffect(() => {
    if (fileUpload) {
      let list = [];
      let ids = [];
      fileUpload?.data?.candidateSkillSet.map((item) =>
        list.push({ value: item._id, label: item.skill })
      );

      setSelectedSkill(list);
      fileUpload?.data?.candidateSkillSet.map((item) => {
        if (item._id !== "") {
          ids.push(item._id);
        }
      });
      setConsultantData({
        ...consultantData,
        firstName: fileUpload?.data?.firstName
          ? fileUpload?.data?.firstName
          : "",
        lastName: fileUpload?.data?.lastName ? fileUpload?.data?.lastName : "",
        email: fileUpload?.data?.emailId ? fileUpload?.data?.emailId : "",
        mobile: fileUpload?.data?.phone ? fileUpload?.data?.phone : "",
        skillSet: ids.length > 0 ? ids : [],
      });
      setErrors({
        ...errors,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
      });
    }
  }, [fileUpload]);

  useEffect(() => {
    if (walkinDriveSourceDetail && walkinDriveSourceDetail.length > 0) {
      const list = [];
      walkinDriveSourceDetail?.map((item) => {
        return list.push({
          label: item?.walkInDrive,
          value: item?._id,
        });
      });
      setWalkinDriveSourceOption(list);
    } else {
      setWalkinDriveSourceOption([]);
    }
  }, [walkinDriveSourceDetail]);

  useEffect(() => {
    dispatch(countryCodeListing());
    setSkillSetOption([]);
    dispatch(getQualificationList());
    dispatch(getWalkInDriveDetails());

    setWalkinDriveSourceOption([]);
  }, []);

  useEffect(() => {
    if (code && code.length > 0) {
      const list = [];
      code?.map((item) => {
        list.push({
          label: `+${item?.phoneCode}`,
          value: `+${item?.phoneCode}`,
        });
        setPhoneCodeOptionsData(list);
      });
    }
  }, [code]);

  useEffect(() => {
    if (walkinStatus && walkinStatus.status === 1) {
      setWalkinDrive(walkinStatus?.data?.isWalkInDrive);
    }
  }, [walkinStatus]);

  const handleReupload = () => {
    setFile();
    setPreview("");
    setFileList([]);
    setFileType("");
    setErrors({
      ...errors,
      succsessFile: "",
      file: "",
    });
    setSelectedSkill([]);
    let deletePayload = {
      type: "resume",
      filePath: consultantData.resume,
    };
    dispatch(candidateFileDelete(deletePayload));
    setConsultantData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      resume: "",
      qualificationId: "",
      qualification: "",
    });
  };

  const handleEducationDetails = (e, index, name) => {
    const eduValues = [...educationDetails];
    eduValues[index][name] = e;
    let today = new Date();
    if (name === "pursuing" && e === true) {
      eduValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setEducationDetails(eduValues);
  };

  const handleExpDetail = (e, index, name) => {
    const expValues = [...experienceDetails];
    expValues[index][name] = e;
    let today = new Date();
    if (name === "isWorking" && e === true) {
      expValues[index]["to"] = moment(today).format("MM/yyyy");
    }
    setExperienceDetails(expValues);
  };

  /******************* 
   @Purpose : function for + functionality
   @Parameter : {}
   @Author : INIC
  ******************/
  const handleAddEducation = (e) => {
    e.preventDefault();
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
      ...educationDetails,
    ]);
  };

  const handleExeperienceAdd = (e) => {
    e.preventDefault();
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
      ...experienceDetails,
    ]);
  };

  const handleRemoveEducation = (index) => {
    const educationValues = [...educationDetails];
    let obj = educationDetails[index];
    delete obj["institute"];
    delete obj["degree"];
    delete obj["from"];
    delete obj["to"];
    delete obj["major"];
    delete obj["pursuing"];
    educationValues.splice(index, 1);
    setEducationDetails(educationValues);
  };

  const handleRemoveExperience = (index) => {
    const experienceValues = [...experienceDetails];
    let obj = experienceDetails[index];
    delete obj["company"];
    delete obj["from"];
    delete obj["isWorking"];
    delete obj["occupation"];
    delete obj["summary"];
    delete obj["to"];
    experienceValues.splice(index, 1);
    setExperienceDetails(experienceValues);
  };

  // const getWalkInDriveList = (searchText) => {
  //   clearTimeout(timeOut.current);
  //   timeOut.current = setTimeout(async () => {
  //     if (searchText !== "") {
  //       await dispatch(getWalkInDriveDetails(searchText));
  //     } else {
  //       setWalkinDriveSourceOption(null);
  //     }
  //   }, 600);
  // };

  const getSkillSetList = (searchText) => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      if (searchText !== "") {
        await dispatch(getSkillDetails(searchText));
      } else {
        setSkillSetOption(null);
      }
    }, 600);
  };

  const handleChange = async (file) => {
    if (file) {
      setErrors({
        ...errors,
        succsessFile: "File added succsessfully",
        file: "",
      });
    }
    setFile(file);
    let formData = new FormData();
    formData.append("file", file);
    await dispatch(addCvDetails(formData));
  };

  const addProfile = async (profile) => {
    let payload = new FormData();
    payload.append("file", profile);
    payload.append("type", "photo");
    dispatch(candidateFileUpload(payload)).then((res) => {
      setConsultantData({
        ...consultantData,
        photo: res?.data?.data?.response,
      });
    });
  };

  const handleDataChange = (e, type) => {
    if (type === "num") {
      setConsultantData({ ...consultantData, [e.target.name]: e.target.value });
    } else if (type === "email") {
      setConsultantData({
        ...consultantData,
        [e.target.name]: emailValidator(e.target.value),
      });
    } else {
      setConsultantData({
        ...consultantData,
        [e.target.name]: textValidator(e.target.value),
      });
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let error = {
      firstName: "",
      // phoneCode: "",
      lastName: "",
      email: "",
      mobile: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      city: "",
      referrerName: "",
      referrerEmail: "",
      relation: "",
      qualification: "",
      file: "",
    };
    let mobileRegex = "^[0-9][0-9]{9,12}$";
    // let indianicEmailRegex = "[a-z0-9]+@indianic.com";
    let emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!file) {
      error.file = "Please upload resume";
      setErrors(error);
      swal({
        title: "Please Upload CV/Resume first",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
        }
      });
      isFormValid = false;

      return isFormValid;
    } else {
      error.file = "";

      if (consultantData.firstName === "") {
        firstNameRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        error.firstName = "First Name is required";
      } else error.firstName = "";

      if (consultantData.lastName === "") {
        error.lastName = "Last Name is required";
      } else error.lastName = "";

      if (consultantData.email === "") {
        error.email = "Email is required";
      } else if (!new RegExp(emailRegex).test(consultantData.email)) {
        error.email = "Please provide a valid email";
      } else error.email = "";

      // if (consultantData.phoneCode === "") {
      //   error.phoneCode = "Phone Code is required";
      // } else error.phoneCode = "";

      if (consultantData.mobile === "") {
        error.mobile = "Mobile is required";
      } else if (!new RegExp(mobileRegex).test(consultantData.mobile)) {
        error.mobile = "Mobile number in between 10-13 digits";
      } else error.mobile = "";

      if (consultantData.currentEmployer === "") {
        error.currentEmployer = "This field is required.";
      } else error.currentEmployer = "";

      if (consultantData.totalExperiance === "") {
        error.totalExperiance = "This field is required.";
      } else error.totalExperiance = "";

      if (consultantData.currentSalary === "") {
        error.currentSalary = "This field is required.";
      } else error.currentSalary = "";

      if (consultantData.city === "") {
        error.city = "This field is required.";
      } else error.city = "";

      if (consultantData.referrerEmail === "") {
        error.referrerEmail = "Email is required";
      } else if (!new RegExp(emailRegex).test(consultantData.referrerEmail)) {
        error.referrerEmail = "Please provide a valid email";
      } else error.referrerEmail = "";

      if (consultantData.referrerName === "") {
        error.referrerName = "Your name is required.";
      } else error.referrerName = "";

      if (consultantData.relation === "") {
        error.relation = "This field is required.";
      } else error.relation = "";

      if (consultantData.qualificationId === "") {
        error.qualification = "This field is required";
      } else error.qualification = "";

      if (file === undefined) {
        error.file = "Please upload resume";
      } else error.file = "";

      if (
        error.firstName !== "" ||
        error.lastName !== "" ||
        error.email !== "" ||
        // error.phoneCode !== "" ||
        error.mobile !== "" ||
        error.currentEmployer !== "" ||
        error.totalExperiance !== "" ||
        error.currentSalary !== "" ||
        error.city !== "" ||
        error.referrerEmail !== "" ||
        error.relation !== "" ||
        error.qualification !== "" ||
        error.referrerName !== "" ||
        error.file !== ""
      ) {
        isFormValid = false;
      } else {
        isFormValid = true;
      }
      setErrors(error);
      return isFormValid;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      let list = [];
      consultantData.skillSet.map((item) => list.push(item.value));

      let obj = {
        // postingTitle: [jobData._id],
        firstName: consultantData.firstName,
        lastName: consultantData.lastName,
        emailId: consultantData.email,
        phoneCode: consultantData.phoneCode.value,
        mobile: consultantData.mobile,
        noticePeriod: consultantData.noticePeriod.value,
        currentEmployer: consultantData.currentEmployer,
        totalExperiance: consultantData.totalExperiance,
        currentSalary: consultantData.currentSalary,
        skillSet: consultantData.skillSet,
        ...(educationDetails.length > 0 && {
          educations: educationDetails,
        }),
        ...(experienceDetails.length > 0 && {
          experiences: experienceDetails,
        }),
        street: consultantData.street,
        zipCode: consultantData.zipCode,
        city: consultantData.city,
        state: consultantData.state,
        country: consultantData.country,
        faceBook: consultantData.faceBook,
        linkdin: consultantData.linkdin,
        referrerEmail: consultantData.referrerEmail,
        referrerName: consultantData.referrerName,
        relation: consultantData.relation,
        qualification: consultantData.qualificationId.value,
        knownPeriod: consultantData.knownPeriod.value,

        ...(consultantData.walkinDriveSourceId !== "" && {
          walkinDriveSource: consultantData.walkinDriveSourceId.value,
        }),

        resume: "resume",
        createdFrom: "referral",
      };
      dispatch(addCandidateData(obj));
    }
  };

  const resetData = () => {
    setIsLoading(false);
    setConsultantData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: [],
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      referrerName: "",
      referrerEmail: "",
      relation: "",
      knownPeriod: "",
      qualificationId: "",
      qualification: "",
      walkinDriveSourceId: "",
      walkinDriveSource: "",
    });
    setSelectedSkill([]);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      phoneCode: "",
      noticePeriod: "",
      currentEmployer: "",
      totalExperiance: "",
      currentSalary: "",
      skillSet: "",
      educationalDetails: [],
      experienceDetails: [],
      faceBook: "",
      linkdin: "",
      summary: "",
      street: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      referrerName: "",
      referrerEmail: "",
      relation: "",
      knownPeriod: "",
      qualificationId: "",
      qualification: "",
    });
    setShow(false);
    setEducationDetails([
      {
        institute: "",
        major: "",
        degree: "",
        from: "",
        to: "",
        pursuing: false,
      },
    ]);
    setExperienceDetails([
      {
        occupation: "",
        company: "",
        summary: "",
        from: "",
        to: "",
        isWorking: false,
      },
    ]);
  };

  const addResume = async (resume) => {
    let type = resume.name.split(".")[1];
    setFileType(type);
    if (type === "pdf") {
      const objectUrl = URL.createObjectURL(resume);
      setPreview(objectUrl);
    }
    setFile(resume);
    let obj = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      skillSet: [],
      resume: "",
    };
    let formData = new FormData();
    formData.append("file", resume);
    await dispatch(addCvDetails(formData)).then((res) => {
      if (res) {
        let list = [];
        let ids = [];
        res?.data?.data?.candidateSkillSet.map((item) =>
          list.push({ value: item._id, label: item.skill })
        );

        setSelectedSkill(list);
        res?.data?.data?.candidateSkillSet.map((item) => {
          if (item._id !== "") {
            ids.push(item._id);
          }
        });

        obj.firstName = res?.data?.data?.firstName
          ? res?.data?.data?.firstName
          : "";
        obj.lastName = res?.data?.data?.lastName
          ? res?.data?.data?.lastName
          : "";
        obj.email = res?.data?.data?.emailId ? res?.data?.data?.emailId : "";
        obj.mobile = res?.data?.data?.phone ? res?.data?.data?.phone : "";
        obj.skillSet = ids.length > 0 ? ids : [];

        setErrors({
          ...errors,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
      }
    });
    let payload = new FormData();
    payload.append("file", resume);
    payload.append("type", "resume");
    await dispatch(candidateFileUpload(payload)).then((res) => {
      obj.resume = res?.data?.data?.response;
    });
    setConsultantData({
      ...consultantData,
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      mobile: obj.mobile,
      resume: obj.resume,
    });
  };

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onResumeChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      setErrors({
        ...errors,
        succsessFile: "Resume/CV added succsessfully",
        file: "",
      });

      // let type = newFileList[0].originFileObj.name.split(".")[1];
      // setFileType(type);
      // if (type === "pdf") {
      //   const objectUrl = URL.createObjectURL(newFileList[0].originFileObj);
      //   setPreview(objectUrl);
      // }
      //   setFile(newFileList[0].originFileObj);

      //   let formData = new FormData();
      //   formData.append("file", newFileList[0].originFileObj);
      //   await dispatch(addCvDetails(formData));
      //   let payload = new FormData();
      //   payload.append("file", newFileList[0].originFileObj);
      //   payload.append("type", "resume");
      //   // dispatch(candidateFileUpload(payload)).then((res) => {
      //   //   setConsultantData({
      //   //     ...consultantData,
      //   //     resume: res?.data?.data?.response,
      //   //   });
      //   // });
    }
  };

  const handleImageChange = async ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      setProfileList(newFileList);
      setErrors({
        ...errors,
        successImageFile: "Image added succsessfully",
        file: "",
      });
    }
    setImage(newFileList[0].originFileObj);

    // let payload = new FormData();
    // payload.append("file", newFileList[0].originFileObj);
    // payload.append("type", "photo");
    // dispatch(candidateFileUpload(payload)).then((res) => {
    //   setJobApplicationData({
    //     ...jobApplicationData,
    //     photo: res?.data?.data?.response,
    //   });
    // });
  };

  const handleImageReupload = () => {
    setImage();
    // setPreview("");
    setProfileList([]);
    // setFileType("");
    setErrors({
      ...errors,
      successImageFile: "",
      // file: "",
    });

    let payload = {
      type: "photo",
      filePath: consultantData.photo,
    };
    dispatch(candidateFileDelete(payload));
    setConsultantData({
      ...consultantData,
      photo: "",
    });
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  /******************* 
  @Purpose : function for upload Files  
  @Parameter : {}
  @Author : INIC
  ******************/

  const fileTypes = [
    "PDF",
    "DOC",
    "DOCX",
    "JPG",
    "JPEG",
    "PNG",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  var noticePeriod = [
    "Immediately",
    "15 Days",
    "30 Days",
    "45 Days",
    "60 Days",
  ].map(function (notperiod) {
    return { label: notperiod, value: notperiod };
  });

  // SimpleSelect Known Period
  var knownPeriod = ["1 year", "2 Year", "3 Year", "More"].map(function (
    knwnprd
  ) {
    return { label: knwnprd, value: knwnprd };
  });

  return (
		<React.Fragment>
			<Container>
				{/* Start: Header */}
				<section
					className="consultant-header d-block"
					// style={{ backgroundImage: `url(${bgImg})` }}
				>
					<Image
						src={inicLogo}
						width="140"
						height="140"
						alt="IndiaNIC Infotech Pvt. Ltd."
						className="inic-logo mb-2"
					/>
					<h1 className="text-dark display-4 font-weight-semibold">
						Referral Form
					</h1>
				</section>
				{/* Ends: Header */}

				{/* Start: Form */}
				<section className="consultant-add-form">
					<div className="consultant-add-form-body">
						<Row>
							<Col md="3">
								<aside className="doc-upload-block">
									<div className="mb-4">
										<h5 className="font-weight-semibold">
											Autofill Application
										</h5>
										<small className="text-gray">
											Upload your Resume/CV in seconds with the below Drag &amp;
											Drop option
										</small>
									</div>
                  <Row>
                    <Col lg={12} sm={6}>
                      {/* File Uploader */}
                      <div className="document-file-uploader mb-5">
                        <div className="section-title-heading d-block">
                          <h4 className="doc-name mb-3">
                            Upload Resume/CV
                            <span className="text-danger">&nbsp;*</span>
                          </h4>
                          {/* <span className="divider"></span> */}
                        </div>

                        <div className="document-upload">
                          <div className="document-uploader-block">
                            {file && (
                              <div className="preview-delete-btn">
                                {preview && fileType === "pdf" && (
                                  <Button
                                    variant="icon"
                                    onClick={() => setShowPreview(true)}
                                    title="Preview"
                                  >
                                    <span className="bx bx-show"></span>
                                  </Button>
                                )}
                                <Button
                                  variant="icon"
                                  onClick={handleReupload}
                                  title="Re-Upload"
                                >
                                  <span className="bx bx-upload"></span>
                                </Button>
                              </div>
                            )}
                            {!preview && (
                              <>
                                <div className="file-uploader-block">
                                  <div className="upload-icon-title">
                                    {/* <span className="bx bx-cloud-upload"></span> */}
                                    <Image src={UploadIcon} alt="Upload-icon" />
                                    <p className="upload-icon-title-text my-2">
                                      Upload or Drag your Resume/CV here
                                    </p>
                                    <p className="or-text position-relative">OR</p>
                                    <Button type="button" variant="light">
                                      Browse
                                    </Button>
                                  </div>
                                  {fileType === "" && (
                                    <div className="upload-file-extensions">
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        PDF
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        DOC
                                      </Badge>
                                      <Badge
                                        variant="dark"
                                        className="badge-custom"
                                      >
                                        DOCX
                                      </Badge>
                                    </div>
                                  )}
                                </div>
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList}
                                  onChange={onResumeChange}
                                  customRequest={({
                                    file: resume,
                                    onSuccess,
                                    onError,
                                  }) => {
                                    Promise.resolve().then(() => {
                                      if (resume.name.split(".")[1] !== "pdf") {
                                        onSuccess();
                                        addResume(resume);
                                      } else {
                                        addResume(resume);
                                      }
                                    });
                                  }}
                                  // onPreview={onPreview}
                                  accept=".pdf,.doc,.docx"
                                  onRemove={() => {
                                    setFile();
                                    setPreview("");
                                    setFileList([]);
                                    setFileType("");
                                    setErrors({
                                      ...errors,
                                      succsessFile: "",
                                      file: "",
                                    });
                                  }}
                                >
                                  {fileList.length < 1}
                                </Upload>{" "}
                              </>
                            )}
                            <div className="preview-frame">
                              {preview && <iframe src={preview} />}
                            </div>
                          </div>
                          {(fileType === "docx" || fileType === "doc") && (
                            <img
                              src={docIcon}
                              alt="Word document icon"
                              className="preview-doc-icon"
                            />
                          )}
                        </div>
                        <small style={{ color: "red" }}>{errors.file}</small>
                        <small style={{ color: "green" }}>
                          {errors.succsessFile}
                        </small>
                      </div>
                      {/* File Uploader */}
                    </Col>
                    <Col lg={12} sm={6}>
                      {/* Begin: Attach Image */}
                      <div className="document-file-uploader upload-img">
                        <div className="document-upload">
                          {profileList && (
                            <>
                              <div className="section-title-heading d-block">
                                <h4 className="doc-name mb-3">
                                  Upload Profile Picture
                                </h4>
                                {/* <span className="divider"></span> */}
                              </div>
                              <div className="position-relative">
                                <div className="document-uploader-block">
                                  <div className="preview-delete-btn">
                                    {image && (
                                      <Button
                                        variant="icon"
                                        onClick={handleImageReupload}
                                        title="Re-Upload"
                                      >
                                        <span className="bx bx-upload"></span>
                                      </Button>
                                    )}
                                  </div>
                                  <div className="upload-icon-title">
                                    {/* <span className="bx bx-cloud-upload"></span> */}
                                    <Image
                                      src={UploadImageIcon}
                                      alt="Upload-image-icon"
                                    />
                                    {profileList.length === 0 && (
                                      <>
                                        {" "}
                                        <small className="upload-icon-title-text">
                                          Upload or Drag here
                                        </small>
                                      </>
                                    )}
                                  </div>
                                  <ImgCrop grid rotate>
                                    <Upload
                                      listType="picture-card"
                                      fileList={profileList}
                                      onChange={(e) => handleImageChange(e)}
                                      customRequest={({
                                        file,
                                        onSuccess,
                                        onError,
                                      }) => {
                                        Promise.resolve().then(() => {
                                          onSuccess();
                                          addProfile(file);
                                        });
                                      }}
                                      onPreview={onPreview}
                                      onRemove={() => {
                                        setProfileList([]);
                                        setImage();
                                        setErrors({
                                          ...errors,
                                          successImageFile: "",
                                        });
                                      }}
                                    >
                                      {profileList.length < 1}
                                    </Upload>
                                  </ImgCrop>
                                </div>
                                {!errors.successImageFile && (
                                  <div className="upload-file-extensions">
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      JPG
                                    </Badge>
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      PNG
                                    </Badge>
                                    <Badge
                                      variant="dark"
                                      className="badge-custom"
                                    >
                                      JPEG
                                    </Badge>
                                  </div>
                                )}
                                <div className="text-center">
                                  <small style={{ color: "green" }}>
                                    {errors.successImageFile}
                                  </small>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* Ends: Attach Image */}
                    </Col>
                  </Row>
								</aside>
							</Col>
							<Col md="9">
								<section className="consultant-add-form">
									<div className="consultant-add-form-body">
										{/* <div className="text-center">
                      <h4>Autofill Application</h4>
                      <p className="text-gray">
                        Upload your Resume/CV in seconds with the below Drag &amp;
                        Drop option
                      </p>
                    </div>

                    <div className="file-uploader text-center">
                      <FileUploader
                        handleChange={(e) => handleChange(e)}
                        name="file"
                        types={fileTypes}
                        className={errors.file !== "" ? "error-red" : ""}
                      />
                      <span className="error-msg" style={{ color: "red" }}>
                        {errors.file}
                      </span>
                    </div> */}

										{/* Form Fields Begins */}
										<Form onSubmit={handleSubmit}>
											<div className="panel">
												{/* Begin: Basic Info */}
												<div className="panel-header">
													<h4 className="title">Candidate Basic Info</h4>
												</div>
												<div className="panel-content py-0">
													<Row>
														<Col md="6" ref={firstNameRef}>
															<Form.Group controlId="formFirstName">
																<Form.Label>
																	First Name
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<div>
																	{!file ? (
																		<ToolTipComponent
																			text={
																				<span>Please upload CV/Resume</span>
																			}
																			data={
																				<Form.Control
																					type="text"
																					name="firstName"
																					maxLength="20"
																					className={
																						errors.firstName !== ""
																							? "error-red"
																							: !file
																							? "disabled-field"
																							: ""
																					}
																					disabled={!file}
																					value={consultantData.firstName}
																					onChange={(e) => {
																						handleDataChange(e);
																						setErrors({
																							...errors,
																							firstName: "",
																						});
																					}}
																				/>
																			}
																		/>
																	) : (
																		<Form.Control
																			type="text"
																			name="firstName"
																			maxLength="20"
																			className={
																				errors.firstName !== ""
																					? "error-red"
																					: !file
																					? "disabled-field"
																					: ""
																			}
																			value={consultantData.firstName}
																			onChange={(e) => {
																				handleDataChange(e);
																				setErrors({
																					...errors,
																					firstName: "",
																				});
																			}}
																		/>
																	)}
																	<span
																		className="error-msg"
																		style={{ color: "red" }}
																	>
																		{errors.firstName}
																	</span>
																</div>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formLastName">
																<Form.Label>
																	Last Name
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<div>
																	{!file ? (
																		<ToolTipComponent
																			text={
																				<span>Please upload CV/Resume</span>
																			}
																			data={
																				<Form.Control
																					type="text"
																					name="lastName"
																					maxLength="20"
																					className={
																						errors.lastName !== ""
																							? "error-red"
																							: !file
																							? "disabled-field"
																							: ""
																					}
																					disabled={!file}
																					value={consultantData.lastName}
																					onChange={(e) => {
																						handleDataChange(e);
																						setErrors({
																							...errors,
																							lastName: "",
																						});
																					}}
																				/>
																			}
																		/>
																	) : (
																		<Form.Control
																			type="text"
																			name="lastName"
																			maxLength="20"
																			className={
																				errors.lastName !== ""
																					? "error-red"
																					: !file
																					? "disabled-field"
																					: ""
																			}
																			value={consultantData.lastName}
																			onChange={(e) => {
																				handleDataChange(e);
																				setErrors({
																					...errors,
																					lastName: "",
																				});
																			}}
																		/>
																	)}
																	<span
																		className="error-msg"
																		style={{ color: "red" }}
																	>
																		{errors.lastName}
																	</span>
																</div>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formEmail">
																<Form.Label>
																	Email
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<div>
																	{!file ? (
																		<ToolTipComponent
																			text={
																				<span>Please upload CV/Resume</span>
																			}
																			data={
																				<Form.Control
																					type="email"
																					name="email"
																					maxLength="45"
																					className={
																						errors.email !== ""
																							? "error-red"
																							: !file
																							? "disabled-field"
																							: ""
																					}
																					disabled={!file}
																					value={consultantData.email}
																					onChange={(e) => {
																						handleDataChange(e, "email");
																						setErrors({
																							...errors,
																							email: "",
																						});
																					}}
																				/>
																			}
																		/>
																	) : (
																		<Form.Control
																			type="email"
																			name="email"
																			maxLength="45"
																			className={
																				errors.email !== ""
																					? "error-red"
																					: !file
																					? "disabled-field"
																					: ""
																			}
																			value={consultantData.email}
																			onChange={(e) => {
																				handleDataChange(e, "email");
																				setErrors({
																					...errors,
																					email: "",
																				});
																			}}
																		/>
																	)}
																	<span
																		className="error-msg"
																		style={{ color: "red" }}
																	>
																		{errors.email}
																	</span>
																</div>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formMobile">
																<Form.Label>
																	Mobile
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<div className="form-input">
																	{/* <SimpleSelectComponent
                                    name="mobile"
                                    value={consultantData.phoneCode}
                                    className={
                                      errors.phoneCode
                                        ? "form-control phone-code error-red"
                                        :
                                      "phone-code w-25"
                                    }
                                    options={phoneCodeOptionsData}
                                    onValueChange={(e) => {
                                      e &&
                                        setConsultantData({
                                          ...consultantData,
                                          phoneCode: e,
                                        });
                                      e &&
                                        setErrors({
                                          ...errors,
                                          phoneCode: "",
                                        });
                                    }}
                                  />
                                  <div>
                                    <span
                                      className="error-msg"
                                      style={{ color: "red" }}
                                    >
                                      {errors.phoneCode}
                                    </span>
                                  </div> */}

																	<div className="w-100">
																		<div>
																			{!file ? (
																				<ToolTipComponent
																					text={
																						<span>Please upload CV/Resume</span>
																					}
																					data={
																						<InputMask
																							disabled={!file}
																							className={
																								errors.mobile !== ""
																									? "form-control error-red"
																									: !file
																									? "disabled-field form-control"
																									: "form-control"
																							}
																							mask={
																								consultantData.mobile !== ""
																									? "9999999999999"
																									: ""
																							}
																							maskChar=""
																							// placeholder="dd/mm/yyyy"
																							value={consultantData.mobile}
																							onChange={(e) => {
																								setConsultantData({
																									...consultantData,
																									mobile: e.target.value,
																								});
																								setErrors({
																									...errors,
																									mobile: "",
																								});
																							}}
																						/>
																					}
																				/>
																			) : (
																				<InputMask
																					className={
																						errors.mobile !== ""
																							? "form-control error-red"
																							: !file
																							? "disabled-field form-control"
																							: "form-control"
																					}
																					mask={
																						consultantData.mobile !== ""
																							? "9999999999999"
																							: ""
																					}
																					maskChar=""
																					// placeholder="dd/mm/yyyy"
																					value={consultantData.mobile}
																					onChange={(e) => {
																						setConsultantData({
																							...consultantData,
																							mobile: e.target.value,
																						});
																						setErrors({
																							...errors,
																							mobile: "",
																						});
																					}}
																				/>
																			)}
																			<span
																				className="error-msg"
																				style={{ color: "red" }}
																			>
																				{errors.mobile}
																			</span>
																		</div>
																		{/* <Form.Control
                                      type="number"
                                      name="mobile"
                                      onWheel={(e) => e.target.blur()}
                                      className={
                                        errors.mobile !== ""
                                          ? "form-control error-red"
                                          : ""
                                      }
                                      value={consultantData.mobile}
                                      onChange={(e) => {
                                        setConsultantData({
                                          ...consultantData,
                                          mobile: e.target.value,
                                        });
                                        setErrors({
                                          ...errors,
                                          mobile: "",
                                        });
                                      }}
                                    /> */}
																	</div>
																</div>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formEmail">
																<Form.Label>Notice Period</Form.Label>
																<div>
																	{!file ? (
																		<ToolTipComponent
																			text={
																				<span>Please upload CV/Resume</span>
																			}
																			data={
																				<SimpleSelectComponent
																					name="noticePeriod"
																					value={consultantData.noticePeriod}
																					options={noticePeriod}
																					disabled={!file}
																					className={
																						!file ? "disabled-field" : ""
																					}
																					onValueChange={(e) => {
																						e &&
																							setConsultantData({
																								...consultantData,
																								noticePeriod: e,
																							});
																					}}
																				/>
																			}
																		/>
																	) : (
																		<SimpleSelectComponent
																			name="noticePeriod"
																			value={consultantData.noticePeriod}
																			options={noticePeriod}
																			onValueChange={(e) => {
																				e &&
																					setConsultantData({
																						...consultantData,
																						noticePeriod: e,
																					});
																			}}
																		/>
																	)}
																</div>
															</Form.Group>
														</Col>
													</Row>
												</div>
												{/* Ends: Basic Info */}

												<div className="divider-x"></div>

												{/* Begin: Professional Details */}
												<div className="panel-header pt-0">
													<h4 className="title">
														Candidate Professional Details
													</h4>
												</div>
												<div className="panel-content py-0">
													<Row>
														<Col md="6">
															<Form.Group controlId="formHighQualifHeld">
																<Form.Label>
																	{" "}
																	Highest Qualification Held
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<SimpleSelectComponent
																	name="qualification"
																	value={consultantData.qualification}
																	className={
																		errors.qualification ? "error-red" : ""
																	}
																	onValueChange={(e) => {
																		e &&
																			setConsultantData({
																				...consultantData,
																				qualificationId: e,
																				qualification: e,
																			});
																		setErrors({
																			...errors,
																			qualification: "",
																		});
																	}}
																>
																	{qualificationDetail?.data?.map((data) => (
																		<option value={data._id}>
																			{data.qualification}
																		</option>
																	))}
																</SimpleSelectComponent>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.qualification}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formCurrentEmployer">
																<Form.Label>
																	Current Employer
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="text"
																	name="currentEmployer"
																	maxLength="30"
																	className={
																		errors.currentEmployer !== ""
																			? "error-red"
																			: ""
																	}
																	value={consultantData.currentEmployer}
																	onChange={(e) => {
																		handleDataChange(e);
																		setErrors({
																			...errors,
																			currentEmployer: "",
																		});
																	}}
																/>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.currentEmployer}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formTotalExperience">
																<Form.Label>
																	Total Experience(IN YEAR)
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="number"
																	className={
																		errors.totalExperiance !== ""
																			? "error-red form-control"
																			: "form-control"
																	}
																	value={consultantData.totalExperiance}
																	onChange={(e) => {
																		setConsultantData({
																			...consultantData,
																			totalExperiance: e.target.value,
																		});
																		setErrors({
																			...errors,
																			totalExperiance: "",
																		});
																	}}
																	onKeyDown={(evt) =>
																		["e", "E", "+", "-"].includes(evt.key) &&
																		evt.preventDefault()
																	}
																	onInput={(e) => {
																		if (
																			e.target.value.length > e.target.maxLength
																		)
																			e.target.value = e.target.value.slice(
																				0,
																				e.target.maxLength
																			);
																	}}
																	maxlength={5}
																/>
																{/* <InputMask
                                  className={
                                    errors.totalExperiance !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  mask={
                                    consultantData.totalExperiance !== ""
                                      ? "99.99"
                                      : ""
                                  }
                                  maskChar=""
                                  value={consultantData.totalExperiance}
                                  onChange={(e) => {
                                    setConsultantData({
                                      ...consultantData,
                                      totalExperiance: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      totalExperiance: "",
                                    });
                                  }}
                                /> */}

																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.totalExperiance}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formCurrentSalary">
																<Form.Label>
																	Current Salary(IN LPA)
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="number"
																	className={
																		errors.currentSalary !== ""
																			? "error-red form-control"
																			: "form-control"
																	}
																	value={consultantData.currentSalary}
																	onChange={(e) => {
																		setConsultantData({
																			...consultantData,
																			currentSalary: e.target.value,
																		});
																		setErrors({
																			...errors,
																			currentSalary: "",
																		});
																	}}
																	onKeyDown={(evt) =>
																		["e", "E", "+", "-"].includes(evt.key) &&
																		evt.preventDefault()
																	}
																	onInput={(e) => {
																		if (
																			e.target.value.length > e.target.maxLength
																		)
																			e.target.value = e.target.value.slice(
																				0,
																				e.target.maxLength
																			);
																	}}
																	maxlength={5}
																/>
																{/* <InputMask
                                  className={
                                    errors.currentSalary !== ""
                                      ? "error-red form-control"
                                      : "form-control"
                                  }
                                  mask={
                                    consultantData.currentSalary !== ""
                                      ? "99.99"
                                      : ""
                                  }
                                  maskChar=""
                                  value={consultantData.currentSalary}
                                  onChange={(e) => {
                                    setConsultantData({
                                      ...consultantData,
                                      currentSalary: e.target.value,
                                    });
                                    setErrors({
                                      ...errors,
                                      currentSalary: "",
                                    });
                                  }}
                                /> */}
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.currentSalary}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formEmail">
																<Form.Label>Skill Set</Form.Label>
																<MultiSelectComponent
																	name="skillSet"
																	options={skillSetOption}
																	values={selectedSkill}
																	onValuesChange={(e) => {
																		let list = [];
																		e.map((item) => list.push(item.value));
																		e &&
																			setConsultantData({
																				...consultantData,
																				skillSet: list,
																			});
																		setSelectedSkill(e);
																	}}
																	onSearchChange={(e) => {
																		getSkillSetList(e);
																	}}
																	onRemoveItem={function (item) {
																		const skills = selectedSkill?.filter(
																			function (d) {
																				return item.value !== d?.value;
																			}
																		);
																		setSelectedSkill(skills);
																	}}
																></MultiSelectComponent>
															</Form.Group>
														</Col>
														{walkinStatus?.data?.isWalkInDrive && (
															<Col md="6">
																<Form.Group controlId="formWalk-inDrive ">
																	<Form.Label>
																		Candidate walk-in drive source :
																	</Form.Label>
																	<SimpleSelectComponent
																		// placeholder="Type to select"
																		// options={walkinDriveSourceOption}
																		name="walkinDriveSource"
																		value={consultantData.walkinDriveSource}
																		// defaultValue={{
																		//   value:
																		//     consultantData?.walkinDriveSourceId,
																		//   label:
																		//     consultantData?.walkinDriveSource,
																		// }}
																		onValueChange={(e) => {
																			e &&
																				setConsultantData({
																					...consultantData,
																					walkinDriveSourceId: e,
																					walkinDriveSource: e,
																				});
																		}}
																		// onSearchChange={(e) => {
																		//   getWalkInDriveList(e);
																		// }}
																	>
																		{walkinDriveSourceDetail?.map((data) => (
																			<option value={data._id}>
																				{data.walkInDrive}
																			</option>
																		))}
																	</SimpleSelectComponent>
																</Form.Group>
															</Col>
														)}
													</Row>
												</div>
												{/* ENDS: Professional Details */}

												<div className="divider-x"></div>

												{/* Begin: Indianic Referer Details */}
												<div className="panel-header pt-0">
													<h4 className="title">Referrer</h4>
												</div>
												<div className="panel-content py-0">
													<Row>
														<Col md="6">
															<Form.Group controlId="formInicEmployeName">
																<Form.Label>
																	Your Name
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="text"
																	name="referrerName"
																	maxLength="30"
																	className={
																		errors.referrerName !== ""
																			? "error-red"
																			: ""
																	}
																	value={consultantData.referrerName}
																	onChange={(e) => {
																		handleDataChange(e);
																		setErrors({
																			...errors,
																			referrerName: "",
																		});
																	}}
																/>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.referrerName}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formInicEmployeMail">
																<Form.Label>
																	Your mail address
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="text"
																	name="referrerEmail"
																	maxLength="30"
																	className={
																		errors.referrerEmail !== ""
																			? "error-red"
																			: ""
																	}
																	value={consultantData.referrerEmail}
																	onChange={(e) => {
																		setConsultantData({
																			...consultantData,
																			referrerEmail: e.target.value,
																		});
																		setErrors({
																			...errors,
																			referrerEmail: "",
																		});
																	}}
																/>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.referrerEmail}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formReltnWithRefer">
																<Form.Label>
																	Relationship with candidate
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="text"
																	name="relation"
																	maxLength="30"
																	className={
																		errors.relation !== "" ? "error-red" : ""
																	}
																	value={consultantData.relation}
																	onChange={(e) => {
																		handleDataChange(e);
																		setErrors({
																			...errors,
																			relation: "",
																		});
																	}}
																/>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.relation}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formKnownPeriod">
																<Form.Label>Known Period</Form.Label>
																<SimpleSelectComponent
																	name="knownPeriod"
																	options={knownPeriod}
																	value={consultantData.knownPeriod}
																	onValueChange={(e) => {
																		e &&
																			setConsultantData({
																				...consultantData,
																				knownPeriod: e,
																			});
																	}}
																/>
															</Form.Group>
														</Col>
													</Row>
												</div>
												{/* ENDS: Indianic Referer Details */}

												<div className="divider-x"></div>

												{/* Begin: Educational Details */}
												<div className="panel-header pt-0">
													<h4 className="title">Educational Details</h4>
												</div>
												<div className="panel-content py-0">
													{educationDetails &&
														educationDetails.map((i, index) => {
															return (
																Object.keys(i).includes("institute") && (
																	<Form className="form repeater-default add-columns-btn">
																		<div className="field-form" key={index}>
																			<Row>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>
																							Institute / School
																						</Form.Label>
																						<Form.Control
																							type="text"
																							autoComplete="off"
																							name="institute"
																							value={i.institute}
																							onChange={(e) =>
																								handleEducationDetails(
																									e.target.value,
																									index,
																									"institute"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>
																							Major / Department
																						</Form.Label>
																						<Form.Control
																							type="text"
																							autoComplete="off"
																							name="major"
																							value={i.major}
																							onChange={(e) =>
																								handleEducationDetails(
																									e.target.value,
																									index,
																									"major"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>Degree</Form.Label>
																						<Form.Control
																							type="text"
																							autoComplete="off"
																							name="degree"
																							value={i.degree}
																							onChange={(e) =>
																								handleEducationDetails(
																									e.target.value,
																									index,
																									"degree"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>Duration</Form.Label>
																						<Row>
																							<Col sm={6}>
																								<DatePicker
																									placeholderText="From"
																									className="form-control"
																									value={i.from}
																									onChange={(date) => {
																										handleEducationDetails(
																											moment(date).format(
																												"MM/yyyy"
																											),
																											index,
																											"from"
																										);
																										setStartMonth(
																											new Date(date)
																										);
																										i.to = "";
																									}}
																									selectsStart
																									dateFormat="MM/yyyy"
																									showMonthYearPicker
																								/>
																							</Col>

																							<Col sm={6}>
																								<DatePicker
																									placeholderText="To"
																									className="form-control"
																									value={
																										i.pursuing !== true
																											? i.to
																											: "Present"
																									}
																									minDate={startMonth}
																									disabled={i.pursuing === true}
																									onChange={(date) => {
																										handleEducationDetails(
																											moment(date).format(
																												"MM/YYYY"
																											),
																											index,
																											"to"
																										);
																									}}
																									selectsStart
																									dateFormat="MM/yyyy"
																									showMonthYearPicker
																								/>
																							</Col>
																						</Row>
																						<div className="custom-checkbox text-right mt-2">
																							<label
																								className="form-label"
																								htmlFor={`pursuing${index}`}
																							>
																								Currently Pursuing
																								<input
																									type="checkbox"
																									className="form-check-input"
																									name="pursuing"
																									id={`pursuing${index}`}
																									checked={i.pursuing}
																									onChange={(e) => {
																										handleEducationDetails(
																											e.target.checked,
																											index,
																											"pursuing"
																										);
																									}}
																								/>
																								<span></span>
																							</label>
																						</div>
																					</Form.Group>
																				</Col>
																			</Row>
																		</div>
																		{/* {btnForm.educationDetails && ( */}
																		<div className="add-row text-right">
																			{educationDetails.length - 1 ===
																				index && (
																				<span
																					className="bx bxs-plus-circle text-success"
																					title="Add"
																					onClick={(e) => {
																						handleAddEducation(e);
																					}}
																				>
																					<span className="btn-text">Add</span>
																				</span>
																			)}
																			{educationDetails.length !== 1 && (
																				<span
																					className="bx bxs-minus-circle text-danger"
																					title="Delete"
																					onClick={() =>
																						handleRemoveEducation(index)
																					}
																				>
																					<span className="btn-text">
																						Remove
																					</span>
																				</span>
																			)}
																		</div>
																		{/* )} */}
																	</Form>
																)
															);
														})}
												</div>
												{/* Ends: Educational Details */}

												<div className="divider-x"></div>

												{/* Begin: Experience Details */}
												<div className="panel-header pt-0">
													<h4 className="title">Experience Details</h4>
												</div>
												<div className="panel-content py-0">
													{experienceDetails &&
														experienceDetails.map((i, index) => {
															return (
																Object.keys(i).includes("company") && (
																	<Form className="form repeater-default add-columns-btn">
																		<div className="field-form" key={index}>
																			<Row>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>
																							Occupation / Title
																						</Form.Label>
																						<Form.Control
																							type="text"
																							autoComplete="off"
																							name="occupation"
																							value={i.occupation}
																							onChange={(e) =>
																								handleExpDetail(
																									e.target.value,
																									index,
																									"occupation"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>Company</Form.Label>
																						<Form.Control
																							type="text"
																							autoComplete="off"
																							name="company"
																							value={i.company}
																							onChange={(e) =>
																								handleExpDetail(
																									e.target.value,
																									index,
																									"company"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>Summary</Form.Label>
																						<Form.Control
																							as="textarea"
																							rows="2"
																							name="summary"
																							value={i.summary}
																							onChange={(e) =>
																								handleExpDetail(
																									e.target.value,
																									index,
																									"summary"
																								)
																							}
																						/>
																					</Form.Group>
																				</Col>
																				<Col xs={6} lg={6}>
																					<Form.Group>
																						<Form.Label>
																							Work Duration
																						</Form.Label>
																						<Row>
																							<Col sm={6}>
																								<DatePicker
																									placeholderText="From"
																									className="form-control"
																									value={i.from}
																									onChange={(date) => {
																										handleExpDetail(
																											moment(date).format(
																												"MM/yyyy"
																											),
																											index,
																											"from"
																										);
																										setExperienceStartMont(
																											new Date(date)
																										);
																										i.to = "";
																									}}
																									selectsStart
																									dateFormat="MM/yyyy"
																									showMonthYearPicker
																								/>
																							</Col>
																							<Col sm={6}>
																								<DatePicker
																									placeholderText="To"
																									className="form-control"
																									value={
																										i.isWorking !== true
																											? i.to
																											: "Present"
																									}
																									minDate={experienceStartMonth}
																									disabled={
																										i.isWorking === true
																									}
																									onChange={(date) => {
																										handleExpDetail(
																											moment(date).format(
																												"MM/yyyy"
																											),
																											index,
																											"to"
																										);
																									}}
																									selectsStart
																									dateFormat="MM/yyyy"
																									showMonthYearPicker
																								/>
																							</Col>
																						</Row>
																						<div className="custom-checkbox text-right mt-2">
																							<label
																								className="form-label"
																								htmlFor={`isWorking${index}`}
																							>
																								I currently work here
																								<input
																									type="checkbox"
																									className="form-check-input"
																									name="isWorking"
																									id={`isWorking${index}`}
																									checked={i.isWorking}
																									onChange={(e) => {
																										handleExpDetail(
																											e.target.checked,
																											index,
																											"isWorking"
																										);
																									}}
																								/>
																								<span></span>
																							</label>
																						</div>
																					</Form.Group>
																				</Col>
																			</Row>
																		</div>
																		<div className="add-row text-right">
																			{experienceDetails.length - 1 ===
																				index && (
																				<span
																					className="bx bxs-plus-circle text-success"
																					title="Add"
																					onClick={(e) => {
																						handleExeperienceAdd(e);
																					}}
																				>
																					<span className="btn-text">Add</span>
																				</span>
																			)}
																			{experienceDetails.length !== 1 && (
																				<span
																					className="bx bxs-minus-circle text-danger"
																					title="Delete"
																					onClick={() =>
																						handleRemoveExperience(index)
																					}
																				>
																					<span className="btn-text">
																						Remove
																					</span>
																				</span>
																			)}
																		</div>
																		{/* )} */}
																	</Form>
																)
															);
														})}
												</div>
												{/* Ends: Experience Details */}

												<div className="divider-x"></div>

												{/* Begin: Address Details */}
												<div className="panel-header pt-0">
													<h4 className="title">Address Details</h4>
												</div>
												<div className="panel-content py-0">
													<Row>
														<Col md="6">
															<Form.Group controlId="formStreet">
																<Form.Label>Street</Form.Label>
																<Form.Control
																	type="text"
																	name="street"
																	maxLength="45 "
																	value={consultantData.street}
																	onChange={(e) => {
																		handleDataChange(e);
																	}}
																/>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formZip">
																<Form.Label>Zip / Postal Code</Form.Label>
																<InputMask
																	className="form-control"
																	mask={
																		consultantData.zipCode !== ""
																			? "999999"
																			: ""
																	}
																	maskChar=""
																	value={consultantData.zipCode}
																	onChange={(e) =>
																		setConsultantData({
																			...consultantData,
																			zipCode: e.target.value,
																		})
																	}
																/>
																{/* <Form.Control
                                  type="number"
                                  name="zipCode"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => {
                                    setConsultantData({
                                      ...consultantData,
                                      zipCode: e.target.value,
                                    });
                                  }}
                                /> */}
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formCity">
																<Form.Label>
																	City
																	<span className="text-danger">&nbsp;*</span>
																</Form.Label>
																<Form.Control
																	type="text"
																	name="city"
																	maxLength="30"
																	className={
																		errors.city !== "" ? "error-red" : ""
																	}
																	value={consultantData.city}
																	onChange={(e) => {
																		handleDataChange(e);
																		setErrors({
																			...errors,
																			city: "",
																		});
																	}}
																/>
																<span
																	className="error-msg"
																	style={{ color: "red" }}
																>
																	{errors.city}
																</span>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formProvince">
																<Form.Label>State / Province</Form.Label>
																<Form.Control
																	type="text"
																	name="state"
																	maxLength="30"
																	value={consultantData.state}
																	onChange={(e) => {
																		handleDataChange(e);
																	}}
																/>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formCountry">
																<Form.Label>Country</Form.Label>
																<Form.Control
																	type="text"
																	name="country"
																	maxLength="30"
																	value={consultantData.country}
																	onChange={(e) => {
																		handleDataChange(e);
																	}}
																/>{" "}
															</Form.Group>
														</Col>
													</Row>
												</div>
												{/* Ends: Address Details */}

												<div className="divider-x"></div>

												{/* Begin: Social Network Details */}
												<div className="panel-header pt-0">
													<h4 className="title">Social Network</h4>
												</div>
												<div className="panel-content pt-0">
													<Row>
														<Col md="6">
															<Form.Group controlId="formLinkedIn">
																<Form.Label>LinkedIn</Form.Label>
																<Form.Control
																	type="text"
																	name="linkdin"
																	maxLength="30"
																	value={consultantData.linkdin}
																	onChange={(e) => {
																		handleDataChange(e, "num");
																	}}
																/>
															</Form.Group>
														</Col>
														<Col md="6">
															<Form.Group controlId="formFacebook">
																<Form.Label>Facebook</Form.Label>
																<Form.Control
																	type="text"
																	name="faceBook"
																	maxLength="30"
																	value={consultantData.faceBook}
																	onChange={(e) => {
																		handleDataChange(e, "num");
																	}}
																/>
															</Form.Group>
														</Col>
													</Row>
												</div>
												{/* Ends: Social Network Details */}
											</div>

											{/* Begin: Attachment Details */}
											{/* <div className="panel">
                        <div className="panel-header">
                          <h4 className="title mb-3">Attachment Information</h4>
                        </div>
                        <div className="panel-content pt-0">
                          <Row>
                            <Col md="6">
                              <div className="document-upload">
                                <Form.Label>Image</Form.Label>
                                <FileUploader
                                  onChange={(e) => handleChange(e)}
                                  name="file"
                                  types={fileTypes}
                                />
                                <span className="error-msg text-danger">Please Upload correct file</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div> */}
											{/* Ends: Attachment Details */}

											{/* Begin: Submit Button */}
											<div className="button-group d-flex justify-content-end align-items-center my-4">
												<Button
													variant="link"
													type="button"
													className="mr-2"
													onClick={resetData}
												>
													Cancel
												</Button>
												<Button
													variant="primary"
													type="submit"
													disabled={isLoading}
												>
													Submit
												</Button>
												{isLoading && (
													<Spinner
														animation="border"
														variant="primary"
														className="spinner-loader"
													/>
												)}
											</div>
											{/* Ends: Submit Button */}
										</Form>
										{/* Form Fields Ends */}
									</div>
								</section>
							</Col>
						</Row>
					</div>
				</section>
				{/* Ends: Form */}

				<Modal show={show} onHide={handleClose}>
					<Modal.Body>
						<div className="text-center mb-3">
							<span className="bx bx-check-circle text-success display-3 d-block"></span>
							<h5 className="text-secondary">Success !</h5>
						</div>
						<p>
							Dear{" "}
							<span className="text-capitalize">
								{addJob && addJob.data && addJob.data.referrerName}
							</span>
							,
						</p>
						<p className="">
							Thank you for referring{" "}
							<span className="text-uppercase">
								{addJob &&
									addJob.data &&
									addJob.data.firstName + " " + addJob.data.lastName}
							</span>
						</p>
						<p>
							We believe, referrals are one of our best sources of new hires and
							your contribution is extremely valuable to us. We will map the
							candidate against the open requirements and let you know about the
							status of your referred candidate.
						</p>

						<p className="mb-2">
							To know more about IndiaNIC - visit{" "}
							<a href="candidate-portal-indianic.com" target="_blank">
								https://www.indianic.com
							</a>
						</p>
						<p>
							Further updates will be send to candidate email -{" "}
							<a
								href="http://www.google.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								{addJob && addJob.data && addJob.data.emailId}
							</a>{" "}
						</p>
						<h5>Thanks once again,</h5>
						<br></br>
						<h5 className="font-weight-semibold">HR Team</h5>
						<p className="font-weight-medium mb-0">
							Have a Good Day and Best wishes for your further process.
						</p>
						{/* <img src={bgImg} alt="Career Page Header" /> */}
						<p className="mb-0">+91 (79) 6191 6041</p>
						<p className="mb-0">+1 (408) 922-9083</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={resetData}>
							Close
						</Button>
						{/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button> */}
					</Modal.Footer>
				</Modal>

				<Modal
					show={showPreview}
					size="lg"
					onHide={() => setShowPreview(false)}
				>
					<Modal.Header closeButton>
						{/* <h5 className="text-secondary">Preview</h5>
            <div className="text-center">
            </div> */}
						<Modal.Title>Preview</Modal.Title>
					</Modal.Header>
					<Modal.Body> {preview && <iframe src={preview} />}</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setShowPreview(false)}>OK</Button>
					</Modal.Footer>
				</Modal>
			</Container>

			<Footer />
		</React.Fragment>
	);
};

export default ReferralForm;
