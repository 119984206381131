import React from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer";

/************************************** 
   @Purpose : Used for Basic Layout
   @Parameter : 
   @Author : INIC
   *************************************/

function Layout(props) {
  return (
    <React.Fragment>
      <Header />

      <div className="page-body-wrapper flex-shrink-0">
        <div className="main-content-area close-leftbar">
          <div className="main-content-block">{props.children}</div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Layout;
