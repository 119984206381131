export const paths = {
  // home: "/home",
  login: "/",
  webForm: "/web-form",
  webFormViewDetail: "/web-form/viewForm",
  consultantForm: "/web-form/consultant-form",
  formSettings: "/web-form/form-settings",
  jobApplicationForm: "/web-form/job-application-form",
  referralForm: "/web-form/referral-form",
  career: "/career",
  jobDetails: "/job-detail/:id",
  walkInDrive: "/walkInDrive",
};
