import React from "react";
import { SimpleSelect } from "react-selectize";

const SimpleSelectComponent = (props) => {
  return (
    <SimpleSelect
      {...props}
      style={{ borderRadius: "6px" }}
      // tether={props.tether ? false : true}
      restoreOnBackspace={(item) => {
        return item.label;
      }}
    />
  );
};

export default SimpleSelectComponent;
