import { toast } from "react-toastify";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import store from "../store";
import pdfIcon from "../assets/images/icons-pdf.png";
import pngIcon from "../assets/images/icons-png.png";
import jpgIcon from "../assets/images/icons-jpg.png";
import fileIcon from "../assets/images/icons-file.png";
import docIcon from "../assets/images/icons-doc.png";
import xmlIcon from "../assets/images/icons-xml.png";

toast.configure();
/*******************
@Purpose : Store Data To local Storage
@Parameter : key, value
@Author : INIC
******************/
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

/******************* 
  @Purpose : Get Data From local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const getItem = (key) => {
  return localStorage.getItem(key);
};

/******************* 
  @Purpose : Get Data From local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const getEncryptedItem = (key, permissionKey) => {
  try {
    let user = store.getState();
    const data =
      user?.login?.loginEncryptedData?.permission?.permissionCategoryPermissions?.find(
        (item) => item?.moduleKey === key
      );
    const datas = data?.permissions?.filter(
      (item) => item.permissionKey === permissionKey
    );
    if (key === "SUPER_ADMIN") {
      return user?.login?.loginEncryptedData?.permission?.KEY;
    }
    if (key === "PermissionName") {
      return user?.login?.loginEncryptedData?.permission?.permissionName;
    }
    if (key === "User") {
      return user?.login?.loginEncryptedData?._id;
    }
    if (key === "Profile") {
      return user?.login?.loginEncryptedData?.photo;
    }
    if (key === "UserName") {
      return (
        user?.login?.loginEncryptedData?.firstName +
        " " +
        user?.login?.loginEncryptedData?.lastName
      );
    }
    return datas[0]?.value;
  } catch (error) {}
};

/******************* 
  @Purpose : Remove Data in local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const removeItem = (key) => {
  localStorage.removeItem(key);
};

/******************* 
  @Purpose : Used to check weather user is logged in or not
  @Parameter : key
  @Author : INIC
  ******************/
export const Authorization = () => {
  return getItem("accessToken") ? true : false;
};

/******************* 
  @Purpose : Used to validate an email address
  @Parameter : key
  @Author : INIC
  ******************/
export const validateEmail = (emailId) => {
  var pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(emailId);
};
export const validateIndianicEmail = (emailId) => {
  var pattern = new RegExp("[a-z0-9]+@indianic.com");
  return pattern.test(emailId);
};

export const validateMobile = (mobile) => {
  const pattern = new RegExp("^[0-9]{10,13}$");

  return pattern.test(mobile);
};
export const validateNubmerOfPosition = (mobile) => {
  const pattern = new RegExp("^[0-9]");

  return pattern.test(mobile);
};

export const validateSalary = (salary) => {
  const pattern = new RegExp(
    /([0-9]{1,9})[,]*([0-9]{3,3})*[,]*([0-9]{1,3})*[.]*([0-9]{2,2})*/
  );
  return pattern.test(salary);
};

/******************* 
@Purpose : Used to show error toast on greater size 
@Parameter : message
@Author : INIC
******************/
export const fileSizeValidation = (file) => {
  showErrorToast(file);
};

/******************* 
@Purpose : Used to show error toast for valid type
@Parameter : message
@Author : INIC
******************/
export const fileTypeValidation = (file) => {
  showErrorToast(file);
};
/******************* 
@Purpose : Used to show options 
@Parameter : message
@Author : INIC
******************/
export const marksheetOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
].map((format) => {
  return { label: format.label, value: format.value };
});
/******************* 
@Purpose : Used to show options 
@Parameter : message
@Author : INIC
******************/
export const agreementOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
].map((format) => {
  return { label: format.label, value: format.value };
});

/******************* 
@Purpose : Used to show error toast messages
@Parameter : message
@Author : INIC
******************/
export const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 1800,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

/******************* 
@Purpose : Used to show sucess toast messages
@Parameter : message
@Author : INIC
******************/
export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 1800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

/******************* 
@Purpose : Used to show sucess toast messages
@Parameter : message
@Author : INIC
******************/
export const showSuccessFileToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 1800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    limit: 1,
  });
};

/******************* 
@Purpose : Used for show modal notification
@Parameter : text, type, timer, position, buttons, className
@Author : INIC
******************/
export const showModalNotification = (
  text,
  type = "success",
  timer = 2500,
  position = "center",
  buttons = false
) => {
  swal({
    position: position,
    icon: type,
    text: text,
    buttons: buttons,
    timer: timer,
    className: "custom-toaster",
  });
};
/******************* 
@Purpose : First and Last Name Validation
@Parameter : name
@Author : INIC
******************/
export const validateName = (name) => {
  const pattern = new RegExp(/^[a-z A-Z0-9!@#$%\^&*)(+=._-]*$/);
  return pattern.test(name);
};
export const positionTitle = (name) => {
  const pattern = new RegExp(/^[a-z A-Z0-9!@#$%\^&*)(+=._-]*$/);
  return pattern.test(name);
};
export const validateOverallExperience = (name) => {
  const pattern = new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]/);
  return pattern.test(name);
};
export const validateRelevantExperience = (name) => {
  const pattern = new RegExp(/^[a-zA-Z0-9._-]+$/);
  return pattern.test(name);
};
export const validateTotalExperience = (name) => {
  const pattern = new RegExp(/^[0-9]\d*(\.\d+)?$/);
  return pattern.test(name);
};
export const validateSkill = (name) => {
  const pattern = new RegExp(/^[A-Za-z]+$/);
  return pattern.test(name);
};
export const validateSource = (name) => {
  const pattern = new RegExp("[a-zA-Z ]*$");
  return pattern.test(name);
};
export const validateDepartMentName = (name) => {
  const pattern = new RegExp(/^[a-zA-Z0-9._-]+$/);
  return pattern.test(name);
};
export const validateWalkInDriveSource = (name) => {
  const pattern = new RegExp("[a-zA-Z ]*$");
  return pattern.test(name);
};
export const validateWorkExperience = (name) => {
  const pattern = new RegExp(
    /^[A-Za-z0-9_ @./#&+-]*[A-Za-z0-9_ @./#&+-][A-Za-z0-9_ @./#&+-]*$/
  );

  return pattern.test(name);
};

export const imageType = (imageData) => {
  let img;
  if (imageData && imageData.split(".").pop() === "pdf") {
    img = pdfIcon;
  } else if (imageData && imageData.split(".").pop() === "jpeg") {
    img = jpgIcon;
  } else if (imageData && imageData.split(".").pop() === "jpg") {
    img = jpgIcon;
  } else if (imageData && imageData.split(".").pop() === "png") {
    img = pngIcon;
  } else if (imageData && imageData.split(".").pop() === "doc") {
    img = docIcon;
  } else if (imageData && imageData.split(".").pop() === "docx") {
    img = docIcon;
  } else if (imageData && imageData.split(".").pop() === "xml") {
    img = xmlIcon;
  } else {
    img = fileIcon;
  }

  return img;
};

/*******************
  @Purpose : display file in new tab
  @Parameter : data,i
  @Author : INIC
  *******************/

export const openInNewTab = (data, path) => {
  const newWindow = window.open(
    `${path}${data}`,
    "_blank",
    "noopener,noreferrer"
  );

  if (newWindow) newWindow.opener = null;
};

export const textValidator = (txt) => {
  const pattern = txt.replace(/[^a-z / " " / . ]/gi, "");
  return pattern;
};

export const emailValidator = (txt) => {
  const pattern = txt.replace(/[^a-z / " " / . / @ / 0-9]/gi, "");
  return pattern;
};

